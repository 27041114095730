import {call, debounce, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
  ActionTypes,
  requestGroupProductsFailure,
  requestGroupProductsSuccess,
  requestProductGroupsFailure,
  requestProductGroupsSuccess,
  requestProductsFailure,
  requestProductsSuccess,
  searchFailure,
  searchPreviewFailure,
  searchPreviewSuccess,
  searchSuccess,
} from "./actions";
import {search, searchPreview} from "../services/searchService";
import {
  getBrandsOffset,
  getCategoriesOffset,
  getGroupProductsBySlug,
  getProductsListState,
  getProductsOffset,
  getSearchTerm,
} from "src/menu/state/selectors";
import {fetchGroups} from "@menu/services/productGroupsService";
import {fetchProducts} from "@menu/services/productsService";

function* handleSearchPreview(action) {
  const {term, options} = action.payload;

  try {
    const data = yield call(searchPreview, term, {
      ...options,
      limit: 3,
    });
    yield put(searchPreviewSuccess(data));
  } catch (error) {
    yield put(searchPreviewFailure(error));
  }
}

export function* watchSearchPreview() {
  yield debounce(500, ActionTypes.SEARCH_PREVIEW, handleSearchPreview);
}

function* handleSearch(action) {
  const {term, options} = action.payload;

  try {
    const type = options?.type;
    const currentSearchTerm = yield select(getSearchTerm);

    if (term === currentSearchTerm && !type && !options.reset) {
      return;
    }

    const offsets = {
      products: type ? yield select(getProductsOffset) : 0,
      categories: type ? yield select(getCategoriesOffset) : 0,
      brands: type ? yield select(getBrandsOffset) : 0,
    };
    const data = yield call(search, term, {
      ...options,
      offsets,
    });

    yield put(searchSuccess(type ? {[type]: data} : data, type));
  } catch (error) {
    yield put(searchFailure(error, options?.type));
  }
}

export function* watchSearch() {
  yield debounce(500, ActionTypes.SEARCH, handleSearch);
}

function* handleProductGroups(action) {
  try {
    const data = yield call(
      fetchGroups,
      {catalog: action.payload.catalog, filters: action.payload.filters},
      action.payload.options
    );

    yield put(requestProductGroupsSuccess(data));
  } catch (error) {
    yield put(requestProductGroupsFailure(error));
  }
}

export function* watchProductGroups() {
  yield takeLatest(ActionTypes.REQUEST_PRODUCT_GROUPS, handleProductGroups);
}

function* handleGroupProducts(action) {
  try {
    const groupProducts = yield select(state =>
      getGroupProductsBySlug(state, {
        slug: action.payload.slug,
      })
    );

    const data = yield call(fetchProducts, {
      limit: action.payload.limit,
      offset: groupProducts?.data?.length || 0,
      order: action.payload.order,
      filters: action.payload.filters,
      deliveryInfo: action.payload.deliveryInfo,
    });

    if (data === null) return;

    yield put(
      requestGroupProductsSuccess({
        slug: action.payload.slug,
        data,
      })
    );
  } catch (error) {
    yield put(
      requestGroupProductsFailure({
        slug: action.payload.slug,
        error,
      })
    );
  }
}

export function* watchGroupProducts() {
  yield takeEvery(ActionTypes.REQUEST_GROUP_PRODUCTS, handleGroupProducts);
}

function* handleProducts(action) {
  try {
    const currentState = yield select(getProductsListState);

    const data = yield call(fetchProducts, {
      limit: action.payload.limit,
      offset: currentState.data.length,
      order: action.payload.order,
      filters: action.payload.filters,
      deliveryInfo: action.payload.deliveryInfo,
    });

    if (data === null) return;

    yield put(
      requestProductsSuccess({
        slug: action.payload.slug,
        data: data,
      })
    );
  } catch (error) {
    yield put(requestProductsFailure({error}));
  }
}

export function* watchProducts() {
  yield takeLatest(ActionTypes.REQUEST_PRODUCTS, handleProducts);
}
