import React, {useEffect, useState} from "react";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import {useForm} from "../../../components/FormContext";
import Error from "../../../components/elements/Error";
import {Field} from "react-final-form";
import {FieldContainer} from "../../../components/collections/form/forms";
import {composeValidators, phoneNumberValidation, required} from "../../../validations";
import useParsePhoneNumber from "src/core/common/hooks/useParsePhoneNumber";
import OtherPhoneInput from "react-phone-input-2";
import useSite from "src/core/sites/hooks/useSite";
import {showErrorHighlight} from "src/core/common/utils";
import {useShop} from "src/core/shops";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import media from "src/themes/utils/media";

function PhoneInput({parsePhoneNumber, styles, ...props}) {
  const [value, setValue] = useState(props.value);
  const site = useSite();
  const [, shop] = useShop();
  const shopCountry = shop.getShop()?.getCountry();
  const options = useSiteOptions();

  function onChange(_value) {
    const value = `+${_value}`;
    setValue(value);
    if (props.onChange && !parsePhoneNumber) {
      props.onChange(value);
    } else if (props.onChange) {
      const phoneNumber = parsePhoneNumber(value || "");
      const number = phoneNumber ? phoneNumber.number : value;
      props.onChange(number);
    }
  }

  /* eslint-disable */
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  /* eslint-enable */

  const formContext = useForm();
  const _disabled = formContext.disabled || props.disabled;

  return (
    <Container hasErrors={props.hasErrors} styles={styles}>
      <OtherPhoneInput
        {...props}
        value={value}
        autoComplete="new-password"
        onlyCountries={site.getAllowedCountryPhoneNumbers()}
        className={props.hasErrors ? "invalid" : ""}
        onChange={onChange}
        disabled={Boolean(_disabled)}
        country={options.getDefaultCountry()?.toLowerCase() || shopCountry?.toLowerCase()}
        inputProps={{type: "tel", autoComplete: "new-password"}}
        placeholder={"Phone Number"}
      />
    </Container>
  );
}

const Container = styled.div`
  &:hover {
    input,
    .flag-dropdown {
      border-color: hsl(0, 0%, 70%) !important;
    }
  }

  input {
    padding: ${({theme}) => theme.v2.spacing(4)};
    font-family: ${({styles, theme}) =>
      styles?.fontFamily || theme.v2.typography.bodyText1.family};
    outline: none;
    background: #fafafa !important;
    box-sizing: border-box;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};

    ${media.up("lg")} {
      font-size: ${({styles, theme}) =>
        styles?.fontSize?.lg || theme.v2.typography.sizing.m.lg};
    }

    ${media.between("md", "lg")} {
      font-size: ${({styles, theme}) =>
        styles?.fontSize?.md || theme.v2.typography.sizing.m.md};
    }

    ${media.down("sm")} {
      font-size: ${({styles, theme}) =>
        styles?.fontSize?.sm || theme.v2.typography.sizing.m.sm};
    }

    cursor: ${({disabled, clickable}) =>
      disabled ? (clickable ? "pointer" : "not-allowed") : "auto"};
    color: ${({hasErrors, disabled, clickable}) =>
      hasErrors ? "red" : disabled && !clickable ? "grey" : "black"};

    width: 100% !important;
    height: ${({styles}) => styles?.height || "51px"} !important;
  }

  input,
  .flag-dropdown {
    transition: 100ms border-color ease-out;
    border: ${({styles, hasErrors}) =>
      hasErrors
        ? styles?.error?.border || "1px solid red"
        : styles?.border || "1px solid #ebebeb"} !important;
    border-radius: ${({styles, theme}) =>
        styles?.borderRadius || theme.v2.decoration.border.radius.default}
      0 0
      ${({styles, theme}) =>
        styles?.borderRadius || theme.v2.decoration.border.radius.default} !important;
    background: ${({styles}) => styles?.backgroundColor} !important;
  }

  input,
  .form-control {
    border: ${({styles, hasErrors}) =>
      hasErrors
        ? styles?.error?.border || "1px solid red"
        : styles?.border || "1px solid #ebebeb"} !important;
    border-radius: ${({styles, theme}) =>
      styles?.borderRadius || theme.v2.decoration.border.radius.default} !important;
    background: ${({styles}) => styles?.backgroundColor} !important;
  }
`;

function PhoneInputField({
  name = "phoneNumber",
  onClickContainer,
  isRequired,
  initialValue,
  computePhoneInputProps = ({input}) => input,
  styles,
}) {
  const result = useParsePhoneNumber();
  const {loading, parsePhoneNumber} = result;
  const _validations = isRequired
    ? composeValidators(required, phoneNumberValidation(parsePhoneNumber))
    : phoneNumberValidation(parsePhoneNumber);
  return (
    <Field
      name={name}
      initialValue={initialValue}
      validate={_validations}
      render={({input, meta}) => {
        const props = computePhoneInputProps({input, meta});
        const disabled = props.disabled || loading;
        return (
          <FieldContainer data-cy="phoneNumberField" onClick={onClickContainer}>
            <PhoneInput
              {...input}
              hasErrors={showErrorHighlight(meta)}
              placeholder={"Phone Number" + (isRequired ? "*" : "")}
              parsePhoneNumber={parsePhoneNumber}
              {...props}
              disabled={disabled}
              styles={styles}
            />
            {meta.touched && <Error>{meta.error}</Error>}
          </FieldContainer>
        );
      }}
    />
  );
}

export default PhoneInputField;
