import React from "react";
import styled from "styled-components";
import {useForm} from "../../../components/FormContext";

export default function CustomCheckbox({
  className,
  checked,
  color,
  checkedMarkColor,
  styles,
  ...props
}) {
  const formContext = useForm();
  const _disabled = formContext.disabled || props.disabled;
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        checked={checked}
        {...props}
        disabled={Boolean(_disabled)}
        aria-label={props.label}
      />
      <StyledCheckbox
        className={
          `checkbox__container ` + (checked ? "checkbox__container--checked" : "")
        }
        checked={checked}
        styles={styles}
      >
        <Icon color={checkedMarkColor || color} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  margin-right: 10px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${props => (props.color ? props.color : "black")};
  stroke-width: 4px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({type: "checkbox"})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 17px;
  height: 17px;
  background: white;
  transition: all 150ms;
  border: ${({styles}) => styles?.border || "1px solid #999"};
  border-radius: ${({styles, theme}) =>
    styles?.borderRadius || theme.v2.decoration.border.radius.default};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`;
