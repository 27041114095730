import {useEffect, useState} from "react";
import useCartState from "src/core/checkout/hooks/useCartState";
import useOrderPath from "./useOrderPath";
import useOrderState from "src/core/checkout/hooks/useOrderState";
import routes from "../../common/routes";
import AuthenticationModalService from "../../authentication/components/authenticationModalService";
import * as Notifications from "src/core/notifications";
import {LOGIN_MODE} from "../../authentication/components/AuthenticationModal";
import useFeatureToggles from "../../common/hooks/useFeatureToggles";
import {handleError} from "src/core/common/utils";
import get from "lodash/get";
import useOnBoarding from "src/core/checkout/hooks/useOnBoarding";
import useFormsCache from "src/core/common/hooks/useFormsCache";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useAccountVerificationWithCache from "src/core/authentication/hooks/useAccountVerificationWithCache";

function useCheckout() {
  const [cartState, ECommerceActions] = useCartState();
  const cart = cartState.data;
  const [orderState] = useOrderState();
  const order = orderState.data;
  const orderError = orderState.error;
  const [, goToOrderDetail] = useOrderPath(routes.orderDetail);
  const [submittedValues, setSubmittedValues] = useState(null);
  const toggles = useFeatureToggles();
  const [, {clearOnBoardingData}] = useOnBoarding();
  const [, , clearFormCache] = useFormsCache("");
  const [, Authentication] = useAuthentication();
  const [, sendVerification] = useAccountVerificationWithCache();

  function createOrder(params) {
    setSubmittedValues(params);
    ECommerceActions.createOrderFromCart(cart, params);
  }

  useEffect(() => {
    if (order && submittedValues) {
      goToOrderDetail(order.getId(), true);

      if (
        toggles.isGuestCheckoutActive(Authentication.isLoggedIn()) &&
        get(submittedValues, "saveCheckoutInfo")
      ) {
        Notifications.info("Your order was placed. Let's verify your phone number.");
      }

      if (toggles.isGuestCheckoutActive(Authentication.isLoggedIn())) {
        setTimeout(() => clearOnBoardingData());
        clearFormCache();
      }
    }
    // eslint-disable-next-line
  }, [order, submittedValues]);

  /* eslint-disable */
  useEffect(() => {
    if (submittedValues) {
      handleError("account_already_verified", orderError, () => {
        AuthenticationModalService.show({
          initialMode: LOGIN_MODE,
          initialValues: {
            emailOrPhone: submittedValues.phoneNumber,
            phone: submittedValues.phoneNumber,
          },
        });
      });
      handleError("phone_number_requires_confirmation", orderError, () => {
        sendVerification({phoneNumber: submittedValues.phoneNumber});
      });
      handleError("email_requires_confirmation", orderError, () => {
        sendVerification({email: submittedValues.email});
      });
    }
  }, [orderError]);
  /* eslint-enable */

  return {
    createOrder,
    clearCart: ECommerceActions.clearCart,
    loading: cartState.loading || orderState.loading,
    placingOrder: orderState.loading,
  };
}

export default useCheckout;
