import {useEffect, useState} from "react";
import store from "../state/store";
import {Authentication} from "../index";
import Cache from "src/core/common/cache";

function useAccountVerificationWithCache() {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function sendVerificationCode(payload) {
    if (!cache.get(payload.phoneNumber || payload.email)) {
      cache.put(payload.phoneNumber || payload.email, {sent: new Date()});
      Authentication.accountVerification(payload);
    }
  }

  return [state.accountVerification, sendVerificationCode];
}

const cache = new Cache(300000);

export default useAccountVerificationWithCache;
