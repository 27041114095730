import React from "react";
import styled, {useTheme} from "styled-components";
import {ProductInfo} from "@ui";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import BrandRecommendations from "@menu/components/BrandRecommendations";
import Separator from "src/core/common/components/elements/Separator";
import media from "src/themes/utils/media";
import Link from "src/core/common/components/modules/Link";
import styles from "src/themes/baseTheme/components/ProductDetail/styles";
import useProductDetail from "src/core/common/hooks/useProductDetail";
import useSite from "src/core/sites/hooks/useSite";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import {getFlowerTypeIndicatorProps} from "src/themes/sense/elements/flowerType";
import ProductDetailActions from "src/integrations/inStoreExperience/components/ProductDetailActions";
import ProductDetailHeaderActions from "src/integrations/inStoreExperience/components/ProductDetailHeaderActions";

export default function SenseProductDetail({productId, onAddItem}) {
  const {
    product,
    meta,
    onEmptyPlaceholderButtonClickHandler,
    hasError,
    showSkeleton,
    showProductDetailRecommendations,
    quantityPickerProps,
    productInfoProps,
  } = useProductDetail({productId, onAddItem});

  const theme = useTheme();
  const site = useSite();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);

  const mainTags = {...productInfoProps?.mainTags, flowerTypeIndicatorProps};

  const _styles = styles(theme, site);
  return (
    <>
      <Container style={{height: meta.error ? "100%" : "auto"}}>
        {hasError && (
          <ProductInfo
            hasEmptyPlaceholder
            emptyPlaceholderProps={{
              onEmptyPlaceholderButtonClickHandler,
              emptyPlaceholderImage: EmptyPlaceholderImage,
            }}
          />
        )}
        {showSkeleton && <ProductInfo isSkeleton />}
        {product && (
          <>
            <PageHead
              priority={1}
              title={product.getName()}
              description={product.getDescription()}
              image={product.getMainImage()}
              schemas={<ProductDetailSchema product={product} />}
            />
            <ProductInfo
              styles={styles(theme, site)}
              LinkComponent={Link}
              quantityPicker={quantityPickerProps}
              {...productInfoProps}
              mainTags={mainTags}
              sizesPosition={site.getUiConfiguration().productDetail.sizes.sizesPosition}
              NavigationActionsComponent={
                <ProductDetailHeaderActions styles={_styles.navigationActions} />
              }
              ActionsComponent={<ProductDetailActions product={product} />}
            />
          </>
        )}
      </Container>
      {showProductDetailRecommendations && product && product.getBrandName() && (
        <>
          <CustomSeparator />
          <BrandRecommendationsContainer>
            <BrandRecommendations product={product} />
          </BrandRecommendationsContainer>
        </>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BrandRecommendationsContainer = styled.div`
  ${media.up("lg")} {
    margin-top: 24px;
  }

  ${media.down("md")} {
    padding-bottom: 130px;
  }
`;

const CustomSeparator = styled(Separator)`
  background-color: ${({theme}) => theme.v2.color.base.grey["100"]};
  width: 100%;
  height: 2px;
  max-height: 2px;
  border-radius: 1px;
`;
