import React from "react";
import ProductsList from "./ProductsList";
import useProductGroups from "@mock/hooks/useProductGroups";
import LazyRender from "@ui/components/Decorators/LazyRender";

export default function ShowcaseProductsList({showBrands, type}) {
  const {groups} = useProductGroups({displayBrands: showBrands}, type);

  return <BaseShowcaseProductsList groups={groups} />;
}

export function BaseShowcaseProductsList({groups, keepQuery = true}) {
  const hasGroups = groups.length > 0;

  if (!hasGroups) {
    return [1, 2].map((v, index) => <ProductsList key={index} loading={true} />);
  }

  return groups.map((group, index) => (
    <LazyRender key={group.name} forceDisplay={index === 0} minHeight={"475px"}>
      <ProductsList {...group} keepQuery={keepQuery} />
    </LazyRender>
  ));
}
