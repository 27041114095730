import {useEffect, useState} from "react";
import {makePayment} from "src/core/payments/factories/payment";

const initialState = {
  data: null,
  meta: {
    loading: false,
    error: null,
  },
};

function useLedgerGreen() {
  const [state, setState] = useState(initialState);

  const refreshPaymentSources = () => fetchCards(setState, state);
  useEffect(() => {
    refreshPaymentSources();
  }, []);

  return {...state, refresh: refreshPaymentSources};
}

const fetchCards = (updateState, previousState) => {
  const payment = makePayment("ledgergreen");

  updateState({
    ...previousState,
    meta: {
      loading: true,
      error: null,
    },
  });
  payment
    .listAccounts()
    .then(sources => {
      updateState({
        data: sources,
        meta: {
          loading: false,
          error: null,
        },
      });
    })
    .catch(e => {
      updateState({
        ...previousState,
        meta: {
          loading: false,
          error: e,
        },
      });
    });
};

export default useLedgerGreen;
