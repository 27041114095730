import * as React from "react";
import PropTypes from "prop-types";

const SchwabBankIcon = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g id="surface1">
        <circle cx="12" cy="12" r="12" fill="rgb(0%,62.745098%,87.45098%)" />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 20.867188 10.863281 C 20.867188 10.519531 20.652344 10.261719 20.480469 10.003906 C 20.304688 9.832031 20.179688 9.660156 20.179688 9.445312 C 20.179688 9.1875 20.351562 9.015625 20.566406 9.015625 C 20.695312 9.015625 20.777344 9.058594 20.867188 9.101562 C 20.953125 9.144531 21.078125 9.1875 21.164062 9.1875 C 21.339844 9.1875 21.464844 9.015625 21.464844 8.886719 C 21.464844 8.714844 21.292969 8.542969 20.992188 8.542969 C 20.304688 8.542969 19.703125 9.316406 19.703125 9.917969 C 19.703125 10.261719 19.917969 10.515625 20.089844 10.734375 C 20.21875 10.90625 20.347656 11.074219 20.347656 11.25 C 20.347656 11.503906 20.089844 11.679688 19.875 11.679688 C 19.617188 11.679688 19.488281 11.546875 19.316406 11.421875 C 19.230469 11.335938 19.140625 11.25 19.058594 11.25 C 18.929688 11.25 18.886719 11.503906 18.886719 11.636719 C 18.886719 11.808594 19.1875 12.066406 19.570312 12.066406 C 20.179688 11.976562 20.867188 11.460938 20.867188 10.863281 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 6.226562 6.65625 C 6.183594 6.65625 6.183594 6.65625 6.226562 6.65625 L 6.183594 6.828125 L 6.183594 6.871094 L 6.394531 6.871094 C 6.570312 6.914062 6.609375 6.914062 6.609375 7 C 6.609375 7.128906 6.4375 7.558594 6.222656 8.160156 L 6.050781 8.71875 C 5.878906 9.191406 5.621094 9.878906 5.40625 10.4375 C 5.152344 11.125 4.933594 11.683594 4.933594 11.769531 C 4.933594 11.855469 4.976562 11.898438 5.191406 11.898438 C 5.40625 11.898438 5.492188 11.855469 5.535156 11.769531 C 5.921875 10.523438 6.949219 9.109375 7.464844 9.109375 C 7.640625 9.109375 7.679688 9.152344 7.679688 9.320312 C 7.679688 9.578125 7.464844 10.09375 7.207031 10.609375 C 6.992188 11.039062 6.820312 11.46875 6.820312 11.683594 C 6.820312 11.855469 6.90625 11.941406 7.078125 11.941406 C 7.59375 11.941406 8.023438 11.46875 8.324219 11.082031 L 8.367188 11.039062 L 8.238281 10.90625 L 8.195312 10.90625 C 8.019531 11.082031 7.722656 11.378906 7.632812 11.378906 L 7.589844 11.378906 L 7.589844 11.292969 C 7.589844 11.25 7.632812 11.121094 7.722656 10.953125 C 7.933594 10.476562 8.28125 9.664062 8.28125 9.191406 C 8.28125 8.804688 8.105469 8.59375 7.765625 8.59375 C 7.121094 8.59375 6.605469 9.320312 6.347656 9.707031 C 6.304688 9.792969 6.21875 9.878906 6.21875 9.878906 C 6.21875 9.878906 6.261719 9.792969 6.304688 9.625 C 6.390625 9.453125 6.476562 9.195312 6.605469 8.851562 C 7.078125 7.5625 7.292969 6.917969 7.292969 6.832031 C 7.292969 6.746094 7.203125 6.746094 7.160156 6.746094 L 6.214844 6.746094 Z M 6.226562 6.65625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 4.765625 11.207031 C 4.808594 11.164062 4.808594 11.164062 4.765625 11.207031 C 4.765625 11.164062 4.636719 11.074219 4.636719 11.074219 C 4.421875 11.25 4.035156 11.59375 3.691406 11.59375 C 3.5625 11.59375 3.433594 11.546875 3.347656 11.460938 C 3.21875 11.375 3.175781 11.207031 3.175781 10.988281 C 3.132812 10.472656 3.605469 9.65625 4.035156 9.1875 C 4.292969 8.929688 4.507812 8.800781 4.632812 8.800781 C 4.808594 8.800781 4.933594 8.929688 5.0625 9.058594 C 5.152344 9.1875 5.238281 9.269531 5.363281 9.269531 C 5.40625 9.269531 5.492188 9.226562 5.492188 9.183594 C 5.535156 9.140625 5.578125 9.054688 5.578125 9.011719 C 5.578125 8.796875 5.367188 8.496094 4.894531 8.453125 C 4.378906 8.453125 3.691406 8.753906 3.175781 9.269531 C 2.703125 9.742188 2.445312 10.339844 2.488281 10.902344 C 2.488281 11.375 2.746094 11.886719 3.433594 11.886719 C 4.121094 11.976562 4.679688 11.378906 4.765625 11.207031 Z M 4.765625 11.207031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 13.4375 10.003906 C 13.4375 10.046875 13.394531 10.089844 13.394531 10.089844 L 13.394531 10.046875 C 13.394531 10.003906 13.4375 9.875 13.480469 9.703125 C 13.566406 9.359375 13.652344 8.929688 13.652344 8.800781 C 13.652344 8.628906 13.566406 8.542969 13.398438 8.542969 C 13.054688 8.542969 12.496094 9.144531 12.324219 9.445312 L 12.410156 9.574219 L 12.496094 9.488281 C 12.625 9.316406 12.882812 9.015625 13.011719 9.015625 C 13.097656 9.015625 13.140625 9.101562 13.140625 9.230469 C 13.140625 9.53125 12.800781 10.476562 12.582031 11.121094 C 12.453125 11.460938 12.371094 11.71875 12.371094 11.761719 C 12.371094 11.847656 12.414062 11.847656 12.457031 11.847656 L 12.757812 11.847656 C 12.800781 11.847656 12.84375 11.804688 12.886719 11.71875 C 12.886719 11.71875 13.015625 11.375 13.097656 11.203125 C 13.699219 10 14 9.402344 14.257812 9.140625 C 14.429688 8.96875 14.558594 8.929688 14.644531 8.929688 C 14.730469 8.929688 14.730469 8.972656 14.773438 9.015625 C 14.816406 9.058594 14.859375 9.144531 14.945312 9.144531 C 14.992188 9.144531 15.078125 9.144531 15.121094 9.101562 C 15.207031 9.015625 15.25 8.929688 15.25 8.800781 C 15.25 8.585938 15.078125 8.5 14.949219 8.5 C 14.339844 8.542969 13.953125 8.929688 13.4375 10.003906 Z M 13.4375 10.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 11.59375 8.457031 C 11.546875 8.457031 11.503906 8.5 11.503906 8.542969 C 11.460938 8.585938 11.460938 8.628906 11.375 8.628906 C 11.332031 8.628906 11.289062 8.628906 11.203125 8.585938 C 11.117188 8.585938 11.03125 8.542969 10.902344 8.542969 C 9.402344 8.542969 8.628906 10.5625 8.628906 11.25 C 8.628906 11.722656 8.886719 11.933594 9.144531 11.933594 C 9.445312 11.933594 9.875 11.847656 10.433594 10.988281 L 10.5625 10.777344 L 10.5625 10.820312 L 10.519531 10.90625 C 10.433594 11.035156 10.304688 11.335938 10.304688 11.59375 C 10.304688 11.722656 10.347656 11.804688 10.390625 11.847656 C 10.433594 11.890625 10.523438 11.933594 10.605469 11.933594 C 11.121094 11.933594 11.550781 11.25 11.722656 10.988281 L 11.59375 10.859375 L 11.546875 10.859375 L 11.417969 10.988281 C 11.246094 11.164062 11.074219 11.417969 10.945312 11.417969 L 10.902344 11.417969 L 10.902344 11.332031 C 10.902344 11.160156 11.246094 10.347656 11.460938 9.699219 C 11.636719 9.226562 11.804688 8.796875 11.804688 8.714844 C 11.804688 8.714844 11.804688 8.628906 11.761719 8.628906 L 11.546875 8.5 C 11.636719 8.457031 11.59375 8.457031 11.59375 8.457031 Z M 9.230469 11.121094 C 9.230469 10.132812 10.003906 8.800781 10.777344 8.800781 C 11.03125 8.800781 11.164062 8.929688 11.164062 9.1875 C 11.164062 9.574219 10.132812 11.417969 9.445312 11.417969 C 9.316406 11.460938 9.230469 11.417969 9.230469 11.121094 Z M 9.230469 11.121094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 16.402344 11.03125 C 16.402344 11.59375 16.746094 11.976562 17.261719 11.976562 C 17.859375 11.976562 18.507812 11.378906 18.675781 11.207031 L 18.675781 11.164062 L 18.589844 11.03125 L 18.546875 11.03125 L 18.460938 11.074219 C 18.246094 11.25 17.902344 11.503906 17.601562 11.503906 C 17.257812 11.503906 17.042969 11.292969 17.042969 10.945312 C 17.042969 10.515625 17.257812 10.21875 17.386719 9.960938 L 17.386719 9.917969 L 17.730469 9.917969 C 18.289062 9.917969 19.316406 9.660156 19.316406 9.015625 C 19.316406 8.757812 19.0625 8.457031 18.632812 8.457031 C 17.773438 8.542969 16.402344 9.832031 16.402344 11.03125 Z M 18.589844 8.757812 C 18.761719 8.757812 18.890625 8.886719 18.890625 9.058594 C 18.890625 9.488281 18.417969 9.699219 17.601562 9.699219 C 17.730469 9.402344 18.203125 8.757812 18.589844 8.757812 Z M 18.589844 8.757812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 16.140625 11.074219 C 16.140625 11.074219 16.140625 11.03125 16.140625 11.074219 C 16.140625 11.03125 16.011719 10.902344 16.011719 10.902344 L 15.96875 10.945312 C 15.667969 11.246094 15.539062 11.460938 15.371094 11.460938 L 15.328125 11.460938 C 15.328125 11.460938 15.28125 11.417969 15.28125 11.375 C 15.28125 11.203125 15.582031 10.347656 16.1875 8.800781 L 16.613281 7.683594 C 16.828125 7.125 16.957031 6.824219 16.957031 6.78125 L 16.957031 6.738281 C 16.957031 6.695312 16.914062 6.695312 16.828125 6.695312 L 15.84375 6.695312 C 15.84375 6.695312 15.800781 6.695312 15.800781 6.738281 L 15.800781 6.953125 L 16.011719 6.953125 C 16.140625 6.996094 16.226562 7.039062 16.226562 7.125 C 16.226562 7.253906 15.582031 8.84375 15.582031 8.84375 C 15.238281 9.703125 14.636719 11.207031 14.636719 11.546875 C 14.636719 11.722656 14.679688 11.847656 14.722656 11.890625 C 14.808594 11.976562 14.898438 12.023438 15.023438 12.023438 C 15.410156 12.023438 15.839844 11.503906 16.097656 11.164062 C 16.097656 11.164062 16.140625 11.074219 16.140625 11.074219 Z M 16.140625 11.074219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 2.53125 14.082031 C 2.53125 14.554688 2.917969 14.8125 3.261719 14.984375 L 3.433594 15.070312 C 3.777344 15.246094 3.949219 15.457031 3.949219 15.714844 C 3.949219 16.015625 3.738281 16.273438 3.433594 16.273438 C 2.960938 16.273438 2.703125 15.800781 2.574219 15.5 L 2.402344 15.542969 C 2.445312 15.84375 2.53125 16.1875 2.53125 16.316406 L 2.617188 16.316406 L 2.832031 16.402344 C 2.960938 16.445312 3.132812 16.492188 3.304688 16.492188 C 3.949219 16.492188 4.421875 16.105469 4.421875 15.503906 C 4.421875 14.945312 3.992188 14.6875 3.691406 14.558594 L 3.5625 14.515625 C 3.175781 14.300781 3.003906 14.128906 3.003906 13.871094 C 3.003906 13.613281 3.175781 13.355469 3.476562 13.355469 C 3.863281 13.355469 4.035156 13.65625 4.117188 13.957031 L 4.292969 13.914062 C 4.246094 13.65625 4.203125 13.441406 4.203125 13.269531 L 4.074219 13.226562 L 3.988281 13.183594 C 3.859375 13.140625 3.6875 13.097656 3.558594 13.097656 C 2.960938 13.136719 2.53125 13.523438 2.53125 14.082031 Z M 2.53125 14.082031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 4.808594 14.855469 C 4.808594 15.671875 5.367188 16.445312 6.613281 16.445312 C 6.910156 16.445312 7.339844 16.359375 7.515625 16.316406 C 7.601562 16.101562 7.726562 15.714844 7.769531 15.5 L 7.597656 15.457031 C 7.339844 15.972656 7.125 16.230469 6.570312 16.230469 C 5.882812 16.230469 5.410156 15.628906 5.410156 14.726562 C 5.410156 14.082031 5.710938 13.355469 6.570312 13.355469 C 7.085938 13.355469 7.339844 13.566406 7.472656 14.082031 L 7.644531 14.082031 L 7.601562 13.871094 L 7.515625 13.308594 L 7.429688 13.308594 C 7.253906 13.265625 7 13.222656 6.65625 13.222656 C 5.582031 13.136719 4.808594 13.824219 4.808594 14.855469 Z M 4.808594 14.855469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 11.378906 13.222656 L 10.003906 13.222656 L 10.003906 13.398438 C 10.347656 13.441406 10.390625 13.441406 10.390625 13.996094 L 10.390625 14.640625 L 8.933594 14.640625 L 8.933594 13.996094 C 8.933594 13.480469 8.976562 13.4375 9.320312 13.398438 L 9.320312 13.222656 L 7.902344 13.222656 L 7.902344 13.398438 C 8.332031 13.441406 8.332031 13.484375 8.332031 13.996094 L 8.332031 15.714844 C 8.332031 16.230469 8.289062 16.273438 7.902344 16.316406 L 7.902344 16.488281 L 9.320312 16.488281 L 9.320312 16.316406 C 8.976562 16.273438 8.890625 16.273438 8.890625 15.714844 L 8.890625 14.898438 L 10.347656 14.898438 L 10.347656 15.714844 C 10.347656 16.230469 10.304688 16.273438 9.917969 16.316406 L 9.917969 16.488281 L 11.335938 16.488281 L 11.335938 16.316406 C 10.949219 16.273438 10.90625 16.273438 10.90625 15.714844 L 10.90625 13.996094 C 10.90625 13.480469 10.949219 13.4375 11.335938 13.398438 C 11.335938 13.394531 11.378906 13.222656 11.378906 13.222656 Z M 11.378906 13.222656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 16.230469 13.222656 L 15.113281 13.222656 L 15.113281 13.398438 L 15.285156 13.398438 C 15.371094 13.398438 15.414062 13.441406 15.457031 13.441406 C 15.457031 13.484375 15.5 13.484375 15.5 13.527344 C 15.5 13.570312 15.5 13.65625 15.457031 13.738281 C 15.457031 13.78125 15.203125 14.855469 14.984375 15.542969 C 14.941406 15.457031 14.125 13.265625 14.125 13.265625 L 13.914062 13.265625 C 13.914062 13.265625 13.183594 15.542969 13.183594 15.585938 C 13.140625 15.5 12.625 13.695312 12.625 13.695312 C 12.625 13.609375 12.582031 13.566406 12.582031 13.523438 L 12.582031 13.4375 C 12.625 13.394531 12.667969 13.394531 12.753906 13.394531 L 12.925781 13.394531 L 12.925781 13.265625 L 11.59375 13.265625 L 11.59375 13.4375 C 11.847656 13.480469 11.933594 13.480469 12.066406 13.910156 L 12.878906 16.484375 L 13.09375 16.484375 C 13.09375 16.484375 13.824219 14.253906 13.867188 14.210938 C 13.910156 14.296875 14.683594 16.484375 14.683594 16.484375 L 14.898438 16.484375 C 15.328125 14.984375 15.710938 13.953125 15.710938 13.910156 C 15.886719 13.480469 15.925781 13.4375 16.230469 13.4375 C 16.230469 13.4375 16.230469 13.222656 16.230469 13.222656 Z M 16.230469 13.222656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 17.089844 13.996094 C 17.089844 13.996094 17.429688 15.027344 17.472656 15.027344 L 16.746094 15.027344 C 16.703125 15.027344 17.089844 13.996094 17.089844 13.996094 Z M 17.6875 14.082031 L 17.34375 13.179688 L 17.132812 13.222656 L 16.144531 15.757812 C 15.972656 16.1875 15.929688 16.230469 15.628906 16.230469 L 15.628906 16.402344 L 16.746094 16.402344 L 16.746094 16.230469 L 16.613281 16.230469 C 16.527344 16.230469 16.441406 16.1875 16.441406 16.140625 L 16.441406 16.054688 C 16.441406 16.011719 16.441406 15.96875 16.484375 15.882812 L 16.527344 15.796875 C 16.570312 15.625 16.660156 15.367188 16.703125 15.28125 L 17.605469 15.28125 C 17.691406 15.539062 17.816406 15.839844 17.859375 15.96875 C 17.859375 16.011719 17.902344 16.054688 17.902344 16.097656 L 17.902344 16.140625 C 17.902344 16.183594 17.859375 16.183594 17.773438 16.183594 L 17.644531 16.226562 L 17.644531 16.355469 L 18.933594 16.355469 L 18.933594 16.183594 C 18.675781 16.140625 18.589844 16.140625 18.417969 15.710938 Z M 17.6875 14.082031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity="1"
          d="M 19.964844 13.652344 C 19.964844 13.523438 19.964844 13.441406 20.007812 13.441406 L 20.21875 13.441406 C 20.347656 13.441406 20.820312 13.484375 20.820312 14.039062 C 20.820312 14.425781 20.5625 14.640625 20.132812 14.640625 L 19.960938 14.640625 C 19.964844 14.640625 19.964844 13.652344 19.964844 13.652344 Z M 19.0625 13.222656 L 19.0625 13.398438 C 19.40625 13.441406 19.449219 13.441406 19.449219 13.996094 L 19.449219 15.714844 C 19.449219 16.230469 19.40625 16.273438 19.019531 16.316406 L 19.019531 16.488281 L 20.222656 16.488281 C 20.609375 16.488281 20.953125 16.402344 21.167969 16.273438 C 21.46875 16.101562 21.597656 15.84375 21.597656 15.542969 C 21.597656 15.113281 21.296875 14.816406 20.738281 14.730469 C 20.996094 14.6875 21.425781 14.472656 21.425781 14.042969 C 21.425781 13.785156 21.339844 13.613281 21.167969 13.484375 C 20.996094 13.355469 20.78125 13.308594 20.394531 13.308594 L 19.0625 13.308594 Z M 20.136719 14.769531 C 20.695312 14.769531 20.996094 15.027344 20.996094 15.5 C 20.996094 15.972656 20.652344 16.1875 20.351562 16.1875 C 20.222656 16.1875 20.136719 16.1875 20.09375 16.140625 C 20.007812 16.054688 20.007812 15.929688 20.007812 15.667969 L 20.007812 14.808594 C 20.007812 14.769531 20.136719 14.769531 20.136719 14.769531 Z M 20.136719 14.769531 "
        />
      </g>
    </svg>
  );
};

SchwabBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SchwabBankIcon;
