import React, {useMemo} from "react";
import styled, {useTheme} from "styled-components";
import {
  BuyNowActions,
  CartIconActions,
  HorizontalProductCard,
  ProductCard,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useSite from "src/core/sites/hooks/useSite";
import styles from "src/themes/baseTheme/components/ProductCard/styles";
import useProductCard from "src/core/common/hooks/useProductCard";
import merge from "lodash/merge";
import get from "lodash/get";
import {getFlowerTypeIndicatorProps} from "src/themes/sense/elements/flowerType";

export default function SenseProductCard({product, ...props}) {
  const {actionProps, cartProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();
  const actionsTheme = {
    v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
  };
  const site = useSite();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);

  const DesktopProductCard = useMemo(
    () => (
      <ProductCardStylesWrapper>
        <ProductCard
          {...props}
          {...productCardProps}
          SaleIndicatorComponent={props =>
            productCardProps.onSale && <SaleIndicator {...props} position={"left"} />
          }
          styles={styles(theme, site, {
            mode: props.mode,
          })}
          zoomOnHover={site.getUiConfiguration().productCard.zoomOnHover}
          potencyTagsPosition="bottom"
          flowerTypePosition={
            site.getUiConfiguration().productCard.flowerTypeIndicator.flowerTypePosition
          }
          showFlowerTypeIcon={
            site.getUiConfiguration().productCard.flowerTypeIndicator.showIcon
          }
          sizesPosition={site.getUiConfiguration().productCard.sizes.sizesPosition}
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          ActionsComponent={innerProps => (
            <BuyNowActions
              {...actionProps}
              {...innerProps}
              mode={props.mode || site.getUiConfiguration().productCard.mode}
              buyText={props.mode === "small" ? "Add" : "Add to cart"}
            />
          )}
        />
      </ProductCardStylesWrapper>
    ),
    // eslint-disable-next-line
    [product, actionProps]
  );

  const MobileProductCard = useMemo(
    () => (
      <ProductCardStylesWrapper isHorizontal theme={actionsTheme}>
        <HorizontalProductCard
          {...props}
          {...productCardProps}
          styles={styles(theme, site, {isHorizontalInMobile: true})}
          showSizes
          potencyTagsPosition="bottom"
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          SaleIndicatorComponent={props =>
            productCardProps.onSale && <SaleIndicator {...props} position={"right"} />
          }
          ActionsComponent={props => <CartIconActions {...actionProps} {...props} />}
        />
      </ProductCardStylesWrapper>
    ),
    // eslint-disable-next-line
    [product, actionProps]
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      TabletProductCard={DesktopProductCard}
      MobileProductCard={
        props.mode === "small" || site.getUiConfiguration().productCard.alwaysDesktopMode
          ? DesktopProductCard
          : MobileProductCard
      }
    />
  );
}

export const ProductCardStylesWrapper = styled.div`
  .product-card__info {
    > * {
      margin-bottom: 5px;
    }
  }

  .cart-icon-actions__container {
    justify-content: ${isHorizontal =>
      isHorizontal ? "space-between" : "flex-end"} !important;
  }

  .cart-icon-actions__actions {
    background-color: ${({theme, isHorizontal}) =>
      isHorizontal ? theme.v2.color.primary : "transparent"} !important;
    color: ${({theme, isHorizontal}) =>
      isHorizontal ? theme.v2.color.onBackground : theme.v2.color.primary} !important;
    border: ${({isHorizontal}) => (isHorizontal ? "0" : "2px")} solid
      ${({theme}) => theme.v2.color.primary} !important;
    height: 72px !important;
    width: 72px !important;

    > svg {
      color: ${({theme, isHorizontal}) =>
        isHorizontal ? theme.v2.color.onBackground : theme.v2.color.primary} !important;
      height: 24px !important;
      width: 24px !important;
    }

    @media (max-width: 767px) {
      height: 48px !important;
      width: 48px !important;

      > svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  .cart-icon-actions__actions--quantity {
    min-width: 100px !important;
    font-size: ${({theme, isHorizontal}) =>
      isHorizontal && theme.v2.typography.title5.size.lg} !important;
  }

  .cart-icon-buttons__container {
    min-width: 0 !important;
    margin-right: ${isHorizontal => (isHorizontal ? "0" : "20px")} !important;
  }

  .cart-icon-actions__button {
    > svg {
      color: ${({theme, isHorizontal}) =>
        isHorizontal ? theme.v2.color.onBackground : theme.v2.color.primary} !important;
    }
  }
`;
