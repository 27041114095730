import {CheckoutContext} from "src/core/checkout/context";
import {useShop} from "src/core/shops";
import {useForm} from "react-final-form";
import get from "lodash/get";
import {makePayment} from "src/core/payments/factories/payment";
import {useContext, useEffect, useMemo, useState} from "react";

export default function usePaymentDisclaimer() {
  const {
    order: {
      state: {data: order},
    },
    cart,
  } = useContext(CheckoutContext);
  const [shop] = useShop();
  const [disclaimer, setDisclaimer] = useState("");
  const form = useForm();
  const paymentOptionCode =
    get(form.getState(), "values.paymentOption") || order?.getPaymentOption();
  const paymentOption = useMemo(
    () => makePayment(paymentOptionCode),
    [paymentOptionCode]
  );
  const currentPayment = form.getState().values?.payment;

  useEffect(() => {
    (async () => {
      if (!paymentOption) return;
      try {
        const paymentDisclaimer = await paymentOption.getPaymentDisclaimer({
          shop: shop.data,
          cart: cart.currentCart,
          order: order,
          payment: currentPayment,
        });
        setDisclaimer(paymentDisclaimer);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [cart.currentCart, order, paymentOption, currentPayment, shop.data]);

  return {
    disclaimer,
  };
}
