import React from "react";
import Options from "../common/models/options";
import {DeliveryModes} from "../common/models/deliveryMode";

export const FeatureTogglesContext = React.createContext(null);

export class FeatureToggles {
  static _instance = null;

  static MEDICAL_ID_MODES = {
    FIELDS: "text",
    DOCUMENT: "picture",
    HIDDEN: "hidden",
  };

  static getInstance(site) {
    FeatureToggles._instance = new FeatureToggles(site);
    return FeatureToggles._instance;
  }

  static getCurrentInstance() {
    return FeatureToggles._instance;
  }

  static init(site) {
    return FeatureToggles.getInstance(site);
  }

  constructor(site) {
    this.site = site;
    this.options = site ? site.getOptions() : new Options({});
  }

  forceDeliveryTypeSelection() {
    return this.options.forceDeliveryTypeSelection();
  }

  goToHomeAfterOrderSuccessful() {
    return this.offSiteKioskMode();
  }

  showEmptyCartButton() {
    return this.isKiosk();
  }

  guestCheckoutEnabled() {
    return this.offSiteKioskMode() || this.options.guestCheckoutEnabled();
  }

  isGuestCheckoutActive(isLoggedIn) {
    return this.guestCheckoutEnabled() && !isLoggedIn;
  }

  strictMedicalOrdersEnabled() {
    return this.options.strictMedicalOrdersEnabled();
  }

  loginMode() {
    return this.options.getLoginMode();
  }

  pickupEnabled() {
    return !this.offSiteKioskMode() && this.options.pickupEnabled();
  }

  deliveriesEnabled() {
    return !this.offSiteKioskMode() && this.options.deliveriesEnabled();
  }

  groupDeliveriesEnabled() {
    return !this.offSiteKioskMode() && this.options.groupDeliveriesEnabled();
  }

  groupExpressDeliveriesEnabled() {
    return this.groupDeliveriesEnabled() && this.options.groupExpressDeliveriesEnabled();
  }

  groupScheduledDeliveriesEnabled() {
    return (
      this.groupDeliveriesEnabled() && this.options.groupScheduledDeliveriesEnabled()
    );
  }

  scheduledPickupsEnabled() {
    return !this.isKiosk() && this.options.scheduledPickupsEnabled();
  }

  scheduledDeliveriesEnabled() {
    return !this.isKiosk() && this.options.scheduledDeliveriesEnabled();
  }

  scheduledOrderEnabled() {
    return this.scheduledDeliveriesEnabled() || this.scheduledPickupsEnabled();
  }

  useRegionGeoZonesRestrictions() {
    return this.options.useRegionGeoZonesRestrictions();
  }

  registrationWithDriversLicenseID() {
    const values = ["required", "optional"];
    return values.includes(this.options.registrationWithDriversLicenseID());
  }

  driversLicenseIDRequired() {
    return this.options.registrationWithDriversLicenseID() === "required";
  }

  deliveryModeEnabled(deliveryMode) {
    switch (deliveryMode) {
      case DeliveryModes.EXPRESS:
        return this.expressDeliveriesEnabled();
      case DeliveryModes.SCHEDULED:
        return this.scheduledDeliveriesEnabled();
      case DeliveryModes.ASAP:
        return this.asapDeliveriesEnabled();
      default:
        return false;
    }
  }

  asapPickupsEnabled() {
    return this.options.asapPickupsEnabled();
  }

  asapDeliveriesEnabled() {
    return this.options.asapDeliveriesEnabled();
  }

  asapOrderEnabled(deliveryType) {
    return deliveryType === "delivery"
      ? this.asapDeliveriesEnabled()
      : this.asapPickupsEnabled();
  }

  groupPickupEnabled() {
    return !this.offSiteKioskMode() && this.options.groupPickupEnabled();
  }

  disabledEmail() {
    const emailModes = ["phone_or_email"];
    const currentMode = this.loginMode();
    return !emailModes.includes(currentMode);
  }

  loginWithEmail() {
    return this.loginMode() === "email";
  }

  loginWithPhone() {
    return this.loginMode() === "phone";
  }

  enabledCoupons() {
    return !this.options.couponsDisabled();
  }

  enabledRewards() {
    return !this.options.rewardsDisabled();
  }

  authenticationWithoutAgeConfirmation() {
    return this.options.authenticationWithoutAgeConfirmation();
  }

  hideCheckoutOrderNotes() {
    return this.options.hideCheckoutOrderNotes();
  }

  kioskMode() {
    return this.site.env.getAppMode() === "kiosk";
  }

  offSiteKioskMode() {
    return this.options.isOffSiteKiosk();
  }

  isKiosk() {
    return this.kioskMode();
  }

  allowMedicalCustomers() {
    return this.options.allowMedicalCustomers();
  }

  allowDeliveriesInGroup() {
    return this.options.allowDeliveriesInGroup();
  }

  allowNameAndDobUpdate() {
    return this.options.allowNameAndDobUpdate();
  }

  requiresDateOfBirthOnSignUp() {
    return !this.offSiteKioskMode();
  }

  requireMarketingSourceOnSignUp() {
    return !this.isKiosk() && this.options.requireMarketingSourceOnSignUp();
  }

  registrationWithStateResidency() {
    return this.options.registrationWithStateResidency();
  }

  defaultStateResidency() {
    return this.options.defaultStateResidency();
  }

  displayStateResidencyOnSignUp() {
    return this.registrationWithStateResidency() !== "hidden";
  }

  requiresStateResidencyOnSignUp() {
    return this.registrationWithStateResidency() === "required";
  }

  allowMarketingSourceOnSignUp() {
    return !this.isKiosk() && this.options.allowMarketingSourceOnSignUp();
  }

  allowDocumentsOnPersonalInformation() {
    return this.options.allowDocumentsOnPersonalInformation();
  }

  emailOnSignUpMode() {
    return !this.offSiteKioskMode() && this.options.emailOnSignUpMode();
  }

  requestEmailOnSignUp() {
    return (
      this.loginWithEmail() || ["required", "optional"].includes(this.emailOnSignUpMode())
    );
  }

  requiredEmailOnSignUp() {
    return this.loginWithEmail() || this.emailOnSignUpMode() === "required";
  }

  requiredPhoneOnSignUp() {
    return this.loginWithPhone() || this.phoneOnSignUpMode() === "required";
  }

  phoneOnSignUpMode() {
    return !this.offSiteKioskMode() && this.options.phoneOnSignUpMode();
  }

  requestPhoneOnSignUp() {
    return (
      this.loginWithPhone() || ["required", "optional"].includes(this.phoneOnSignUpMode())
    );
  }

  checkoutRequiresDocuments(opts) {
    return !this.isKiosk() && this.options.checkoutRequiresDocuments(opts);
  }

  deliveryCheckoutSelfieIdRequired() {
    return this.options.deliveryCheckoutSelfieIdUpload() === "required";
  }

  deliveryCheckoutSelfieIdHidden() {
    return this.options.deliveryCheckoutSelfieIdUpload() === "hidden";
  }

  pickupCheckoutSelfieIdRequired() {
    return this.options.pickupCheckoutSelfieIdUpload() === "required";
  }

  pickupCheckoutSelfieIdHidden() {
    return this.options.pickupCheckoutSelfieIdUpload() === "hidden";
  }

  allowExpressDelivery() {
    return this.groupExpressDeliveriesEnabled() || this.expressDeliveriesEnabled();
  }

  allowScheduledDelivery() {
    return this.groupScheduledDeliveriesEnabled() || this.scheduledDeliveriesEnabled();
  }

  expressDeliveriesEnabled() {
    return this.options.expressDeliveriesEnabled();
  }

  enabledFilters() {
    return this.options.enabledFilters();
  }

  priceFilterVisible() {
    return this.options.priceFilterVisible();
  }

  deliveriesOnly() {
    return this.site.env.getAppMode() === "deliveries";
  }

  pickupOnly() {
    return this.site.env.getAppMode() === "pickup";
  }

  deliveriesPath() {
    return this.options.deliveriesPath();
  }

  allowsDeliveryMode(mode, deliveryType) {
    if (!deliveryType || deliveryType.isDelivery()) {
      switch (mode) {
        case DeliveryModes.EXPRESS:
          return this.expressDeliveriesEnabled();
        case DeliveryModes.SCHEDULED:
          return this.scheduledDeliveriesEnabled();
        case DeliveryModes.ASAP:
          return this.asapDeliveriesEnabled();
        default:
          return false;
      }
    } else {
      switch (mode) {
        case DeliveryModes.SCHEDULED:
          return this.scheduledPickupsEnabled();
        case DeliveryModes.ASAP:
          return this.asapPickupsEnabled();
        default:
          return false;
      }
    }
  }

  groupAllowsDeliveryMode(mode) {
    switch (mode) {
      case DeliveryModes.EXPRESS:
        return this.allowExpressDelivery();
      case DeliveryModes.SCHEDULED:
        return this.allowScheduledDelivery();
      case DeliveryModes.ASAP:
        return this.asapDeliveriesEnabled();
      default:
        return false;
    }
  }

  allowTips() {
    return this.options.allowTips();
  }

  showCheckoutDisclaimer() {
    return this.options.getShowDisclaimerCheckout();
  }

  marketingSignatureRequired() {
    return this.options.marketingSignatureRequired();
  }

  showRecommendedProductsInCart() {
    return this.options.showRecommendedProductsInCart();
  }

  medicalIdMode() {
    return this.options.getMedicalIdMode() || FeatureToggles.MEDICAL_ID_MODES.DOCUMENT;
  }

  displayMedicalIdDocument() {
    return this.medicalIdMode() === FeatureToggles.MEDICAL_ID_MODES.DOCUMENT;
  }

  displayMedicalIdFields() {
    return this.medicalIdMode() === FeatureToggles.MEDICAL_ID_MODES.FIELDS;
  }

  allowMultiCoupons() {
    return this.options.allowMultiCoupons();
  }

  registrationOptional({deliveryType, inventoryType}) {
    return (
      (!deliveryType ||
        !this.checkoutRequiresDocuments({
          inventoryType,
          deliveryType: deliveryType.code,
        })) &&
      this.guestCheckoutEnabled()
    );
  }

  getIdentityVerificationServiceName() {
    return this.options.getIdentityVerificationService();
  }

  showProductDetailRecommendations() {
    return !this.options.hasInStoreExperienceEventFlow();
  }

  includeTipInOrderTotal() {
    return this.options.includeTipInOrderTotal();
  }
}
