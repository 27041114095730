import {document, window} from "browser-monads";
import Logger from "../../core/common/logger";
import Tracker from "../../core/analytics/trackers/tracker";

const w = window;

class GoogleAnalytics4Tracker extends Tracker {
  constructor(googleAnalyticsId) {
    super();
    this.googleAnalyticsId = googleAnalyticsId;
  }

  init() {
    /* eslint-disable */
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + this.googleAnalyticsId;
    document.head.appendChild(script);
    w.dataLayer = w.dataLayer || [];

    w.gtag =
      w.gtag ||
      function() {
        w.dataLayer.push(arguments);
      };

    w.gtag("js", new Date());

    w.gtag("config", this.googleAnalyticsId, {
      debug_mode: process.env.NODE_ENV === "development",
      anonymizeIp: false,
      userId: false,
      name: "",
      sampleRate: 100,
      siteSpeedSampleRate: 1,
      cookieDomain: "auto",
      allowLinker: true,
    });
    /* eslint-enable */
  }

  doTrack(eventName, payload) {
    Logger.debug("gtag::doTrack");
    w.gtag("event", eventName);
  }

  doPageView(payload) {
    Logger.debug("gtag::doPageView");
    w.gtag("config", this.googleAnalyticsId, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }

  doValidateInitialization() {
    return Boolean(w.dataLayer);
  }
}

export default GoogleAnalytics4Tracker;
