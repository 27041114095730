import React from "react";
import styled, {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import {getSwifterFormProps} from "./styles";
import usePaymentDisclaimer from "src/core/checkout/hooks/payments/usePaymentDisclaimer";
import PaymentDisclaimer from "@ui/components/CheckoutPaymentMethod/PaymentDisclaimer";
import {Field} from "react-final-form";

function SwifterPaymentForm() {
  const theme = useTheme();
  const site = useSite();
  const {disclaimer} = usePaymentDisclaimer();

  const styles = getSwifterFormProps(theme, site);

  return (
    <Container styles={styles.container}>
      <Field
        name={"payment.dummy"}
        initialValue={"none"}
        render={() => (
          <PaymentDisclaimer
            styles={styles.emptyPlaceholderTitle}
            disclaimer={disclaimer}
          />
        )}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;

export default SwifterPaymentForm;
