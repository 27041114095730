import * as React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({width, height, backgroundColor, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" rx="3" fill={backgroundColor} />
      <path
        d="M9 3L3 9"
        stroke={color || "white"}
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 3L9 9"
        stroke={color || "white"}
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
};

export default CloseIcon;
