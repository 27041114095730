import {getSiteInCluster} from "src/server/clusters";
import {getSite} from "src/server/preloader/site";

const fetchSSGData = async (shop, host) => {
  const siteConfig = getSiteInCluster(host);

  return {
    site: await getSite(shop, host),
    shop: null,
    host: null,
    siteGroupName: siteConfig.name,
  };
};

export default fetchSSGData;
