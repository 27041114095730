import Tracker from "src/core/analytics/trackers/tracker";
import {document, window} from "browser-monads";
import Logger from "src/core/common/logger";

const w = window;

export default class SalesforceTracker extends Tracker {
  constructor(salesforceApiKey) {
    super();
    this.salesforceApiKey = salesforceApiKey;
  }

  init() {
    const script = document.createElement("script");
    script.async = true;
    script.src = `//${this.salesforceApiKey}.collect.igodigital.com/collect.js`;
    document.head.appendChild(script);
    w._etmc = w._etmc || [];
    w._etmc.push(["setOrgId", this.salesforceApiKey]);
    w._etmc.push(["trackPageView"]);
  }

  doIdentify(payload) {
    Logger.debug("Salesforce::doIdentify");
    if (!payload) {
      return;
    }

    const identifyPayload = {
      email: payload.attributes.email,
    };

    w._etmc.push(["setOrgId", this.salesforceApiKey]);
    w._etmc.push(["setUserInfo", identifyPayload]);
    w._etmc.push(["trackPageView"]);
  }

  doTrack(eventName, payload) {}

  doValidateInitialization() {
    return Boolean(w._etmc);
  }
}
