import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {useCallback, useEffect, useState} from "react";
import * as PaymentsApi from "src/core/api/payments";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";

const initState = {
  paymentSources: null,
  meta: {
    loading: true,
    error: false,
    requested: true,
  },
};

const setPaymentSourcesState = ({sourceType, setPaymentSources}) => {
  setPaymentSources({
    paymentSources: [],
    meta: {
      loading: true,
      error: false,
      requested: true,
    },
  });

  PaymentsApi.paymentSources(sourceType).then(({data}) => {
    setPaymentSources({
      paymentSources: data.map(obj => makePaymentSource(obj, sourceType)),
      meta: {
        loading: false,
        error: false,
        requested: true,
      },
    });
  });
};

export default function usePaymentSources({sourceType}) {
  const [, authApi] = useAuthentication();
  const [paymentSources, setPaymentSources] = useState(initState);

  const user = authApi.userProfile();
  const requestCondition = authApi.isLoggedIn();

  const refresh = useCallback(() => {
    if (requestCondition) {
      setPaymentSourcesState({sourceType, setPaymentSources});
    } else {
      setPaymentSources(setLoadingFalse);
    }
  }, [requestCondition]);

  useEffect(() => {
    if (user) {
      setPaymentSourcesState({sourceType, setPaymentSources});
    } else {
      setPaymentSources(setLoadingFalse);
    }
  }, [user]);

  return {
    ...paymentSources,
    refresh,
  };
}

const setLoadingFalse = paymentSources => ({
  ...paymentSources,
  meta: {...paymentSources.meta, loading: false},
});
