import React, {useEffect, useState} from "react";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import {FilterParams} from "@menu/utils/constants";
import useFullPageProductsList from "@menu/hooks/useFullPageProductsList";
import useThemeConfig from "src/themes/useThemeConfig";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import useRouter from "src/core/common/hooks/useRouter";
import useAppliedFilters, {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import Product from "src/core/common/models/product";
import useSite from "src/core/sites/hooks/useSite";
import {PageHead} from "src/core/seo/components/Head";
import useSubcategories from "src/core/common/hooks/useSubcategories";

export default function useDetailProductsList() {
  const site = useSite();
  const config = useThemeConfig();
  const prefetchedProducts = usePrefetchedProducts();
  const {state, actions} = useFullPageProductsList();
  const noResults = !state.showSkeleton && !state.hasMore && state.data.length === 0;

  const router = useRouter();
  const {productId} = router.query;
  const [paramFilters] = useAppliedFilters();
  const [filters, setFilters] = useState(paramFilters);
  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  const [catalog] = useFiltersCatalog({
    // clearing subcategories, so we always request all subcategories for the current category
    filters: ProductFilters.fromPrototype(filters, {
      subcategory: null,
      [FilterParams.PRODUCT_SUB_CATEGORY]: null,
    }),
  });
  const categories = catalog ? catalog.categories : [];
  const category = categories
    ? categories.find(c => c.getSlug() === filters.category)
    : null;
  const {subcategories} = useSubcategories(category, categories);

  let products = [];
  if (state.data.length) {
    products = state.data;
  } else if (prefetchedProducts) {
    products = prefetchedProducts;
  }

  return {
    site,
    products: !noResults ? products : undefined,
    skeleton: !prefetchedProducts && state.showSkeleton,
    hasMore: state.hasMore,
    loading: false,
    next: actions.requestProducts,
    ProductCardComponent: config.components.ProductCard,
    totalCount: state.totalCount,
    title: category?.getName(),
    description: category?.getDescription(),
    category,
    subcategories,
    pageHead: (
      <PageHead
        title={category?.getName()}
        description={category?.getDescription()}
        titleTemplate={site.getOptions().getCategoryTitleTemplate()}
      />
    ),
  };
}

function usePrefetchedProducts() {
  const {data} = useServerContext();
  const router = useRouter();

  const {category, brand} = router.query;

  const prefetchedProducts = data[PrefetchedDataKeys.PRODUCTS];

  if (
    !prefetchedProducts ||
    prefetchedProducts?.params?.category !== category ||
    prefetchedProducts?.params?.brand !== brand
  )
    return null;

  return prefetchedProducts.data.objects.map(p => new Product(p));
}
