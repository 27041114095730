import React from "react";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

const BankIcon = dynamic(
  () =>
    import("src/core/payments/stronghold/bank-icons/index").catch(
      logModuleLoadError("BankIcon")
    ),
  {
    ssr: false,
  }
);

function LazyBankIcon(props) {
  return <BankIcon {...props} />;
}

export default LazyBankIcon;
