function getCluster() {
  return require("../../cluster.json");
}

function getAllClusters() {
  return require("../../clusters.json");
}

const getSiteInCluster = domain => {
  const cluster = getCluster();
  if (!domain) return cluster[0];
  const _domain = domain.split(":")[0];
  const entry = cluster.find(
    entry => entry.domains && entry.domains.indexOf(_domain) > -1
  );
  return entry ? entry : cluster[0];
};

module.exports = {
  getCluster,
  getAllClusters,
  getSiteInCluster,
};
