import axios from "axios";
import {makeJsonApiError} from "src/core/common/utils";
import get from "lodash/get";
import {isClient} from "src/server/utils/isClient";
import {adapter} from "./cache";
import Router from "next/router";
import errorHandlers from "src/core/api/errorHandlers";

class TymberAPIConfig {
  config = {
    axiosInstance: axios,
  };

  headers = {};

  initListeners = [];

  init({
    baseUrl,
    appMode,
    storeId,
    kioskId,
    authorization,
    router,
    fcm,
    ordersEndpointV5,
  }) {
    import("axios-progress-bar").then(
      mod =>
        isClient && mod.loadProgressBar({showSpinner: false}, this.config.axiosInstance)
    );

    this.headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "X-Store": storeId,
    };

    if (appMode) this.headers["X-App-Mode"] = appMode;

    const authorizationToken = authorization || this.getAuthorizationToken();

    if (kioskId) {
      this.headers["X-Kiosk"] = kioskId;
    }
    this.baseUrl = baseUrl;
    this.router = router || this.router || Router;

    /// Firebase Cloud Messaging
    if (fcm) {
      this.fcmWebKey = fcm.webKey;
      this.fcmApiKey = fcm.apiKey;
      this.fcmProjectId = fcm.projectId;
      this.fcmMessagingSenderId = fcm.messagingSenderId;
      this.fcmAppId = fcm.appId;
      this.fcmActive = fcm.active;
    }
    this.ordersEndpointV5 = ordersEndpointV5;

    this.config.axiosInstance = this.createAxiosInstance(this.headers);
    if (authorizationToken) {
      this.config.axiosInstance.defaults.headers.common["Authorization"] =
        authorizationToken;
    }
    this.initialized = true;
    this.onInit();
  }

  onInit() {
    this.initListeners.forEach(listener => listener(this.config.axiosInstance));
  }

  addInitListener(listener) {
    this.initListeners.push(listener);
    return () => {
      this.initListeners = this.initListeners.filter(l => l !== listener);
    };
  }

  getAuthorizationToken() {
    return this.config.axiosInstance.defaults.headers.common["Authorization"];
  }
  setAuthorizationToken(authorizationToken) {
    const headers = {
      ...this.headers,
      Authorization: authorizationToken ? `Bearer ${authorizationToken}` : undefined,
    };

    Object.keys(headers).forEach(key => {
      this.config.axiosInstance.defaults.headers.common[key] = headers[key];
    });
  }

  axios() {
    return this.config.axiosInstance;
  }

  addInterceptor(interceptorFunction) {
    const axiosInstance = this.axios();
    const id = axiosInstance.interceptors.request.use(interceptorFunction);
    return () => {
      this.removeInterceptor(id);
    };
  }

  removeInterceptor(id) {
    const axiosInstance = this.axios();
    axiosInstance.interceptors.request.eject(id);
  }

  createAxiosInstance = headers => {
    if (!this.baseUrl) throw new Error("Please set a baseUrl for TymberAPIConfig");

    const instance = axios.create({
      adapter: adapter,
      baseURL: this.baseUrl,
      headers,
      skipNotification: () => false,
    });
    this.addResponseInterceptor(instance);
    return instance;
  };

  addResponseInterceptor = axiosInstance => {
    const defaultMessages = {
      404: "Not found.",
      401: "Unauthorized operation. Please login before proceeding.",
      403: "You don't have enough permissions for this operation.",
    };

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      error => {
        if (error.response) {
          const code = get(error.response, "data.errors.0.code", null);
          const apiCode = get(
            error.response,
            "data.errors.0.extra_info.api_error_code",
            null
          );
          const url = get(error.response, "config.url", null);
          const message = get(
            error.response,
            "data.errors.0.detail",
            defaultMessages[error.response.status] || "An error has occurred."
          );

          const errorHandlingData = {
            error,
            code,
            apiCode,
            url,
            message,
            router: this.router,
          };

          for (let i = 0; i < errorHandlers.length; i++) {
            if (errorHandlers[i](errorHandlingData)) {
              break;
            }
          }

          return Promise.reject(error.response);
        } else if (error.request) {
          return Promise.reject(
            makeJsonApiError("Failed to contact network. Please try again later.")
          );
        } else {
          return Promise.reject(makeJsonApiError(error.message));
        }
      }
    );
  };
}

export default new TymberAPIConfig();
