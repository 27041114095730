import React, {useState} from "react";
import styled from "styled-components";
import Separator from "@ui/components/Separator/Separator";
import PropTypes from "prop-types";
import {merge} from "lodash";
import StepIndicator from "@ui/components/MultiStepForm/StepIndicator";
import Button from "@ui/components/Button";
import {Form} from "react-final-form";
import media from "@ui/utils/media";

function MultiStepForm({styles, steps, initialValues, onFinish, onError}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(initialValues || {});
  const [loading, setLoading] = useState(false);
  const _styles = merge({}, defaultStyles, styles);

  const onContinue = async (formValues) => {
    setLoading(true);
    let data = merge({}, formData, formValues);

    if (steps[currentStep]?.onNext) {
      try {
        const additionalData = await steps[currentStep].onNext(data);
        data = merge({}, data, additionalData);
      } catch (e) {
        setLoading(false);
        onError(e);
        return;
      }
    }

    if (currentStep === steps.length - 1) {
      try {
        await onFinish(data);
      } catch (e) {
        onError(e);
      }
    } else {
      setFormData(data);
      setCurrentStep(currentStep + 1);
    }
    setLoading(false);
  };
  return (
    <Form
      initialValues={formData}
      mutators={{
        setValue: ([field, value], state, {changeValue}) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <FormContainer styles={_styles.root}>
              <FormHeader>
                <StepIndicator
                  styles={_styles.stepIndicator}
                  steps={steps}
                  currentStep={currentStep}
                  onClickStep={(stepNumber) => setCurrentStep(stepNumber)}
                />
              </FormHeader>
              <Separator />
              <FormBody>{steps[currentStep].form}</FormBody>
            </FormContainer>
            <Button
              label={"Continue"}
              type={"submit"}
              variant={"primary"}
              disabled={loading}
              loading={loading}
              styles={styles.continueButton}
            />
          </Container>
        </form>
      )}
      onSubmit={onContinue}
    />
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
`;
const FormContainer = styled.div`
  border-radius: ${({styles}) => styles.borderRadius};
  border: 1px solid #d9d9d9;

  ${media.up("lg")} {
    width: 600px;
  }
  ${media.down("md")} {
    width: 600px;
  }
  ${media.down("sm")} {
    width: 100%;
  }
`;

const FormHeader = styled.div`
  padding: 16px;
`;
const FormBody = styled.div`
  background-color: #f5f5f5;
`;

const defaultStyles = {
  root: {
    borderRadius: "4px",
  },
};
MultiStepForm.propTypes = {
  initialValues: PropTypes.object,
  styles: PropTypes.shape({
    root: {
      borderRadius: PropTypes.string,
    },
    stepIndicator: StepIndicator.propTypes.styles,
    continueButton: Button.propTypes.styles,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      form: PropTypes.node,
    })
  ),
};

export default MultiStepForm;
