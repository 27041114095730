import routes from "src/core/common/routes";

export const getGroupsFromTags = (tags, baseFilters) =>
  tags
    .filter(tag => tag.isFeatured())
    .map(tag => ({
      description: tag.getDescription(),
      name: tag.getTitle(),
      slug: tag.getSlug(),
      filters: baseFilters.merge({tag: tag.getSlug()}),
      url: makeUrl(tag, "tag"),
      type: "tag",
    }));

export const getGroupsFromCategories = (categories, baseFilters) => {
  const categoryById = categories.reduce((acc, c) => {
    acc[c.getId()] = c;
    return acc;
  }, {});

  return categories.map(category => {
    const parentCategoryId = category.getParentCategoryId();

    let urlParams;
    if (parentCategoryId !== null && categoryById[parentCategoryId]) {
      urlParams = [
        {
          parentCategory: categoryById[parentCategoryId],
          category,
        },
        "subcategory",
      ];
    } else {
      urlParams = [category, "category"];
    }

    return {
      description: category.getDescription(),
      name: category.getName(),
      slug: category.getSlug(),
      filters: baseFilters.merge({category: category.getSlug()}),
      url: makeUrl(...urlParams),
      type: "category",
    };
  });
};

export const getGroupsFromBrands = (brands, baseFilters) =>
  brands.map(brand => ({
    description: brand.getDescription(),
    name: brand.getName(),
    slug: brand.getSlug(),
    filters: baseFilters.merge({brand: brand.getSlug()}),
    url: makeUrl(brand, "brand"),
    type: "brand",
  }));

const makeUrl = (section, type) => {
  switch (type) {
    case "category":
      return {
        pathname: routes.productCategory,
        params: {category: section.getSlug()},
      };
    case "subcategory":
      return {
        pathname: routes.productSubcategory,
        params: {
          category: section.parentCategory.getSlug(),
          subcategory: section.category.getSlug(),
        },
      };
    case "brand":
      return {
        pathname: routes.brandDetail,
        params: {brand: section.getSlug()},
      };
    case "tag":
      return {
        pathname: routes.products,
        query: {tag: section.getSlug()},
      };
    default:
      return {
        pathname: routes.products,
      };
  }
};
