import React, {useEffect} from "react";
import "@adyen/adyen-web/dist/adyen.css";
import {Field} from "react-final-form";
import styled, {useTheme} from "styled-components";
import {FieldContainer} from "src/core/common/components/collections/form/forms";
import useAdyenForm from "src/core/payments/adyen/useAdyenForm";
import {InputContainer} from "@ui/components/FormFields/InputContainer";
import Input from "ui/src/components/Inputs";
import {required} from "src/core/common/validations";
import {useShop} from "src/core/shops";
import BillingAddressForm from "@ui/components/CheckoutPaymentMethod/BillingAddressForm";
import {getCreditCardFormProps} from "src/core/payments/utils";
import {
  getLedgerGreenCreditCardFormProps,
  getLedgerGreenFormProps,
} from "src/core/payments/ledgerGreen/styles";
import useSite from "src/core/sites/hooks/useSite";
import {Error} from "@ui/components/FormFields/Error";
import {useForm} from "react-final-form";

function AdyenNewCardForm({cart, order, styles, disabled}) {
  const {checkoutInstance, formState} = useAdyenForm({cart, order});

  const [, shop] = useShop();

  const formValues = useForm().getState().values;

  const shopCountry = shop.getShop()?.getCountry();
  const creditCardFormProps = getCreditCardFormProps({
    selectedCountry: formValues?.payment?.billingCountry?.value || shopCountry,
    initialCountry: shopCountry,
  });

  const theme = useTheme();
  const site = useSite();
  const formStyles = getLedgerGreenCreditCardFormProps(theme, site);
  const errorStyles = getLedgerGreenFormProps(theme, site).error;

  return (
    <Container id="customCard-container">
      <div>
        <Field
          validate={required}
          name="payment.holderName"
          type="text"
          render={({input, meta}) => (
            <InputContainer>
              <Input
                {...input}
                styles={styles.input}
                disabled={disabled}
                hasErrors={meta.touched && meta.error}
                placeholder={"Name on card"}
              />
              {meta.touched && <Error styles={errorStyles}>{meta.error}</Error>}
            </InputContainer>
          )}
        />
      </div>
      <BillingAddressForm
        styles={{...formStyles, error: errorStyles}}
        disabled={disabled}
        addressProps={creditCardFormProps.addressProps}
        cityProps={creditCardFormProps.cityProps}
        stateProps={creditCardFormProps.stateProps}
        postalCodeProps={creditCardFormProps.cardPostalCodeProps}
        buildingNumberProps={creditCardFormProps.buildingNumberProps}
        countryProps={creditCardFormProps.countryProps}
      />
      <div>
        <Field
          validate={validateCreditCard}
          name="payment.source.creditCard"
          render={({input, meta}) => (
            <HostedField
              styles={styles.input}
              errorStyles={errorStyles}
              name={"encryptedCardNumber"}
              checkoutInstance={checkoutInstance}
              formState={formState}
              meta={meta}
              input={input}
            />
          )}
        />
      </div>
      <SmallContainer>
        <Field
          validate={validateExpiryDate}
          name="creditCardExpirationDate" // just used for validation
          render={({input, meta}) => (
            <HostedField
              styles={styles.input}
              errorStyles={errorStyles}
              name={"encryptedExpiryDate"}
              checkoutInstance={checkoutInstance}
              formState={formState}
              meta={meta}
              input={input}
            />
          )}
        />
        <Field
          validate={validateSecurityCode}
          name="creditCardCVC" // just used for validation
          render={({input, meta}) => (
            <HostedField
              styles={styles.input}
              errorStyles={errorStyles}
              name={"encryptedSecurityCode"}
              checkoutInstance={checkoutInstance}
              formState={formState}
              meta={meta}
              input={input}
            />
          )}
        />
      </SmallContainer>
    </Container>
  );
}

function HostedField({
  name,
  input,
  meta,
  checkoutInstance,
  formState,
  errorStyles,
  styles,
}) {
  useEffect(() => {
    if (!checkoutInstance) return;
    return checkoutInstance.addOnFocusListener(params => {
      if (params.fieldType === name && !params.focus) {
        input.onBlur(params);
      }
    });
  }, [checkoutInstance, name]);

  useEffect(() => {
    input.onChange(formState);
  }, [formState]);

  return (
    <CustomFieldContainer>
      <HostedFieldElement
        styles={styles}
        hasErrors={meta.touched && meta.error}
        data-cse={name}
      ></HostedFieldElement>
      {meta.touched && <Error styles={errorStyles}>{meta.error}</Error>}
    </CustomFieldContainer>
  );
}

const makeValidator = (fieldName, fieldLabel) => {
  return formState => {
    if (!formState?.valid?.[fieldName]) return `Invalid ${fieldLabel}`;

    return undefined;
  };
};
const validateCreditCard = makeValidator("encryptedCardNumber", "credit card");

const validateSecurityCode = makeValidator("encryptedSecurityCode", "security code");
const validateExpiryDate = formState => {
  if (
    !formState?.valid?.["encryptedExpiryMonth"] ||
    !formState?.valid?.["encryptedExpiryYear"]
  )
    return `Invalid expiry date`;

  return undefined;
};

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: ${({theme}) => theme.v2.spacing(4)};
`;

const HostedFieldElement = styled.div`
  height: 46px;
  border: ${({styles, hasErrors}) =>
    hasErrors ? styles?.error?.border : "1px solid #d9d9d9"};
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
`;

const SmallContainer = styled.div`
  display: flex;
  column-gap: ${({theme}) => theme.v2.spacing(2)};
`;

const CustomFieldContainer = styled(FieldContainer)``;

export default AdyenNewCardForm;
