import React from "react";
import styled, {useTheme} from "styled-components";
import Skeleton from "react-loading-skeleton";
import useSite from "src/core/sites/hooks/useSite";
import {getMonerisFormProps} from "./styles";
import {formatMonerisCss} from "src/core/payments/moneris/utils";
import Error from "src/core/common/components/elements/Error";
import {Input} from "@ui/components";
import {Field} from "react-final-form";

function MonerisNewCardForm({monerisData}) {
  const {
    loading,
    profileId,
    errors,
    getErrorDescription,
    hasCards,
    disabled,
    iframeDomain,
  } = monerisData;

  const theme = useTheme();
  const site = useSite();

  const styles = getMonerisFormProps(theme, site);

  const bodyCss = formatMonerisCss(styles.bodyCss);
  const textBoxCss = formatMonerisCss(styles.textBoxCss);
  const cardTextBoxCss = formatMonerisCss(styles.cardTextBoxCss);
  const expTextBoxCss = formatMonerisCss(styles.expTextBoxCss);
  const cvcTextBoxCss = formatMonerisCss(styles.cvcTextBoxCss);

  const cardNumberLabel = encodeURIComponent("Card number");
  const expDateLabel = encodeURIComponent("Expiration Date (MMYY)");
  const cvcLabel = encodeURIComponent("CVC");

  const iframeStyles = {
    border: 0,
    opacity: disabled ? 0.7 : 1.0,
    pointerEvents: disabled ? "none" : "unset",
    cursor: disabled ? "not-allowed" : "unset",
  };

  return (
    <Container styles={styles.container} hasCards={hasCards}>
      {loading ? (
        <Skeleton height={50} count={2} />
      ) : (
        <>
          {profileId && (
            <FormContainer>
              <Field
                name="payment.source.cardholderName"
                render={({input}) => (
                  <Input
                    {...input}
                    data-cy="moneris-cardholder-input"
                    disabled={disabled}
                    placeholder="Cardholder Name"
                    onChange={input.onChange}
                    styles={styles.cardholderInput}
                  />
                )}
              />

              <iframe
                name="monerisFrame"
                title="monerisFrame"
                id="monerisFrame"
                data-cy="moneris-iframe"
                src={`https://${iframeDomain}/HPPtoken/index.php?id=${profileId}&display_labels=2&enable_cvd=1&enable_exp=1&css_body=${bodyCss}&css_textbox=${textBoxCss}&css_textbox_pan=${cardTextBoxCss}_&css_textbox_exp=${expTextBoxCss}&css_textbox_cvd=${cvcTextBoxCss}&pan_label=${cardNumberLabel}&exp_label=${expDateLabel}&cvd_label=${cvcLabel}`}
                width="100%"
                height={styles.iframe.height}
                style={iframeStyles}
              />
            </FormContainer>
          )}
          <div>
            {errors.map(e => (
              <Error>{getErrorDescription(e)}</Error>
            ))}
          </div>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-top: ${({styles, hasCards}) => (hasCards ? styles.padding : "0px")};
`;

const FormContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export default MonerisNewCardForm;
