import {PaymentOptions} from "src/core/payments/constants";
import LedgerGreen from "src/core/payments/ledgerGreen/LedgerGreen";
import LedgerGreenPaymentForm from "src/core/payments/ledgerGreen/LedgerGreenPaymentForm";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";

export default {
  type: PaymentOptions.LEDGERGREEN,
  PaymentClass: LedgerGreen,
  PaymentForm: LedgerGreenPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
