import PropTypes from "prop-types";
import * as React from "react";
import CannabisIndica from "@ui/components/IconsSVGs/CannabisIndica";
import CannabisHybrid from "@ui/components/IconsSVGs/CannabisHybrid";
import CannabisSativa from "@ui/components/IconsSVGs/CannabisSativa";

export const CannabisIcon = ({abbreviation}) => {
  switch (abbreviation) {
    case "I":
      return <CannabisIndica />;
    case "H":
      return <CannabisHybrid />;
    case "ID":
      return <CannabisIndica />;
    case "S":
      return <CannabisSativa />;
    case "SD":
      return <CannabisSativa />;
    default:
      return <CannabisIndica />;
  }
};

CannabisIcon.propTypes = {
  abbreviation: PropTypes.string,
};
