import React, {useEffect, useMemo, useState} from "react";
import "src/index.css";
import GlobalStyles from "src/core/common/components/GlobalStyles";
import dynamic from "next/dynamic";
import {SiteHead} from "src/core/seo/components/Head";
import {ModalContextProvider} from "src/core/common/components/modules/modal/modalContext";
import {makeAppProvider} from "src/apps/common/appFactory";
import {logModuleLoadError} from "src/core/common/utils";
import {isSSG} from "src/server/utils/isSSG";
import IdentityVerificationMain from "src/integrations/identityVerification/components/IdentityVerificationMain";
import Dialog from "src/core/notifications/components/Dialog";
import TymberToast from "src/core/notifications/components/TymberToast";
import MobileAppDownload from "src/integrations/mobileApp/MobileAppDownload";
import useScrollRestoration from "src/core/common/hooks/useScrollRestoration";
import {useHistoryManager} from "src/core/common/hooks/useHistoryManager";
import {getHome, getHomeStaticProps} from "src/server/preloader/homePage";

const AgeGate = dynamic(() =>
  import("src/core/user/components/AgeGate").catch(
    logModuleLoadError("AgeGate", {critical: true})
  )
);

function TymberApp({router, pageProps, initialProps, Component}) {
  const [requiredProps, setRequiredProps] = useState(initialProps?.props || pageProps);

  useEffect(() => {
    if (!requiredProps.site) {
      const {shop} = router.query;
      getHome(shop, window.location.hostname, {}).then(setRequiredProps);
    }
  }, [requiredProps.site]);

  if (!requiredProps.site) {
    return <div />;
  }

  return <WorkingApp pageProps={requiredProps} Component={Component} router={router} />;
}

function WorkingApp({Component, pageProps, router}) {
  const {shop, host, siteGroupName, site} = pageProps;
  const AppProvider = makeAppProvider(shop, host);

  useScrollRestoration(router);
  useHistoryManager(router);

  useEffect(() => {
    import("src/serviceWorker").then(mod => {
      mod.unregisterAll();
    });
  }, []);

  const _initialSite = useMemo(() => {
    return site;
    // eslint-disable-next-line
  }, []);

  if (!isSSG() && !_initialSite) {
    return <Component {...pageProps} />;
  }

  return (
    <AppProvider
      siteGroupName={siteGroupName}
      host={{host: host}}
      initialSite={_initialSite}
    >
      <SiteHead />
      <GlobalStyles />
      <TymberToast />
      <Dialog />
      <AgeGate />
      <MobileAppDownload />
      <ModalContextProvider>
        <Component {...pageProps} />
        <IdentityVerificationMain />
      </ModalContextProvider>
    </AppProvider>
  );
}

/* eslint-disable */
// This gets converted to getInitialProps when running in SSG mode
let result = null;
async function ssg_getInitialProps(router, ctx) {
  result = result || await getHomeStaticProps(ctx)
  return {
    initialProps:result
  };
}
const ssr_getInitialProps = null;
const isr_getInitialProps = null
TymberApp.getInitialProps = getInitialProps;
/* eslint-enable */

export default TymberApp;
