import {useEffect, useState} from "react";
import {PaymentOptions} from "src/core/payments/constants";
import {MonerisEvents} from "src/core/payments/moneris/Moneris";
import useCreditCardPaymentSources from "src/core/payments/hooks/useCreditCardPaymentSources";

export default function useMoneris({cart, order}) {
  const [errors, setErrors] = useState([]);
  const [config, setConfig] = useState();

  const [showExternalAuthForm, setShowExternalAuthForm] = useState(false);
  const [challengeInfo, setChallengeInfo] = useState({});

  const {
    loading,
    disabled,
    refresh,
    paymentOption,
    paymentSources,
    removeAccount,
    initialPaymentSource,
  } = useCreditCardPaymentSources({cart, order, sourceType: PaymentOptions.MONERIS});

  const fetchConfig = async () => {
    const config = await paymentOption.getConfiguration();
    setConfig(config);
  };

  useEffect(() => {
    void fetchConfig();
  }, []);

  useEffect(() => {
    refresh();
  }, [cart]);

  useEffect(() => {
    return paymentOption.subscribe(({event, data}) => {
      switch (event) {
        case MonerisEvents.CREATE_CARD_ERROR:
          setErrors(data);
          break;
        case MonerisEvents.START_EXTERNAL_AUTH:
          setShowExternalAuthForm(true);
          setChallengeInfo(data);
          break;
        case MonerisEvents.EXTERNAL_AUTH_SUCCESS:
          setShowExternalAuthForm(false);
          break;
        case MonerisEvents.EXTERNAL_AUTH_FAILURE:
          setShowExternalAuthForm(false);
          break;
        default:
          break;
      }
    });
  }, [paymentOption]);

  const getErrorDescription = error => {
    return paymentOption.monerisErrorCodes[error];
  };

  const externalAuthFormProps = {
    showForm: showExternalAuthForm,
    challengeUrl: challengeInfo.challengeUrl,
    challengeData: challengeInfo.challengeData,
  };

  return {
    loading,
    iframeDomain: paymentOption.domain,
    disabled,
    paymentSources,
    hasCards: paymentSources?.length > 0,
    initialPaymentSource,
    profileId: config?.publicKey,
    errors,
    getErrorDescription,
    removeAccount,
    externalAuthFormProps,
  };
}
