import useQueryParams from "src/core/common/hooks/useQueryParams";

export const PROMO_CODE_PARAM = "promo";
function usePromoCode() {
  const [params, setParam] = useQueryParams([PROMO_CODE_PARAM]);

  let promoCode = params[PROMO_CODE_PARAM];

  function setPromoCode(code) {
    setParam(PROMO_CODE_PARAM, code);
  }

  return [promoCode, setPromoCode];
}

export default usePromoCode;
