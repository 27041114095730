import React from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import media from "@ui/utils/media";

import Select from "react-select";
import styled from "styled-components";
import {isClient} from "@ui/utils/isClient";

export default function DropdownSelectInput({
  placeholder,
  options,
  isMulti,
  isLoading,
  disabled,
  onChange,
  styles,
  value,
  hasError,
  errorStyles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (!isClient()) return null;

  return (
    <StyledSelect
      hasError={hasError}
      errorStyles={errorStyles}
      value={value}
      classNamePrefix="react-select"
      isLoading={isLoading}
      isDisabled={isLoading || disabled}
      options={options}
      isMulti={isMulti}
      placeholder={placeholder}
      onChange={onChange}
      customStyles={_styles}
      styles={{
        menuPortal: (base) => ({...base, zIndex: 9999}),
        option: (base) => ({
          ...base,
          fontSize: _styles.menu.fontSize.lg,
          fontFamily: _styles.menu.fontFamily,
        }),
      }}
      menuPortalTarget={document.body}
      menuPlacement={"auto"}
    />
  );
}

const StyledSelect = styled(Select)`
  .react-select__placeholder {
    font-family: ${({customStyles}) => customStyles.placeholder.fontFamily};
    font-weight: ${({customStyles}) => customStyles.placeholder.fontWeight};
    text-transform: ${({customStyles}) => customStyles.placeholder.textTransform};
    color: ${({hasError, errorStyles, customStyles}) =>
      hasError ? errorStyles.color : customStyles.placeholder.color};

    ${media.up("lg")} {
      font-size: ${({customStyles}) => customStyles.placeholder.fontSize.lg};
    }
    ${media.between("md", "lg")} {
      font-size: ${({customStyles}) => customStyles.placeholder.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({customStyles}) => customStyles.placeholder.fontSize.sm};
    }
  }

  .react-select__control {
    border: ${({customStyles}) => customStyles.control.border};
    border-radius: ${({customStyles}) => customStyles.control.borderRadius};
    cursor: pointer;
    height: ${({customStyles}) => customStyles.control.height};
    border-color: ${({hasError, errorStyles}) => (hasError ? errorStyles.color : "")};
  }

  .react-select__control:hover {
    border: ${({customStyles}) => customStyles.control.hover.border};
  }

  .react-select__control--is-focused {
    box-shadow: none;
    outline: none;
    border: ${({customStyles}) => customStyles.control.focus.border};
  }

  .react-select__indicator-separator {
    background-color: ${({customStyles}) =>
      customStyles.indicatorSeparator.backgroundColor};
    width: ${({customStyles}) => customStyles.indicatorSeparator.width};
  }

  .react-select__input-container {
    font-family: ${({customStyles}) => customStyles.input.fontFamily};
    font-weight: ${({customStyles}) => customStyles.input.fontWeight};
    text-transform: ${({customStyles}) => customStyles.input.textTransform};
    color: ${({customStyles}) => customStyles.input.color};

    ${media.up("lg")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.lg};
    }
    ${media.between("md", "lg")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.sm};
    }
  }

  .react-select__single-value {
    font-family: ${({customStyles}) => customStyles.input.fontFamily};
    font-weight: ${({customStyles}) => customStyles.input.fontWeight};
    text-transform: ${({customStyles}) => customStyles.input.textTransform};
    color: ${({customStyles}) => customStyles.input.color};

    ${media.up("lg")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.lg};
    }
    ${media.between("md", "lg")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({customStyles}) => customStyles.input.fontSize.sm};
    }
  }

  .react-select__menu {
    font-family: ${({customStyles}) => customStyles.menu.fontFamily};
    font-weight: ${({customStyles}) => customStyles.menu.fontWeight};
    text-transform: ${({customStyles}) => customStyles.menu.textTransform};
    color: ${({customStyles}) => customStyles.menu.color};

    background-color: ${({customStyles}) => customStyles.menu.backgroundColor};
    box-shadow: ${({customStyles}) => customStyles.menu.boxShadow};
    border: ${({customStyles}) => customStyles.menu.border};
    border-radius: ${({customStyles}) => customStyles.menu.borderRadius};
  }

  .react-select__option {
    &:active {
      background-color: ${({customStyles}) =>
        customStyles.option.isSelected.backgroundColor};
    }
  }

  .react-select__option--is-focused {
    color: ${({customStyles}) => customStyles.option.isFocused.color};
    background-color: ${({customStyles}) =>
      customStyles.option.isFocused.backgroundColor};
  }

  .react-select__option--is-selected {
    color: ${({customStyles}) => customStyles.option.isSelected.color};
    background-color: ${({customStyles}) =>
      customStyles.option.isSelected.backgroundColor};
  }
`;

const defaultStyles = {
  placeholder: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "400",
    textTransform: "none",
    color: "#737373",
  },
  control: {
    height: "46px",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    hover: {
      border: "1px solid #D9D9D9",
    },
    focus: {
      border: "1px solid #D9D9D9",
    },
  },
  indicatorSeparator: {
    backgroundColor: "#D9D9D9",
    width: "1px",
  },
  input: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "400",
    textTransform: "none",
    color: "#333333",
  },
  menu: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "400",
    textTransform: "none",
    color: "#333333",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    boxShadow: "none",
  },
  option: {
    isFocused: {
      color: "#333333",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    isSelected: {
      color: "#333333",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
};

DropdownSelectInput.defaultProps = {
  styles: defaultStyles,
  errorStyles: {
    color: "#ff0000",
  },
};

DropdownSelectInput.propTypes = {
  placeholder: PropTypes.string,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errorStyles: PropTypes.shape({
    color: PropTypes.string,
  }),
  styles: PropTypes.shape({
    placeholder: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      color: PropTypes.string,
    }),
    control: PropTypes.shape({
      height: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      hover: PropTypes.shape({
        border: PropTypes.string,
      }),
      focus: PropTypes.shape({
        border: PropTypes.string,
      }),
    }),
    indicatorSeparator: PropTypes.shape({
      backgroundColor: PropTypes.string,
      width: PropTypes.string,
    }),
    input: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      color: PropTypes.string,
    }),
    menu: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      boxShadow: PropTypes.string,
    }),
    option: PropTypes.shape({
      isFocused: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
      }),
      isSelected: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
      }),
    }),
  }),
};
