import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";
import useGroupProducts from "@menu/hooks/useGroupProducts";
import useRouter from "src/core/common/hooks/useRouter";
import useSite from "src/core/sites/hooks/useSite";
import Loader from "src/core/common/components/elements/Loader";

export default function useShowcaseProductsList({slug, inView, url, filters}) {
  const {state, actions} = useGroupProducts({inView, slug, filters});
  const config = useThemeConfig();
  const router = useRouter();
  const site = useSite();
  const onLastSlide = () => {
    router.push(url);
  };

  return {
    site: site,
    loading: state.loading,
    skeleton: !state.prefetched && state.loading && state.data.length === 0,
    products: state.data,
    hasMore: state.hasMore,
    ProductCardComponent: config.components.ProductCard,
    LoadingComponent: <Loader />,
    onChangeNextSlide: () => actions.requestGroupProducts({filters}),
    onChangeIndicator: () => actions.requestGroupProducts({filters}),
    onLastSlide: onLastSlide,
  };
}
