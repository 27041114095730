import React from "react";
import RadioButton from "src/core/common/components/elements/radio";
import BankIcon from "src/core/payments/stronghold/bank-icons/LazyBankIcon";
import styled from "styled-components";
import calendar from "dayjs/plugin/calendar";
import dayjs from "dayjs";

dayjs.extend(calendar);

export default function PaymentSource({
  checked,
  readOnly,
  paymentDate,
  onClick,
  institution,
  institutionName,
  label,
  icon,
  isDefault,
  isExpired,
  dataCy,
}) {
  return (
    <PaymentSourceElement onClick={onClick} data-cy={dataCy} isExpired={isExpired}>
      {!readOnly && <RadioButton checked={checked} disabled={isExpired} />}
      {icon && <InstitutionLogoContainer>{icon}</InstitutionLogoContainer>}
      {institution && (
        <InstitutionLogoContainer>
          <BankIcon width={25} height={25} bank={institution} />
        </InstitutionLogoContainer>
      )}
      <PaymentSourceLabel className={"payment-source__label"} isExpired={isExpired}>
        <PaymentSourceProvider>
          {isDefault && (
            <MarkedAsDefault data-cy="markedAsDefault">Marked as default</MarkedAsDefault>
          )}
          <span>{institutionName}</span>
        </PaymentSourceProvider>
        <span data-cy={`${dataCy}-label`}>{label}</span>
        {readOnly && paymentDate && (
          <span>paid {paymentDate.calendar().toLowerCase()}</span>
        )}
      </PaymentSourceLabel>
    </PaymentSourceElement>
  );
}

const PaymentSourceElement = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${({isExpired}) => (isExpired ? 0.65 : 1)};
`;
const InstitutionLogoContainer = styled.div`
  margin-right: ${({theme}) => theme.v2.spacing(2)};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
`;
const PaymentSourceLabel = styled.div`
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > span:not(:first-child) {
    color: ${({theme, isExpired}) =>
      isExpired ? theme.v2.color.error : theme.v2.color.base.grey["700"]};
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  }
`;
const PaymentSourceProvider = styled.div`
  display: flex;
  flex-direction: column;
`;
const MarkedAsDefault = styled.span`
  color: #0b8535;
`;
