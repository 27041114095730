import React from "react";

const BoldCircleCheck = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9999 0.833374C16.2083 0.833374 0.833252 16.2084 0.833252 35C0.833252 53.7917 16.2083 69.1667 34.9999 69.1667C53.7916 69.1667 69.1666 53.7917 69.1666 35C69.1666 16.2084 53.7916 0.833374 34.9999 0.833374ZM28.1666 52.0834L11.0833 35L15.9008 30.1825L28.1666 42.4142L54.0991 16.4817L58.9166 21.3334L28.1666 52.0834Z"
        fill="#23BF90"
      />
    </svg>
  );
};

export default BoldCircleCheck;
