import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import Skeleton from "react-loading-skeleton";
import useLedgerGreen from "src/core/payments/ledgerGreen/useLedgerGreen";
import {Field, useForm} from "react-final-form";
import {Modal} from "@ui";
import MultiStepForm from "@ui/components/MultiStepForm/MultiStepForm";
import CardAndBillingStep from "src/core/payments/ledgerGreen/CardAndBillingStep";
import IdentificationStep from "src/core/payments/ledgerGreen/IdentificationStep";
import AgreementStep from "src/core/payments/ledgerGreen/AgreementStep";
import PaymentSourceList from "@ui/components/CheckoutPaymentMethod/PaymentSourceList";
import {
  getLedgerGreenFormProps,
  getLedgerGreenMultiStepFormProps,
} from "src/core/payments/ledgerGreen/styles";
import {makePayment} from "src/core/payments/factories/payment";
import CreditCardLabelComponent from "@ui/components/CheckoutPaymentMethod/CreditCardLabelComponent";
import {paymentSourceToCreditCard} from "src/core/payments/utils";
import {useShop} from "src/core/shops";
import * as Notifications from "src/core/notifications";

function LedgerGreenPaymentForm({cart, order}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {data: paymentSources, meta, refresh} = useLedgerGreen({cart, order});
  const [, shop] = useShop();
  const payment = makePayment("ledgergreen");
  const [customerToken, setCustomerToken] = useState(null);

  const form = useForm();
  useEffect(() => {
    if (!paymentSources) {
      form.mutators.setValue("payment.source", null);
    } else {
      form.mutators.setValue(
        "payment.source",
        paymentSources.find(pSource => pSource.isDefault)
      );
    }
  }, [paymentSources]);

  useEffect(() => {
    payment.getToken().then(token => setCustomerToken(token));
  }, []);

  const theme = useTheme();
  const site = useSite();
  const styles = getLedgerGreenFormProps(theme, site);

  const multiStepFormProps = getMultiStepForm({
    theme,
    site,
    payment,
    cart,
    shop,
    customerToken,
  });

  return (
    <Container styles={styles.container}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        ContainerComponent={ModalContent}
      >
        <MultiStepForm
          {...multiStepFormProps}
          onError={e => {
            if (e?.message) {
              Notifications.error(e.message);
            }
          }}
          onFinish={async data => {
            await payment.addAccount(data);
            refresh();
            setIsModalOpen(false);
          }}
        />
      </Modal>
      {meta.loading ? (
        <Skeleton height={50} count={3} />
      ) : (
        <Field
          name="payment.source"
          render={({input, meta}) => (
            <PaymentSourceList
              onNewPaymentSource={() => setIsModalOpen(true)}
              styles={styles}
              onChange={pSource => {
                !meta.loading && input.onChange(pSource);
              }}
              onRemove={pSource => {
                payment.removeAccount(pSource).then(refresh);
              }}
              loading={meta.loading}
              disabled={meta.loading}
              meta={meta}
              value={input.value}
              hasPaymentSources={true}
              paymentSources={paymentSources}
              LabelComponent={<LabelComponent input={input} />}
              emptyPlaceholderTitle={"No cards added yet"}
              emptyPlaceholderSubtitle={"Please add a new card below"}
              addNewPaymentSourceLabel={"Add new card"}
            />
          )}
        />
      )}
    </Container>
  );
}

function getMultiStepForm({theme, site, payment, cart, shop, customerToken}) {
  return {
    styles: getLedgerGreenMultiStepFormProps(theme, site),
    initialValues: {
      documentType: {value: "driver_license", label: "Driver's License"},
    },
    steps: [
      {
        label: "Card & Billing",
        form: <CardAndBillingStep />,
        onNext: async data => {
          const shopCountry = shop.getShop()?.getCountry();
          const {publicKey} = await payment.getConfiguration();
          return {
            cardToken: await payment.createCardToken(publicKey, data.payment),
            payment: {billingCountry: shopCountry?.toLowerCase()},
          };
        },
      },
      customerToken
        ? null
        : {
            label: "Identification",
            form: <IdentificationStep />,
            onNext: async data => {
              return payment.createCustomer({...data, cartId: cart.currentCart?.getId()});
            },
          },
      customerToken
        ? null
        : {
            label: "Agreement",
            form: <AgreementStep />,
          },
    ].filter(step => step),
  };
}

const LabelComponent = ({pSource, styles, input}) => {
  const card = paymentSourceToCreditCard(pSource, input);
  return <CreditCardLabelComponent card={card} styles={styles} />;
};

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;

const ModalContent = styled.div`
  width: 100%;
`;

export default LedgerGreenPaymentForm;
