import * as React from "react";
import PropTypes from "prop-types";

const CrossIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2126 2.0126L11.9876 0.787598L7.0001 5.7751L2.0126 0.787598L0.787598 2.0126L5.7751 7.0001L0.787598 11.9876L2.0126 13.2126L7.0001 8.2251L11.9876 13.2126L13.2126 11.9876L8.2251 7.0001"
        fill={color || "#737373"}
      />
    </svg>
  );
};

CrossIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default CrossIcon;
