import {makePayment} from "src/core/payments/factories/payment";
import React, {useEffect} from "react";
import {useForm} from "react-final-form";
import {formatPrice} from "src/core/common/price";
import SavingsIndicatorLabel from "src/core/payments/stronghold/SavingsIndicatorLabel";
import {PaymentOptions} from "src/core/payments/constants";

export default function useStrongholdPromotionSavings({cart}) {
  const form = useForm();
  const chargeAmount = cart?.getPrices().totalPrice.amount;
  useEffect(() => {
    if (!chargeAmount) return;

    const stronghold = makePayment(PaymentOptions.STRONGHOLD);
    stronghold.getPromotionSavings(chargeAmount).then(promotion => {
      if (promotion) {
        const formattedPrice = formatPrice(promotion.savings);
        form.mutators.setValue("savings", {
          label: <SavingsIndicatorLabel savingsDisplay={promotion.savingsDisplay} />,
          type: "savings",
          value: formattedPrice,
        });
      }
    });

    return () => {
      form.mutators.setValue("savings", null);
    };
  }, [chargeAmount]);
}
