import * as React from "react";
import PropTypes from "prop-types";

const RegionsBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <path
        d="M10.3834 7.89615L7.91626 11.2134L11.752 17.991L10.3834 7.89615Z"
        fill="#6EC72E"
      />
      <path
        d="M17.3745 11.4995L17.3199 11.427L14.3433 18H22.2104L17.3745 11.4995Z"
        fill="#6EC72E"
      />
      <path
        d="M12.5265 18L14.4513 7.56941L12.5384 5L10.5947 7.61212L12.5086 18H12.5265Z"
        fill="#6EC72E"
      />
      <path
        d="M7.70357 11.4995L2.86768 18H10.6494L7.76018 11.4221L7.70357 11.4995Z"
        fill="#6EC72E"
      />
      <path
        d="M13.4883 18L17.1264 11.1668L14.6712 7.86536L13.4446 18H13.4883Z"
        fill="#6EC72E"
      />
    </svg>
  );
};

RegionsBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RegionsBankIcon;
