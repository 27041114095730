import {makeThemeProps} from "src/themes/utils/utils";

export const getStrongholdFormProps = (theme, site, options = {}) => {
  const themeStyles = makeThemeProps(theme, site, options);
  return themeStyles.getStrongholdFormProps(theme, options);
};

export const getStrongholdPromotionBannerProps = (theme, site, options) => {
  const themeStyles = makeThemeProps(theme, site, options);
  return themeStyles.getStrongholdPromotionBannerProps(theme, options);
};
