class OfflinePayment {
  constructor(code) {
    this._code = code;
  }

  pay() {
    return Promise.resolve();
  }
  preparePayment(orderInfo, paymentData) {
    const result = {
      ...orderInfo,
      payment_specification: {
        tip: paymentData?.tip || undefined,
      },
    };
    return Promise.resolve(result);
  }

  async maybeHandle(info, e) {
    throw e;
  }

  isOnline() {
    return false;
  }

  get code() {
    return this._code;
  }

  async getPaymentDisclaimer() {
    return "";
  }

  subscribe() {}

  async addTip() {
    throw new Error("tip not supported for " + this.code);
  }

  getPaymentForm() {
    return null;
  }
}

export default OfflinePayment;
