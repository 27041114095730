import {useCallback, useEffect, useMemo, useState} from "react";
import {useForm, useFormState} from "react-final-form";
import get from "lodash/get";
import {useForm as useFormContext} from "src/core/common/components/FormContext";
import {makePayment} from "src/core/payments/factories/payment";
import usePaymentSources from "src/core/payments/hooks/usePaymentSources";
import {PaymentOptions} from "src/core/payments/constants";

export default function useStronghold({
  isMyAccount = false,
  markAsDefault = false,
  cart,
}) {
  const paymentOption = useMemo(() => makePayment(PaymentOptions.STRONGHOLD), []);

  const [loading, setLoading] = useState(false);

  const {paymentSources, meta, refresh} = usePaymentSources({
    sourceType: PaymentOptions.STRONGHOLD,
  });
  const pSourceMeta = meta;
  const hasAccounts = paymentSources && paymentSources.length > 0;
  const showPaymentSourcesList =
    !pSourceMeta.loading || (paymentSources && paymentSources.length > 0);

  const _loading = pSourceMeta.loading || loading;

  const form = useForm();
  const formState = useFormState();
  const {requestDisabled} = useFormContext();

  const startAsyncRequest = useCallback(() => {
    requestDisabled(true);
    setLoading(true);
  }, []);

  const finishAsyncRequest = useCallback(() => {
    setLoading(false);
    requestDisabled(false);
  }, []);

  useEffect(() => {
    if (!pSourceMeta.loading) finishAsyncRequest();
  }, [pSourceMeta.loading]);

  const disabled = cart.hasCart && cart.loading;

  const initialPaymentSource = useMemo(
    () =>
      Array.isArray(paymentSources) &&
      (paymentSources.find(pSource => pSource.isDefault) || paymentSources[0]),
    [paymentSources]
  );

  const pSources = paymentSources;

  async function linkAccount() {
    startAsyncRequest();
    try {
      await paymentOption.linkAccount();
    } catch (e) {
      finishAsyncRequest();
    }
  }

  async function onChangeAccount(paymentSource) {
    if (loading || !markAsDefault) return;
    startAsyncRequest();
    await paymentOption.markAsDefaultPaymentMethod(paymentSource);
  }

  async function removeAccount(paymentSource) {
    if (loading) return;
    startAsyncRequest();
    try {
      await paymentOption.removeAccount(paymentSource);
    } catch (e) {
      finishAsyncRequest();
    }
  }

  useEffect(() => {
    if (!initialPaymentSource) form.mutators.setValue("payment.source", null);

    const currentPaymentSourceId = get(formState, "values.payment.source.id");
    const inList =
      paymentSources &&
      paymentSources.find(pSource => pSource.id === currentPaymentSourceId);
    if (!inList && paymentSources && paymentSources.length > 0) {
      form.mutators.setValue("payment.source", initialPaymentSource);
    }
    // eslint-disable-next-line
  }, [paymentSources, initialPaymentSource]);

  useEffect(() => {
    return paymentOption.subscribe(({event, data}) => {
      const refreshEvents = [
        "payment-source-marked-as-default",
        "link-account-success",
        "removed-account-success",
      ];
      if (event === "link-account-success" && !isMyAccount) {
        form.mutators.setValue("payment.source", data);
        finishAsyncRequest();
      }
      if (refreshEvents.indexOf(event) > -1) refresh();
      if (event === "link-account-fail") {
        finishAsyncRequest();
      }
    });
  }, [paymentOption, form?.mutators, isMyAccount]);

  return {
    isMyAccount,
    hasAccounts,
    showPaymentSourcesList,
    loading: _loading,
    disabled: disabled,
    initialPaymentSource,
    pSources,
    linkAccount,
    onChangeAccount,
    removeAccount,
  };
}
