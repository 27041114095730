import Tracker from "./tracker";
import autoBind from "auto-bind";
import {window} from "browser-monads";
import {addBreadCrumb} from "../../analytics/errorReporting";
import Router from "next/router";

class MainTracker extends Tracker {
  trackers = [];
  constructor(trackers = []) {
    super();
    this.trackers = trackers;
    this.trackers.forEach(tracker => {
      tracker.init();
    });
    Router.events.on("routeChangeComplete", url => {
      this.pageView();
      return true;
    });
    this.pageView();
    autoBind(this);
  }

  doIdentify(payload) {
    this.trackers.forEach(tracker => {
      tracker.identify(payload);
    });
  }
  doTrack(eventName, payload) {
    addBreadCrumb({eventName, payload});
    this.trackers.forEach(tracker => {
      tracker.track(eventName, payload);
    });
  }
  doPageView() {
    const payload = {
      path: window.location.pathname,
      url: window.location.href,
      title: window.document.title,
      search: window.location.search,
      referrer: window.document.referrer,
    };
    this.trackers.forEach(tracker => {
      tracker.pageView(payload);
    });
  }
}

export default MainTracker;
