export const ErrorMessages = {
  FAILED_TOKEN_GENERATION:
    "There's a configuration problem with the Payment Processor. Please get in touch with the Store to solve it.",
};

export const cardTypes = {
  visa: "visa",
  mastercard: "mastercard",
  master: "mastercard",
  amex: "amex",
  discover: "discover",
  jcb: "jcb",
  maestro: "maestro",
  hiper: "",
  hipercard: "",
  "american-express": "amex",
  "diners-club": "diners",
  diners: "diners",
  unionpay: "",
  mir: "",
};

export const PaymentOptions = {
  STRONGHOLD: "stronghold",
  SPENCE: "spence",
  MERRCO: "merrco",
  MONERIS: "moneris",
  ADYEN: "adyen",
  LEDGERGREEN: "ledgergreen",
  SWIFTER: "swifter",
};
