import {useEffect, useState} from "react";
import {Actions, store} from "..";
import {orderStateSelector} from "../state/selectors";

function useOrderState() {
  const state = store.getState();
  const initialOrderState = orderStateSelector(state);

  const [orderState, setOrderState] = useState(initialOrderState);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const orderState = orderStateSelector(state);
      setOrderState(orderState);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return [orderState, Actions];
}

export default useOrderState;
