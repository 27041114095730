import React from "react";
import {Field} from "react-final-form";
import {required} from "src/core/common/validations";
import styled, {useTheme} from "styled-components";
import Error from "src/core/common/components/elements/Error";
import useSite from "src/core/sites/hooks/useSite";
import {getMerrcoFormProps} from "./styles";
import {CreditCardPanel} from "@ui/components";
import Skeleton from "react-loading-skeleton";
import useMoneris from "src/core/payments/moneris/useMoneris";
import MonerisNewCardForm from "src/core/payments/moneris/MonerisNewCardForm";
import {NullPaymentSource} from "src/core/payments/models/paymentSource";
import MonerisExternalAuthModal from "src/core/payments/moneris/MonerisExternalAuthModal";
import {paymentSourcesToCreditCards} from "src/core/payments/utils";

function MonerisPaymentForm({cart, order}) {
  const monerisData = useMoneris({cart, order});
  const {
    loading,
    disabled,
    hasCards,
    paymentSources,
    initialPaymentSource,
    removeAccount,
    externalAuthFormProps,
  } = monerisData;

  const {showForm} = externalAuthFormProps;

  const theme = useTheme();
  const site = useSite();

  const styles = getMerrcoFormProps(theme, site);

  return (
    <Container styles={styles.container}>
      {loading ? (
        <Skeleton height={50} count={3} />
      ) : (
        <>
          <Field
            //Since moneris works with an iframe,
            //There's no way to validate the fields
            //This is a workaround
            validate={hasCards ? required : null}
            initialValue={initialPaymentSource}
            name="payment.source"
            render={({input, meta}) => (
              <>
                <CreditCardPanel
                  NewCreditCardFormComponent={
                    <MonerisNewCardForm monerisData={monerisData} />
                  }
                  onAddNewCard={() => {
                    input.onChange(new NullPaymentSource());
                  }}
                  disabled={disabled}
                  creditCards={paymentSourcesToCreditCards({
                    paymentSources,
                    input,
                    disabled,
                    removeAccount,
                    dataCyRemove: "moneris-remove-card",
                  })}
                  styles={styles.creditCardPanel}
                />
                {meta.touched && <Error>{meta.error}</Error>}
              </>
            )}
          />
          {showForm && <MonerisExternalAuthModal {...externalAuthFormProps} />}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;

export default MonerisPaymentForm;
