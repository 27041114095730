import React from "react";
import {useTheme} from "styled-components";
import {CarouselProductsList} from "@ui";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import categoryCardStyles from "./categoryCardStyles";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";
import useShowcaseProductsList from "src/core/common/hooks/useShowcaseProductsList";

export default function ColourfulProductsList({
  categoryName,
  description,
  url,
  slug,
  ...props
}) {
  const site = useSite();
  const theme = useTheme();

  const showCategoryCard =
    site.getUiConfiguration().showcasedProductsList.showCategoryCard;
  let categoryCardProps = {
    styles: categoryCardStyles(theme, site),
    category: categoryName,
    LinkComponent: Link,
    url,
  };

  const baseProps = useShowcaseProductsList({
    inView: true,
    slug,
    url,
    filters: props.filters,
  });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  const showHeader = site.getUiConfiguration().showcasedProductsList.showHeader;

  const headerProps = {
    category: categoryName,
    url: url,
  };

  return (
    <CarouselProductsList
      {...props}
      {...baseProps}
      loading={false}
      styles={styles(theme, site)}
      maxProductsPerSlide={productsPerSlide}
      showCategoryCard={showCategoryCard}
      categoryCardProps={categoryCardProps}
      showIndicatorsDesktop={true}
      showHeader={showHeader}
      headerProps={headerProps}
      showCategoryHeader={true}
    />
  );
}
