import {useEffect, useMemo, useState} from "react";
import AdyenCheckout from "@adyen/adyen-web";
import {makePayment} from "src/core/payments/factories/payment";
import {PaymentOptions} from "src/core/payments/constants";

function useAdyenForm() {
  const [checkoutInstance, setCheckoutInstance] = useState(null);
  const [formState, setFormState] = useState({});

  const paymentOption = useMemo(() => makePayment(PaymentOptions.ADYEN), []);

  useEffect(() => {
    createCheckoutInstance(paymentOption, {
      onChange: setFormState,
    }).then(setCheckoutInstance);
  }, [paymentOption]);

  return {checkoutInstance, formState};
}

async function createCheckoutInstance(adyenInstance, listeners) {
  const config = await adyenInstance.getConfiguration();
  const checkout = await AdyenCheckout(config);

  const onFocusListeners = [];

  const instance = checkout
    .create("securedfields", {
      type: "card",
      styles: {
        base: {
          color: "black",
          fontSize: "13px",
          fontSmoothing: "antialiased",
          fontFamily: "Helvetica",
        },
        error: {
          color: "red",
        },
        validated: {
          color: "green",
        },
        placeholder: {
          color: "#737373",
        },
      },
      ariaLabels: {
        lang: "en-GB",
        encryptedCardNumber: {
          label: "Credit or debit card number field",
          iframeTitle: "Iframe for secured card number",
          error: "Message that gets read out when the field is in the error state",
        },
      },
      // Events
      onFocus: params => {
        onFocusListeners.forEach(fn => fn(params));
      },
      ...listeners,
    })
    .mount("#customCard-container");

  instance.addOnFocusListener = fn => {
    onFocusListeners.push(fn);
    return () => {
      const index = onFocusListeners.indexOf(fn);
      onFocusListeners.splice(index, 1);
    };
  };

  return instance;
}

export default useAdyenForm;
