import {Field} from "react-final-form";
import {TermsText} from "../../common/components/collections/form/forms";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import curry from "lodash/curry";
import CustomCheckbox from "../../common/components/elements/checkbox/CustomCheckbox";
import useAccountVerification from "src/core/authentication/hooks/useAccountVerification";
import * as Notifications from "src/core/notifications";
import {showErrorHighlight} from "src/core/common/utils";
import OutlinedPrimaryButton from "src/core/common/components/elements/button/OutlinedPrimaryButton";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import get from "lodash/get";

export function DisclaimerField({name, text, ...props}) {
  const [checked, setChecked] = useState(false);

  const onChange = curry((callback, evt) => {
    setChecked(!checked);
    callback(!checked);
  });

  return (
    <Field
      {...props}
      name={name}
      render={({input, meta}) => (
        <TermsText onClick={onChange(input.onChange)}>
          <CustomCheckbox
            {...input}
            color={"black"}
            type={"checkbox"}
            checked={checked}
            onChange={() => {}}
          />
          <Text onClick={onChange(input.onChange)} hasErrors={showErrorHighlight(meta)}>
            {text}
          </Text>
        </TermsText>
      )}
    />
  );
}

const Text = styled.div`
  cursor: pointer;
  color: ${({theme, hasErrors}) => (hasErrors ? "red" : theme.v2.color.base.grey["900"])};
  p {
    display: inline;
  }
`;

export function ResendCodeLink({phoneNumber, email}) {
  const toggles = useFeatureToggles();
  const [state, api] = useAccountVerification();
  const [requestedCode, setRequestedCode] = useState(false);

  function resendCode() {
    if (!requestedCode) {
      setRequestedCode(true);
      api.accountVerification({phoneNumber, email});
    }
  }

  const {data, error} = state;
  useEffect(() => {
    if (requestedCode && (data || error)) {
      if (!error) {
        const successMessage = get(
          data,
          "data.attributes.message",
          `6-digit code sent to your ${toggles.loginWithEmail() ? "email" : "phone"}.`
        );
        Notifications.success(successMessage);
      }
      setRequestedCode(false);
    }
  }, [error, data, requestedCode, toggles]);

  /* eslint-disable */
  return (
    <Container>
      <OutlinedPrimaryButton type="button" label="Resend code" onClick={resendCode} />
    </Container>
  );
  /* eslint-enable */
}

const Container = styled.div`
  a {
    font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
  text-align: center;

  margin: 0 auto ${({theme}) => theme.v2.spacing(4)} auto;
  max-width: ${({maxWidth}) => maxWidth || "100%"};
`;
