import Tracker from "../../core/analytics/trackers/tracker";

export default class SurfsideTracker extends Tracker {
  constructor(surfsideId, sourceId) {
    super();
    this.surfsideId = surfsideId;
    this.sourceId = sourceId;
  }

  init() {
    this.loadTracker();
    this.loadAds();

    window.surf("newTracker", "digital", "//col.surfside.io", {
      appId: "digital",
      cookieName: "surf",
      cookieDomain: "surfside.io",
    });

    window.surf("source:digital", {
      accountId: this.surfsideId,
      sourceId: this.sourceId,
    });
  }

  loadTracker() {
    /* eslint-disable */
    (function(s, u, r, f, i, n, g) {
      if (!s[i]) {
        s.surfContext = s.surfContext || [];
        s.surfContext.push(i);
        s[i] = function() {
          (s[i].q = s[i].q || []).push(arguments);
        };
        s[i].q = s[i].q || [];
        n = u.createElement(r);
        g = u.getElementsByTagName(r)[0];
        n.async = 1;
        n.src = f;
        g.parentNode.insertBefore(n, g);
      }
    })(window, document, "script", "//cdn.surfside.io/sdk/1.0.0/edge.js", "surf");
    /* eslint-enable */
  }

  loadAds() {
    /* eslint-disable */
    (function(d, script) {
      script = d.createElement("script");
      script.type = "text/javascript";
      script.onload = function() {
        window.surfRun && window.surfRun()
      };
      script.src = "//cdn.surfside.io/ads/1.0.0/r.js";
      d.getElementsByTagName("head")[0].appendChild(script);
    })(document);
    /* eslint-disable */
  }
}
