import React from "react";
import Error, {ErrorsList} from "../../common/components/elements/Error";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import get from "lodash/get";

function AuthErrors() {
  const [authState] = useAuthentication();
  const errors = get(authState.error, "data.errors", []);
  const hasGeneralErrors = Array.isArray(errors) && errors.length > 0;

  if (hasGeneralErrors) {
    return (
      <ErrorsList>
        {authState.error.errors.map((error, index) => (
          <li key={index}>
            <Error>{error.detail}</Error>
          </li>
        ))}
      </ErrorsList>
    );
  } else if (authState.error) {
    return (
      <ErrorsList>
        <li>
          <Error>Authentication Failed.</Error>
        </li>
      </ErrorsList>
    );
  } else {
    return null;
  }
}

export default AuthErrors;
