import React from "react";
import {CreditCardForm} from "@ui/components/CheckoutPaymentMethod/commonComponents";
import FormFields from "@ui/components/FormFields/FormFields";

function BillingAddressForm({
  styles,
  disabled,
  addressProps,
  cityProps,
  stateProps,
  postalCodeProps,
  buildingNumberProps,
  countryProps,
}) {
  const {validateAddress, addressPlaceholder} = addressProps;
  const {validateCity, cityPlaceholder} = cityProps;
  const {validateState, statePlaceholder} = stateProps;
  const {validatePostalCode, cardPostalCodePlaceholder} = postalCodeProps;
  const {validateBuildingNumber, buildingNumberPlaceholder} = buildingNumberProps;
  const {validateCountry, countryPlaceholder} = countryProps;

  const fieldProps = {
    fields: [
      {
        type: "text",
        name: "payment.buildingNumber",
        validate: validateBuildingNumber,
        placeholder: buildingNumberPlaceholder,
        styles: styles.input,
        width: {
          sm: 6,
          md: 4,
          lg: 3,
        },
      },
      {
        type: "text",
        name: "payment.billingAddress",
        validate: validateAddress,
        placeholder: addressPlaceholder,
        styles: styles.input,
        width: {
          sm: 12,
          md: 8,
          lg: 6,
        },
      },
      {
        type: "text",
        name: "payment.billingCity",
        validate: validateCity,
        placeholder: cityPlaceholder,
        styles: styles.input,
        width: {
          sm: 6,
          md: 6,
          lg: 3,
        },
      },
      {
        type: "select",
        name: "payment.billingCountry",
        styles: styles.select,
        validate: validateCountry,
        placeholder: countryPlaceholder,
        options: countryProps.options,
        initialValue: countryProps.initialValue,
        width: {
          sm: 6,
          md: 6,
          lg: 4,
        },
      },
      {
        type: "select",
        name: "payment.billingState",
        styles: styles.select,
        validate: validateState,
        placeholder: statePlaceholder,
        options: stateProps.options,
        width: {
          sm: 6,
          md: 6,
          lg: 4,
        },
      },
      {
        type: "text",
        name: "payment.cardPostalCode",
        styles: styles.input,
        validate: validatePostalCode,
        placeholder: cardPostalCodePlaceholder,
        width: {
          sm: 6,
          md: 6,
          lg: 4,
        },
      },
    ],
    styles: {
      fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
      },
      padding: {
        lg: "12px",
        md: "12px",
        sm: "12px",
      },
    },
  };

  return (
    <CreditCardForm styles={styles}>
      <FormFields {...fieldProps} disabled={disabled} styles={styles} />
    </CreditCardForm>
  );
}

export default BillingAddressForm;
