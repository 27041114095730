import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import RowLayout from "../../common/components/elements/container/row";
import ColumnLayout from "../../common/components/elements/container/column";
import PasswordInput from "../../common/components/elements/input/PasswordInput";
import {
  FieldContainer,
  FormContainer,
  FormText,
} from "../../common/components/collections/form/forms";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {PrimaryLoadingButton} from "../../common/components/elements/button/PrimaryButton";
import OutlinedPrimaryButton from "../../common/components/elements/button/OutlinedPrimaryButton";
import styled from "styled-components";
import {required} from "../../common/validations";
import Error from "../../common/components/elements/Error";
import AuthErrors from "./AuthErrors";
import IdentifierField, {
  handleIdentifier,
} from "../../common/components/elements/input/IdentifierField";
import routes from "../../common/routes";
import Link from "../../common/components/modules/Link";
import useQueryParams from "src/core/common/hooks/useQueryParams";
import useRouter from "../../common/hooks/useRouter";
import useSite from "../../sites/hooks/useSite";
import {showErrorHighlight} from "src/core/common/utils";
import Title from "src/core/common/components/modules/Title";

function LoginForm({
  onLoginSuccessful,
  switchToSignUp,
  initialValues,
  switchToForgotPassword,
}) {
  const [authState, authApi] = useAuthentication();
  const site = useSite();

  const sanitizeRegistrationAnnouncement = () => ({
    __html: site.getOptions().getRegistrationAnnouncement(),
  });

  function onSubmit({password, ...values}) {
    const params = {password, ...handleIdentifier(values)};
    authApi.login(params);
  }

  const [params] = useQueryParams(["redirect"]);

  const router = useRouter();

  const loggedIn = authApi.isLoggedIn();
  useEffect(() => {
    if (loggedIn) {
      if (params.redirect) {
        router.replace(params.redirect);
      } else {
        onLoginSuccessful();
      }
    }

    // eslint-disable-next-line
  }, [loggedIn, onLoginSuccessful, params.redirect]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={() => {}}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          {/* trick chrome into not auto completing */}
          <input style={{display: "none"}} />
          <FormContainer>
            <Title>Log In</Title>
            <AuthErrors />
            <ColumnLayout style={{paddingTop: "30px"}}>
              <RowLayout>
                <IdentifierField />
              </RowLayout>
            </ColumnLayout>
            <ColumnLayout>
              <RowLayout>
                <Field
                  name="password"
                  validate={required}
                  render={({input, meta}) => (
                    <FieldContainer data-cy="passwordField">
                      <PasswordInput
                        {...input}
                        hasErrors={showErrorHighlight(meta)}
                        placeholder={"Password"}
                      />
                      {meta.touched && <Error>{meta.error}</Error>}
                    </FieldContainer>
                  )}
                />
                <FormText alignment={"right"} onClick={switchToForgotPassword}>
                  <Link to={{pathname: routes.forgotPassword}}>Reset your password?</Link>
                </FormText>
              </RowLayout>
            </ColumnLayout>
            <PrimaryLoadingButton
              dataCy="loginButton"
              loading={authState.loading}
              type="submit"
              label="Log In"
            />
            <Footer alignment={"center"}>
              New member?
              <ButtonContainer>
                <OutlinedPrimaryButton label="Sign Up" onClick={switchToSignUp} />
              </ButtonContainer>
            </Footer>

            <Announcement>
              <div dangerouslySetInnerHTML={sanitizeRegistrationAnnouncement()} />
            </Announcement>
          </FormContainer>
        </form>
      )}
    />
  );
}

const ButtonContainer = styled.div`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;

const Footer = styled(FormText)`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;

const Announcement = styled(FormText)`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
  text-align: justify;
`;

export default LoginForm;
