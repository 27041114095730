const {getSiteInCluster} = require("../clusters");
const getEnv = (shop, domain) => {
  const siteInCluster = getSiteInCluster(domain);

  let env = undefined;
  if (Array.isArray(siteInCluster.sites)) {
    const site = siteInCluster.sites.find(site => {
      return site.siteObject.basePath === shop;
    });
    if (site) {
      const siteConfig = siteInCluster.template;
      const {envs} = siteConfig;
      const additionalEnv = envs[shop];
      env = {...site.siteObject.env, ...additionalEnv};
    }
  }

  if (!env) {
    const siteConfig = siteInCluster.template;
    const {envs} = siteConfig;
    env = envs[shop];
    if (!env) {
      env = envs[Object.keys(envs)[0]];
    }
  }
  return env || process.env;
};

module.exports = {
  getEnv,
};
