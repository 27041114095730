import {makeThemeProps} from "src/themes/utils/utils";

export const getMonerisFormProps = (theme, site, options = {}) => {
  const themeStyles = makeThemeProps(theme, site, options);
  return themeStyles.getMonerisFormProps(theme, options);
};

export const getMerrcoFormProps = (theme, site, options = {}) => {
  const themeStyles = makeThemeProps(theme, site, options);
  return themeStyles.getMerrcoFormProps(theme, options);
};
