import {fetchGroups} from "@menu/services/productGroupsService";
import ProductFiltersCatalog from "src/core/common/models/productFiltersCatalog";
import {getProducts} from "src/server/preloader/products";
import {ProductFilters} from "src/core/common/hooks/useAppliedFilters";

export async function getShowcasedGroups(filters, params) {
  const groups = await fetchGroups({
    catalog: new ProductFiltersCatalog(filters.data),
    filters: new ProductFilters(params),
  });

  const tagGroup = groups.find(g => g.type === "tag");
  const categoryGroup = groups.find(g => g.type === "category");

  return [tagGroup, categoryGroup].filter(g => !!g);
}

export async function getShowcasedGroupProducts(axiosInstance, showcasedGroups) {
  return await getProducts(
    axiosInstance,
    showcasedGroups,
    group => group.filters?.serialize(),
    {
      limit: 20,
      offset: 0,
    }
  );
}
