import {useMemo} from "react";
import get from "lodash/get";
import {FilterParams} from "@menu/utils/constants";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import useRange from "@menu/hooks/useRange";

export default function useBaseProductFilters(onChangeFilter) {
  const options = useSiteOptions();
  const [filters, setFilter, clearFilters] = useAppliedFilters(onChangeFilter);
  const [catalog, meta] = useFiltersCatalog({
    filters: filters.serialize(),
  });
  const [priceRange] = useRange("priceRange");
  const [thcRange] = useRange("thcRange");
  const [cbdRange] = useRange("cbdRange");
  const hasOnSaleProducts = get(catalog, "hasOnSaleProducts", false);

  /* eslint-disable */
  const pricesRange = useMemo(() => {
    return priceRange
      ? [priceRange.getMin(), priceRange.getMax()]
      : [
          filters[FilterParams.PRODUCT_MIN_PRICE] || 0,
          filters[FilterParams.PRODUCT_MAX_PRICE] || 0,
        ];
  }, [priceRange]);

  function onChangePrice([min, max]) {
    const maxValue = priceRange ? Math.min(priceRange.getMax(), max) : max;
    const minValue = priceRange ? Math.max(priceRange.getMin(), min) : min;
    setFilter(
      [FilterParams.PRODUCT_MIN_PRICE, FilterParams.PRODUCT_MAX_PRICE],
      [
        priceRange && minValue === priceRange.getMin() ? null : minValue,
        priceRange && maxValue === priceRange.getMax() ? null : maxValue,
      ]
    );
  }

  const potencyRange = (range, min, max) =>
    range
      ? [range.getMin() || 0, range.getMax() || 0]
      : [filters[min] || 0, filters[max] || 0];

  const potencyCbdRange = useMemo(
    () =>
      potencyRange(
        cbdRange,
        FilterParams.PRODUCT_MIN_CBD_POTENCY,
        FilterParams.PRODUCT_MAX_CBD_POTENCY
      ),
    [cbdRange]
  );

  const potencyThcRange = useMemo(
    () =>
      potencyRange(
        thcRange,
        FilterParams.PRODUCT_MIN_THC_POTENCY,
        FilterParams.PRODUCT_MAX_THC_POTENCY
      ),
    [thcRange]
  );

  function onChangeThc([min, max]) {
    const maxValue = thcRange ? Math.min(thcRange.getMax(), max) : max;
    const minValue = thcRange ? Math.max(thcRange.getMin(), min) : min;
    setFilter(
      [FilterParams.PRODUCT_MIN_THC_POTENCY, FilterParams.PRODUCT_MAX_THC_POTENCY],
      [
        thcRange && minValue === thcRange.getMin() ? null : minValue,
        thcRange && maxValue === thcRange.getMax() ? null : maxValue,
      ]
    );
  }

  function onChangeCbd([min, max]) {
    const maxValue = cbdRange ? Math.min(cbdRange.getMax(), max) : max;
    const minValue = cbdRange ? Math.max(cbdRange.getMin(), min) : min;
    setFilter(
      [FilterParams.PRODUCT_MIN_CBD_POTENCY, FilterParams.PRODUCT_MAX_CBD_POTENCY],
      [
        cbdRange && minValue === cbdRange.getMin() ? null : minValue,
        cbdRange && maxValue === cbdRange.getMax() ? null : maxValue,
      ]
    );
  }

  /* eslint-enable */
  const _types = get(catalog, "types", []);

  function onChangeType(newValue) {
    setFilter(FilterParams.PRODUCT_TYPE, newValue);
  }

  const _categories = get(catalog, "categories", []);
  const _subcategories = get(catalog, "subcategories", []);

  function onChangeSubcategory(newValue) {
    setFilter(FilterParams.PRODUCT_SUB_CATEGORY, newValue);
  }

  const _brands = get(catalog, "brands", []);

  function onChangeBrand(newValue) {
    setFilter(FilterParams.PRODUCT_BRANDS, newValue);
  }

  const _tags = get(catalog, "tags", []);

  function onChangeTag(newValue) {
    setFilter(FilterParams.PRODUCT_TAG, newValue);
  }

  const _weights = get(catalog, "weights", []);

  function onChangeWeight(newValue) {
    setFilter(FilterParams.PRODUCT_WEIGHT, newValue);
  }

  function onChangeOnSale(newValue) {
    setFilter(FilterParams.PRODUCT_ON_SALE, newValue);
  }

  const brands = useMemo(() => {
    const brandsCopy = [..._brands];
    if (options.getOrderBrandsAlphabeticallyInFilters()) {
      brandsCopy.sort((a, b) => a.getName().localeCompare(b.getName()));
    } else {
      brandsCopy.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    }
    return brandsCopy;
  }, [_brands, options]);

  const featuredTags = useMemo(() => {
    const tagsCopy = [..._tags];
    tagsCopy.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    return tagsCopy.filter(tag => tag.isFeatured());
  }, [_tags]);

  const nonFeaturedTags = useMemo(() => {
    const tagsCopy = [..._tags];
    tagsCopy.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    return tagsCopy.filter(tag => !tag.isFeatured());
  }, [_tags]);

  const weights = useMemo(() => {
    const weightsCopy = [..._weights];
    weightsCopy.sort((a, b) => (b.getAmount() < a.getAmount() ? 1 : -1));
    return weightsCopy;
  }, [_weights]);

  const types = useMemo(() => {
    const typesCopy = [..._types];
    typesCopy.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));
    return typesCopy;
  }, [_types]);

  const subcategories = useMemo(() => {
    const subcategoriesCopy = [..._subcategories];
    const categoriesCopy = [..._categories];

    const selectedCategorySlug = filters[FilterParams.CATEGORY];

    const selectedCategory = categoriesCopy.find(
      obj => obj.getSlug() === selectedCategorySlug
    );
    const subcategories = selectedCategory
      ? subcategoriesCopy.filter(
          obj => obj.getParentCategoryId() === selectedCategory.getId()
        )
      : [];

    subcategories.sort((a, b) => (b.getCount() > a.getCount() ? 1 : -1));

    return {
      name: selectedCategory?.getName(),
      values: subcategories,
    };
  }, [_subcategories, filters]);

  return {
    meta,
    filters,
    brands,
    types,
    subcategories,
    featuredTags,
    nonFeaturedTags,
    weights,
    hasWeightFilters: !!weights?.length,
    pricesRange,
    potencyThcRange,
    potencyCbdRange,
    hasOnSaleProducts,
    onChangeBrand,
    onChangeSubcategory,
    onChangeType,
    onChangePrice,
    onChangeThc,
    onChangeCbd,
    onChangeTag,
    onChangeWeight,
    onChangeOnSale,
    clearFilters,
  };
}
