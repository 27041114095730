import React from "react";
import FormFields from "@ui/components/FormFields/FormFields";
import styled, {useTheme} from "styled-components";
import {
  afterDate,
  beforeDate,
  composeValidators,
  required,
} from "src/core/common/validations";
import useAgeValidation from "src/core/authentication/hooks/useAgeValidation";
import {getStateOptions} from "src/core/payments/utils";
import {useShop} from "src/core/shops";
import useSite from "src/core/sites/hooks/useSite";
import {
  getLedgerGreenCreditCardFormProps,
  getLedgerGreenFormProps,
} from "src/core/payments/ledgerGreen/styles";

function IdentificationStep() {
  const [, ageValidation] = useAgeValidation();
  const [, shop] = useShop();
  const shopCountry = shop.getShop()?.getCountry();

  const theme = useTheme();
  const site = useSite();

  const styles = getLedgerGreenCreditCardFormProps(theme, site);
  const errorStyles = getLedgerGreenFormProps(theme, site).error;

  return (
    <FormContainer>
      <FormFields
        {...getProps({shopCountry, ageValidation, styles})}
        styles={{...styles, error: errorStyles}}
      />
    </FormContainer>
  );
}

const FormContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const getProps = ({ageValidation, shopCountry, styles}) => ({
  fields: [
    {
      type: "select",
      validate: required,
      name: "documentType",
      placeholder: "Document Type",
      styles: styles.select,
      options: [
        {value: "passport", label: "Passport"},
        {value: "driver_license", label: "Driver's License"},
      ],
      width: {
        sm: 12,
        md: 12,
        lg: 12,
      },
    },
    {
      type: "text",
      validate: required,
      name: "idNumber",
      placeholder: "ID Number",
      styles: styles.input,
      width: {
        sm: 12,
        md: 12,
        lg: 12,
      },
    },
    {
      type: "date",
      name: "idIssueDate",
      styles: styles.date,
      validate: composeValidators(required, beforeDate()),
      placeholder: "Issue date",
      width: {
        sm: 6,
        md: 6,
        lg: 6,
      },
    },
    {
      type: "date",
      name: "idExpirationDate",
      styles: styles.date,
      validate: composeValidators(required, afterDate()),
      placeholder: "Expiration date",
      width: {
        sm: 6,
        md: 6,
        lg: 6,
      },
    },
    {
      type: "date",
      name: "idDateOfBirth",
      styles: styles.date,
      validate: composeValidators(required, ageValidation),
      placeholder: "Date of Birth",
      width: {
        sm: 6,
        md: 6,
        lg: 6,
      },
    },
    {
      type: "select",
      name: "idState",
      placeholder: "State",
      validate: required,
      styles: styles.select,
      options: getStateOptions(shopCountry),
      width: {
        sm: 12,
        md: 6,
        lg: 6,
      },
    },
    {
      type: "select",
      validate: required,
      name: "gender",
      placeholder: "Gender",
      styles: styles.select,
      options: [
        {value: "m", label: "Male"},
        {value: "f", label: "Female"},
      ],
      width: {
        sm: 12,
        md: 6,
        lg: 6,
      },
    },
  ],
  styles: styles,
});

export default IdentificationStep;
