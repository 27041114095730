import * as React from "react";

const WheelSativa = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.26094 14.5391L12.5234 8.16406H10.2734V4.03906L6.89844 10.4141H9.26094V14.5391ZM9.71094 16.7891C8.67344 16.7891 7.69844 16.5922 6.78594 16.1984C5.87344 15.8047 5.07969 15.2703 4.40469 14.5953C3.72969 13.9203 3.19531 13.1266 2.80156 12.2141C2.40781 11.3016 2.21094 10.3266 2.21094 9.28906C2.21094 8.25156 2.40781 7.27656 2.80156 6.36406C3.19531 5.45156 3.72969 4.65781 4.40469 3.98281C5.07969 3.30781 5.87344 2.77344 6.78594 2.37969C7.69844 1.98594 8.67344 1.78906 9.71094 1.78906C10.7484 1.78906 11.7234 1.98594 12.6359 2.37969C13.5484 2.77344 14.3422 3.30781 15.0172 3.98281C15.6922 4.65781 16.2266 5.45156 16.6203 6.36406C17.0141 7.27656 17.2109 8.25156 17.2109 9.28906C17.2109 10.3266 17.0141 11.3016 16.6203 12.2141C16.2266 13.1266 15.6922 13.9203 15.0172 14.5953C14.3422 15.2703 13.5484 15.8047 12.6359 16.1984C11.7234 16.5922 10.7484 16.7891 9.71094 16.7891ZM9.71094 15.2891C11.3859 15.2891 12.8047 14.7078 13.9672 13.5453C15.1297 12.3828 15.7109 10.9641 15.7109 9.28906C15.7109 7.61406 15.1297 6.19531 13.9672 5.03281C12.8047 3.87031 11.3859 3.28906 9.71094 3.28906C8.03594 3.28906 6.61719 3.87031 5.45469 5.03281C4.29219 6.19531 3.71094 7.61406 3.71094 9.28906C3.71094 10.9641 4.29219 12.3828 5.45469 13.5453C6.61719 14.7078 8.03594 15.2891 9.71094 15.2891Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default WheelSativa;
