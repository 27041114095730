import {
  composeValidators,
  emailValidator,
  phoneNumberValidation,
  required,
} from "../../../validations";
import Input from "../../elements/input/Input";
import Error from "../../elements/Error";
import {Field} from "react-final-form";
import React from "react";
import useParsePhoneNumber from "src/core/common/hooks/useParsePhoneNumber";
import {showErrorHighlight} from "src/core/common/utils";

function EmailOrPhoneField({initialValue, onChange}) {
  const {loading, parsePhoneNumber} = useParsePhoneNumber();
  return (
    <Field
      name="emailOrPhone"
      validate={composeValidators(required, emailOrPhoneValidation(parsePhoneNumber))}
      initialValue={initialValue}
      render={({input, meta}) => (
        <>
          <Input
            {...input}
            data-cy="phoneNumberField"
            disabled={loading}
            hasErrors={showErrorHighlight(meta)}
            placeholder={"Email or Phone Number"}
            onChange={onChange(input.onChange)}
          />
          {meta.touched && <Error>{meta.error}</Error>}
        </>
      )}
    />
  );
}

const emailOrPhoneValidation = parsePhoneNumber => value => {
  if (!value || !parsePhoneNumber) return undefined;
  if (value.indexOf("@") > -1) {
    return emailValidator(value);
  } else {
    return phoneNumberValidation(parsePhoneNumber)(value);
  }
};
export default EmailOrPhoneField;
