import React from "react";
import CategoryBar from "@menu/components/CategoryBar";
import useRouter from "src/core/common/hooks/useRouter";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import {useBrandResource} from "src/state/hooks/useAsyncResource";
import BrandProductsBrowser from "src/themes/medleaf/components/BrandProductsBrowser";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import styled from "styled-components";
import AdBanner from "@themes/default/components/AdBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";

export default function MedLeafBrandPage({themeProperties = {}}) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const {data: brand} = useBrandResource({brandSlug});
  const {showAdBanner} = useAdBanner();

  return (
    <div>
      {brand && <Header isSearchEnabled={true} options={<OptionsList />} />}
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <Content>
        <CategoryBar />
        <BrandProductsBrowser showFilters showSort themeProperties={themeProperties} />
      </Content>
    </div>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
