import Input from "./Input";
import React from "react";
import {showErrorHighlight} from "src/core/common/utils";

export function PhoneConfirmationCodeField({input, meta}) {
  return (
    <Input
      {...input}
      type={"tel"}
      disableAutoComplete={true}
      autoComplete={"off"}
      hasErrors={showErrorHighlight(meta)}
      placeholder={"6-digit code"}
    />
  );
}
