import React from "react";
import styled from "styled-components";
import Logo from "../menu-elements/Logo";
import {Close} from "styled-icons/remix-line/Close";
import Separator from "src/core/common/components/elements/Separator";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import {items} from "../sideMenuItems";
import LazyRender from "src/core/common/components/utils/LazyRender";
import usePages from "src/core/shops/hooks/usePages";
import MenuItems from "./MenuItems";
import AccountSection from "./AccountSection";
import MenuItem from "src/core/common/components/collections/menu/side-menu/MenuItem";
import routes from "src/core/common/routes";
import useSite from "src/core/sites/hooks/useSite";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import {isMobile} from "src/core/common/utils";
import {useAboutItemsResource} from "src/state/hooks/useAsyncResource";

function Menu({logo, isOpen, close}) {
  const [, authApi] = useAuthentication();
  const isUserLoggedIn = authApi.isLoggedIn();
  const {data: aboutItems} = useAboutItemsResource();
  const {sidebarPages} = usePages();
  const site = useSite();
  const options = site.getOptions();

  const [, setDisplayCustomHome] = useCustomHome();

  return (
    <MenuContainer isOpen={isOpen}>
      <LazyRender>
        <Top>
          <CloseButton onClick={close} />
          <CustomLogo src={logo} alt="logo" />
          <MainMenuItems
            isUserLoggedIn={isUserLoggedIn}
            items={items(site)}
            close={close}
          >
            {/* TODO: refactor integrations */}
            {isMobile() && options.inStoreExperienceEnabled() && (
              <li>
                <MenuItem
                  onClick={() => {
                    close();
                    setDisplayCustomHome(true);
                  }}
                  item={{
                    linkTo: routes.home,
                    label: "In-Store Experience",
                    icon: "inStoreExperience",
                  }}
                />
              </li>
            )}
          </MainMenuItems>
          <CustomSeparator />
          {sidebarPages && (
            <MenuItems
              isUserLoggedIn={isUserLoggedIn}
              items={sidebarPages}
              close={close}
            />
          )}
          {aboutItems && (
            <MenuItems isUserLoggedIn={isUserLoggedIn} items={aboutItems} close={close} />
          )}
        </Top>
        <Bottom>
          <CustomSeparator style={{margin: 0}} />
          <AccountSection close={close} />
        </Bottom>
      </LazyRender>
    </MenuContainer>
  );
}

const Bottom = styled.div`
  min-height: 208px;
  box-sizing: border-box;
`;
const Top = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: ${({theme}) => theme.v2.spacing(4)};
`;
const MenuContainer = styled.div`
  width: 400px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    width: calc(100vw - ${({theme}) => theme.v2.spacing(16)});
  }
  background-color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  padding: ${({theme}) => `0 ${theme.v2.spacing(4)}`};
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "-100%")}, 0, 0);
  box-shadow: ${props => (props.isOpen ? "0 2px 18px 0 rgba(0, 0, 0, 0.3)" : "none")};
  transition: transform 300ms ease-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CloseButton = styled(Close)`
  position: absolute;
  right: ${({theme}) => theme.v2.spacing(4)};
  top: ${({theme}) => theme.v2.spacing(4)};
  width: 30px;
  height: 30px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  cursor: pointer;
`;
const CustomLogo = styled(Logo)`
  @media (min-width: 769px) {
    max-width: 340px;
  }
`;
const MainMenuItems = styled(MenuItems)`
  > li:first-child {
    margin-top: 0;
  }
`;

const CustomSeparator = styled(Separator)`
  margin: ${({theme}) => theme.v2.spacing(4)} 0;
`;

export default Menu;
