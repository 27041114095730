import * as React from "react";
import PropTypes from "prop-types";

const FidelityBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.9902" cy="12.9902" r="12.9902" fill="white" />
      <circle cx="12.9902" cy="12.9902" r="12.9902" fill="white" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <circle cx="12.9902" cy="12.9902" r="12.9902" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M11.0498 15.668H15.8977L17.3165 16.627H10.8362L8.25607 25.1152L7.9274 26C4.71187 24.6985 1.73737 21.6903 0.493884 18.311C-0.842585 14.8808 -0.830776 11.0406 0.526754 7.6194C1.79763 4.24011 4.85977 1.18618 8.11912 -0.0696331C10.6828 -1.17704 14.1119 -1.29691 16.829 -0.423545C20.0852 0.590013 22.8587 2.83854 24.6021 5.87838C26.5194 9.21771 27.0507 13.4532 25.8894 17.2607C25.1144 19.8847 23.6057 22.2081 21.5564 23.9336L21.2003 23.3571L17.9739 17.7173L22.9587 20.6114C22.9861 20.6114 23.0026 20.5829 22.9861 20.5543L16.4565 14.6805L24.9034 16.022C24.9308 16.022 24.9417 16.0105 24.9417 15.9934L24.887 15.9478L16.9276 13.0422L25.1992 10.9587C25.2266 10.9587 25.2266 10.9302 25.2156 10.9131L16.7304 11.4896L22.8054 6.7232L23.3751 6.24941C23.386 6.22087 23.3312 6.20375 23.3148 6.23229L17.0152 9.56591L15.8539 10.171L19.9733 2.60183C19.9733 2.58471 19.9733 2.55617 19.9459 2.57329L14.5666 9.15492L15.5965 0.506901C15.5965 0.489776 15.5691 0.472652 15.5417 0.489776L13.0602 8.89234L12.37 6.28937L10.8964 0.238613C10.88 0.221488 10.8526 0.210072 10.8416 0.238613L10.88 0.974979L11.4332 9.212L6.31689 2.27646C6.30593 2.26505 6.2895 2.27646 6.27854 2.28788L10.1459 9.96549L2.82747 5.80417C2.81652 5.80417 2.78913 5.82129 2.78913 5.83271L8.62309 10.8217L9.29139 11.3983L0.986896 10.4849C0.959506 10.4964 0.959506 10.5249 0.986896 10.542L9.03393 12.9966L1.03072 15.4397C1.02483 15.449 1.02169 15.4599 1.02169 15.4711C1.02169 15.4823 1.02483 15.4932 1.03072 15.5025L9.29139 14.4979L2.80556 20.2404C2.7946 20.2404 2.77817 20.2689 2.80556 20.286L2.8932 20.2575L8.09173 17.4205L9.30783 15.3998H10.6937L9.72962 14.6919L12.9451 9.32046L11.0498 15.668Z"
          fill="#699121"
        />
      </g>
    </svg>
  );
};

FidelityBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FidelityBankIcon;
