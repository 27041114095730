import {useEffect, useState} from "react";

let _parsePhoneNumber = null;
function useParsePhoneNumber() {
  const [state, setParsePhoneNumber] = useState({
    loading: !_parsePhoneNumber,
    parsePhoneNumber: _parsePhoneNumber,
  });
  useEffect(() => {
    if (state.parsePhoneNumber) return;

    let mounted = true;
    import("src/core/common/parsePhoneNumber").then(_module => {
      if (mounted) {
        _parsePhoneNumber = _module.default;
        setParsePhoneNumber({loading: false, parsePhoneNumber: _parsePhoneNumber});
      }
    });
    return () => {
      mounted = false;
    };
  }, [state.parsePhoneNumber, setParsePhoneNumber]);

  return state;
}

export default useParsePhoneNumber;
