import React from "react";
import {Field} from "react-final-form";
import Skeleton from "react-loading-skeleton";
import styled, {useTheme} from "styled-components";
import {required} from "src/core/common/validations";
import useStronghold from "src/core/payments/stronghold/useStronghold";
import useSite from "src/core/sites/hooks/useSite";
import {getStrongholdFormProps} from "./styles";
import BankIcon from "src/core/payments/stronghold/bank-icons";
import usePaymentDisclaimer from "src/core/checkout/hooks/payments/usePaymentDisclaimer";
import PaymentSourceList from "@ui/components/CheckoutPaymentMethod/PaymentSourceList";
import useStrongholdPromotionSavings from "src/core/payments/stronghold/useStrongholdPromotionSavings";

export default function StrongholdPaymentForm({cart, order}) {
  const {
    showPaymentSourcesList,
    loading,
    disabled,
    initialPaymentSource,
    pSources,
    linkAccount,
    onChangeAccount,
    removeAccount,
  } = useStronghold({cart, order});

  const theme = useTheme();
  const site = useSite();
  const {disclaimer} = usePaymentDisclaimer();

  const styles = getStrongholdFormProps(theme, site);
  useStrongholdPromotionSavings({cart: cart.currentCart});

  return (
    <OuterContainer>
      <Container styles={styles.container}>
        {!showPaymentSourcesList && <Skeleton height={50} count={3} />}
        <Field
          name="payment.source"
          initialValue={initialPaymentSource}
          validate={required}
          render={({input, meta}) => (
            <div className={meta.error ? "invalid" : ""}>
              {showPaymentSourcesList && (
                <PaymentSourceList
                  InstitutionIconComponent={CustomBankIcon}
                  onNewPaymentSource={linkAccount}
                  styles={styles}
                  onChange={pSource => {
                    onChangeAccount(pSource);
                    !disabled && input.onChange(pSource);
                  }}
                  onRemove={pSource => {
                    removeAccount(pSource);
                  }}
                  loading={loading}
                  disabled={disabled}
                  meta={meta}
                  value={input.value}
                  disclaimer={disclaimer}
                  paymentSources={pSources}
                  emptyPlaceholderTitle={"No accounts linked yet"}
                  emptyPlaceholderSubtitle={"Please link a new account below"}
                  addNewPaymentSourceLabel={"Link New Account"}
                  BannerComponent={null}
                />
              )}
            </div>
          )}
        />
      </Container>
    </OuterContainer>
  );
}

const OuterContainer = styled.div``;

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;
const CustomBankIcon = ({institution}) => (
  <BankIcon width={25} height={25} bank={institution} />
);
