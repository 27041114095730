import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import media from "src/themes/utils/media";
import Rating from "src/core/common/components/modules/Rating";
import ActionsSortFilter from "src/themes/medleaf/elements/filters/ActionsSortFilter";

function MedLeafBrandHeader({
  themeProperties = {},
  filters,
  filtersDisplayOptions,
  showSort,
  brand,
}) {
  function createMarkup(description) {
    return {__html: description};
  }

  if (!brand) {
    return (
      <HeaderContainer>
        <CentralHeader logo={false} description={false}>
          <Skeleton height={32} width={250} />
        </CentralHeader>
      </HeaderContainer>
    );
  } else {
    const logo = brand.getLogo();
    const description = brand.getDescription();
    const name = brand.getName();
    const rating = brand.getRating();
    return (
      <HeaderContainer>
        {logo && (
          <BrandContainer>
            {logo && <BrandLogo src={logo} alt={"brand logo"} />}
          </BrandContainer>
        )}
        <CentralHeader logo={logo} description={description}>
          <BrandName
            logo={logo}
            description={description}
            themeProperties={themeProperties}
          >
            {name}
          </BrandName>
          <RateReview style={{display: "none"}}>
            <Rating readonly={true} initialRating={rating} size={"20px"} />
          </RateReview>
          {!themeProperties.hideDescription && (
            <Description dangerouslySetInnerHTML={createMarkup(description)} />
          )}
        </CentralHeader>
        {themeProperties && themeProperties.sortFilterInHeader && (
          <FiltersContainer themeProperties={themeProperties}>
            {showSort && (
              <ActionsSortFilter
                filters={filters}
                filtersDisplayOptions={filtersDisplayOptions}
              />
            )}
          </FiltersContainer>
        )}
      </HeaderContainer>
    );
  }
}

const FiltersContainer = styled.div`
  display: flex;
  justify-content: ${({themeProperties}) => themeProperties.filtersSide || "flex-start"};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 ${({theme}) => `${theme.v2.spacing(8)}`};

  ${media.down("md")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`};
  }

  @media (max-width: 768px) {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(4) : 0)}
      ${({theme}) => theme.v2.spacing(4)};
    flex-direction: column;
    align-items: center;
  }
`;

const BrandContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
  height: 200px;
`;

const BrandLogo = styled.img`
  width: 200px;
  height: 100%;
  object-fit: contain;
`;

const CentralHeader = styled.div`
  flex-grow: 1;
  padding-left: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)};
  display: flex;
  flex-direction: column;
  justify-content: ${({description}) => (description ? "space-between" : "center")};

  @media (max-width: 768px) {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)} 0;
    padding-bottom: ${({description, theme}) => (description ? theme.v2.spacing(8) : 0)};
    flex-direction: column;
    width: 100%;
  }
`;

const BrandName = styled.h2`
  font-size: ${({theme}) => theme.v2.typography.sizing["4xl"].lg};
  font-weight: ${({theme, themeProperties}) =>
    themeProperties.brandTitleFontWeight
      ? themeProperties.brandTitleFontWeight
      : theme.v2.typography.bodyText1.weight.heavy};
  text-transform: capitalize;
  margin: ${({logo, description}) => (!logo && !description ? "0" : "")};

  @media (max-width: 768px) {
    padding-bottom: ${({theme}) => theme.v2.spacing(4)};
    font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
    font-weight: ${({theme, themeProperties}) =>
      themeProperties.brandTitleFontWeightMobile
        ? themeProperties.brandTitleFontWeightMobile
        : theme.v2.typography.bodyText1.weight.default};
  }
`;

const RateReview = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({theme}) => theme.v2.spacing(2)};

  @media (max-width: 768px) {
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: ${({theme}) => theme.v2.spacing(4)};
  }
`;

const Description = styled.div`
  text-align: justify;
  font-family: sofia-pro, sans-serif;
`;

export default MedLeafBrandHeader;
