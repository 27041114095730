import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import AdBanner from "@ui/components/AdBanner";
import useAdBanner from "@mock/hooks/useAdBanner";

export default function DefaultThemeAdBanner() {
  const {AdComponent} = useAdBanner();

  return (
    <Container>
      <AdBanner AdComponent={AdComponent} />
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 8px;

  ${media.down("md")} {
    padding-top: 16px;
  }
`;
