import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import {merge} from "lodash";
import Separator from "@ui/components/Separator/Separator";

function StepIndicator({styles, currentStep, steps, onClickStep}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <StepIndicatorContainer>
      {steps.map((step, index) => (
        <>
          <Step
            onClick={index <= currentStep ? () => onClickStep(index) : undefined}
            key={index}
            styles={_styles}
            label={step.label}
            active={currentStep >= index}
            displayLabel={currentStep === index}
            number={index + 1}
          />
          {index + 1 !== steps.length && <Separator />}
        </>
      ))}
    </StepIndicatorContainer>
  );
}

const StepIndicatorContainer = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: center;
`;

function Step({styles, onClick, label, number, active, displayLabel}) {
  return (
    <StepContainer onClick={onClick}>
      <StepNumber styles={styles.stepNumber} active={active}>
        {number}
      </StepNumber>
      <StepLabel styles={styles.stepLabel} active={active} displayLabel={displayLabel}>
        {label}
      </StepLabel>
    </StepContainer>
  );
}

const StepContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: ${({onClick}) => (onClick ? "pointer" : "default")};
`;
const StepNumber = styled.div`
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;

  border-radius: 50%;

  background-color: ${({styles, active}) =>
    active ? styles.backgroundColor : "#bdbdbd"};
  color: ${({styles, active}) => (active ? styles.color : "#ffffff")};

  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepLabel = styled.span`
  color: ${({active}) => (active ? "#272727" : "#9e9e9e")};
  font-family: ${({styles}) => styles.fontFamily};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    display: ${({displayLabel}) => (displayLabel ? "inline" : "none")};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
  white-space: nowrap;
`;

const defaultStyles = {
  stepNumber: {
    fontFamily: "sans-serif",
    backgroundColor: "#3dbba8",
    color: "#ffffff",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
  },
  stepLabel: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
  },
};
StepIndicator.propTypes = {
  styles: PropTypes.shape({
    stepNumber: PropTypes.shape({
      backgroundColor: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      fontFamily: PropTypes.string,
    }),
    stepLabel: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  currentStep: PropTypes.number,
  onClickStep: PropTypes.func,
};
export default StepIndicator;
