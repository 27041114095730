import {AnalyticsLogger} from "src/core/common/logger";
import SurfsideTracker from "src/integrations/surfside/surfside";
import NullTracker from "src/core/analytics/trackers/null";
import {events} from "src/core/analytics/ecommerce/events";
import {
  productDetailView,
  addProductToCartSuccessful,
  checkoutStarted,
  orderSuccessful,
  removeProductFromCartSuccessful,
  updateProductInCartSuccessful,
} from "src/integrations/surfside/handlers";

export default function makeSurfsideTracker(key, sourceId) {
  AnalyticsLogger.debug("makeSurfsideTracker: " + key + " ::: " + sourceId);

  const tracker =
    key && sourceId ? new SurfsideTracker(key, sourceId) : new NullTracker();

  if (key && sourceId) {
    tracker.addHandler(events.PRODUCT_DETAIL_VIEW, productDetailView);
    tracker.addHandler(events.ADD_ITEM_SUCCESSFUL, addProductToCartSuccessful);
    tracker.addHandler(events.REMOVE_ITEM_SUCCESSFUL, removeProductFromCartSuccessful);
    tracker.addHandler(events.UPDATE_ITEM_SUCCESSFUL, updateProductInCartSuccessful);
    tracker.addHandler(events.CHECKOUT_STARTED, checkoutStarted);
    tracker.addHandler(events.ORDER_SUCCESSFUL, orderSuccessful);
  }

  return tracker;
}
