import React from "react";

function FallbackCardIcon() {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0C1.79375 0 0 1.60156 0 3.57143V5.35714H36V3.57143C36 1.60156 34.2062 0 32 0H4ZM36 10.7143H0V21.4286C0 23.3984 1.79375 25 4 25H32C34.2062 25 36 23.3984 36 21.4286V10.7143ZM7 17.8571H11C11.55 17.8571 12 18.2589 12 18.75C12 19.2411 11.55 19.6429 11 19.6429H7C6.45 19.6429 6 19.2411 6 18.75C6 18.2589 6.45 17.8571 7 17.8571ZM14 18.75C14 18.2589 14.45 17.8571 15 17.8571H23C23.55 17.8571 24 18.2589 24 18.75C24 19.2411 23.55 19.6429 23 19.6429H15C14.45 19.6429 14 19.2411 14 18.75Z"
        fill="#9EA0A5"
      />
    </svg>
  );
}

export default FallbackCardIcon;
