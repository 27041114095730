import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";
import RowLayout from "../../common/components/elements/container/row";
import ColumnLayout from "../../common/components/elements/container/column";
import {FormContainer, FormHeader} from "../../common/components/collections/form/forms";
import useRecoverPassword from "src/core/authentication/hooks/useRecoverPassword";
import {PrimaryLoadingButton} from "../../common/components/elements/button/PrimaryButton";
import AuthErrors from "./AuthErrors";
import * as Notifications from "src/core/notifications";
import IdentifierField, {
  handleIdentifier,
} from "../../common/components/elements/input/IdentifierField";
import get from "lodash/get";

function ForgotPasswordForm({switchToLogin, switchToResetPassword}) {
  const [authState, authApi] = useRecoverPassword();
  const [submitted, setSubmitted] = useState(null);

  function onSubmit(values) {
    const params = handleIdentifier(values);
    authApi.recoverPassword(params);
    setSubmitted(params);
  }

  const {data, error} = authState;

  useEffect(() => {
    if (submitted && data) {
      setSubmitted(null);
      if (submitted.email) {
        Notifications.success("Password recovery link sent to your email.");
        switchToLogin();
      } else if (submitted.phoneNumber) {
        Notifications.success("6-digit code sent to your phone.");
        switchToResetPassword({
          phoneNumber: submitted.phoneNumber,
          email: submitted.email,
          requestEmail: get(data, "data.meta.request_email", false),
        });
      }
    }
  }, [error, data, submitted, switchToLogin, switchToResetPassword]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={() => {}}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <FormHeader style={{textTransform: "none"}}>Reset your password?</FormHeader>
            <AuthErrors />
            <ColumnLayout>
              <RowLayout>
                <IdentifierField />
              </RowLayout>
            </ColumnLayout>
            <PrimaryLoadingButton
              loading={authState.loading}
              label="Continue"
              type="submit"
            />
          </FormContainer>
        </form>
      )}
    />
  );
}

export default ForgotPasswordForm;
