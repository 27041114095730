import * as React from "react";
import PropTypes from "prop-types";

const TDBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#59B64B" />
      <rect x="5" y="6" width="16" height="12" fill="white" />
      <path
        d="M4.00004 21H22V5H4L4.00004 21ZM15.7513 17.7125H10.7777V9.48808H13.2767V16.2651H15.6782C17.336 16.2651 18.0308 15.2104 18.0308 12.4951C18.0308 9.75736 17.2385 8.96073 15.5563 8.96073H10.0829V17.7237H7.62047V8.94951H4V7.49088H16.3242C19.2986 7.49088 20.7127 8.90463 20.7127 12.4614C20.7127 17.1403 18.6038 17.7125 15.7513 17.7125Z"
        fill="#59B64B"
      />
    </svg>
  );
};

TDBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TDBankIcon;
