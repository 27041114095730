import React from "react";
import useMerrco from "src/core/payments/merrco/useMerrco";
import {Field} from "react-final-form";
import {required} from "src/core/common/validations";
import styled, {useTheme} from "styled-components";
import Error from "src/core/common/components/elements/Error";
import useSite from "src/core/sites/hooks/useSite";
import {getMerrcoFormProps} from "./styles";
import {CreditCardPanel} from "@ui/components";
import Skeleton from "react-loading-skeleton";
import {paymentSourcesToCreditCards} from "src/core/payments/utils";
import {getLedgerGreenFormProps} from "src/core/payments/ledgerGreen/styles";

function MerrcoPaymentForm({cart, order}) {
  const {
    loading,
    disabled,
    paymentSources,
    initialPaymentSource,
    removeAccount,
    cardInputProps,
    cardNameInputProps,
    cardExpirationDateProps,
    cardCvcProps,
    cardPostalCodeProps,
  } = useMerrco({cart, order});

  const theme = useTheme();
  const site = useSite();

  const styles = getMerrcoFormProps(theme, site);

  const validatePaymentSource = (value, allValues) => {
    if (allValues.payment?.cardNumber) return undefined;

    return required(value);
  };
  const errorStyles = getLedgerGreenFormProps(theme, site).error;

  return (
    <Container styles={styles.container}>
      {loading ? (
        <Skeleton height={50} count={3} />
      ) : (
        <Field
          validate={validatePaymentSource}
          initialValue={initialPaymentSource}
          name="payment.source"
          render={({input, meta}) => (
            <>
              <CreditCardPanel
                creditCardFormProps={{
                  cardInputProps,
                  cardNameInputProps,
                  cardExpirationDateProps,
                  cardCvcProps,
                  cardPostalCodeProps,
                }}
                disabled={disabled}
                creditCards={paymentSourcesToCreditCards({
                  paymentSources,
                  input,
                  disabled,
                  removeAccount,
                })}
                onAddNewCard={() => input.onChange(undefined)}
                styles={styles.creditCardPanel}
                errorStyles={errorStyles}
              />
              {meta.touched && <Error>{meta.error}</Error>}
            </>
          )}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;

export default MerrcoPaymentForm;
