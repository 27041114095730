import Adyen from "src/core/payments/adyen/Adyen";
import AdyenPaymentForm from "src/core/payments/adyen/AdyenPaymentForm";
import {PaymentOptions} from "src/core/payments/constants";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";

export default {
  type: PaymentOptions.ADYEN,
  PaymentClass: Adyen,
  PaymentForm: AdyenPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
