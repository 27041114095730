import React from "react";
import SenseBasePage from "./BasePage";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import AdBanner from "@themes/default/components/AdBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";

export default function SenseProductsPage(props) {
  const {showAdBanner} = useAdBanner();

  return (
    <SenseBasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <CategorizedProductsBrowser />
    </SenseBasePage>
  );
}
