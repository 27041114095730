import React, {useEffect, useState} from "react";
import * as StoresApi from "src/core/api/shops";
import MenuBannerPlacement from "src/integrations/surfside/adPlacements/MenuBannerPlacement";

export default function useAdBanner() {
  const [showAdBanner, setShowAdBanner] = useState(false);
  const [adIntegration, setAdIntegration] = useState(null);
  const [AdComponent, setAdComponent] = useState(null);

  useEffect(() => {
    StoresApi.siteIntegrations().then(response => {
      const surfside = response.data.find(
        service => service.attributes.service === "surfside"
      );
      setShowAdBanner(surfside?.attributes?.surfside_ad_banners_active);
      setAdIntegration(surfside);
    });
  }, []);

  useEffect(() => {
    if (adIntegration) {
      setAdComponent(
        <MenuBannerPlacement
          accountId={adIntegration.attributes.key_1}
          sourceId={adIntegration.attributes.key_2}
        />
      );
    }
  }, [adIntegration]);

  return {showAdBanner, AdComponent};
}
