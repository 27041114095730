import * as React from "react";
import PropTypes from "prop-types";

const USAABankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#1A3258" />
      <path
        d="M5.05127 16.539V19.4826C5.05127 21.127 6.04826 21.4001 6.88334 21.4001C8.11892 21.4001 8.69091 20.5774 8.69091 19.4662V16.5379H7.87394V19.5656C7.87394 19.948 7.74931 20.6615 6.88334 20.6473C6.09512 20.6331 5.95133 19.9043 5.95133 19.4356V16.539H5.05127ZM12.1069 16.7313C12.1069 16.7313 11.5849 16.4713 10.9917 16.4713C10.2535 16.4713 9.32468 16.8526 9.32468 17.8961C9.32468 18.6194 9.85407 18.9614 10.2141 19.1373C10.4058 19.2313 10.638 19.3362 10.8298 19.44C10.9288 19.4946 11.3219 19.6279 11.3219 20.0551C11.3219 20.514 10.8234 20.6692 10.5997 20.6692C9.80294 20.6692 9.25971 20.3042 9.25971 20.3042L9.34386 21.222C9.34386 21.222 9.6698 21.3892 10.6444 21.3914C11.5743 21.3881 12.2443 20.7861 12.2443 19.9579C12.2443 19.3209 11.8129 18.9428 11.4433 18.7625C11.1536 18.6205 10.9853 18.5418 10.7328 18.4216C10.4804 18.3014 10.2194 18.0949 10.2194 17.7748C10.2194 17.4317 10.5326 17.1848 10.9917 17.1848C11.6691 17.1848 11.9407 17.4929 12.1069 17.589V16.7313ZM18.7588 16.5314H17.7788L17.9344 16.9302L16.4112 21.0221L14.8401 16.5314H13.8601L14.0156 16.9302L12.3923 21.293H13.2785L13.5619 20.4626H15.2406L15.5228 21.293H17.1983L17.4827 20.4626H19.1604L19.4437 21.293H20.4258L18.7588 16.5314ZM13.7824 19.8169L14.4342 17.9529L15.0158 19.8169H13.7824ZM17.7022 19.8169L18.3519 17.9529L18.9345 19.8169H17.7022Z"
        fill="white"
      />
      <path
        d="M5.00049 11.1829L5.04309 12.671C5.04309 12.671 5.40525 12.4011 5.81107 12.4011C6.21796 12.4011 6.67598 12.6962 6.67598 12.6962L10.3114 14.8344L10.6192 13.4905L6.67598 11.1698C6.67598 11.1698 6.21796 10.878 5.81107 10.878C5.40312 10.878 5.00155 11.184 5.00155 11.184"
        fill="white"
      />
      <path
        d="M5.00049 9.23797L5.04309 10.7261C5.04309 10.7261 5.40525 10.4563 5.81107 10.4563C6.21796 10.4563 6.67598 10.7502 6.67598 10.7502L10.7033 13.1201L11.0101 11.7762L6.67491 9.22486C6.67491 9.22486 6.21903 8.93204 5.81107 8.93204C5.40312 8.93204 5.00155 9.23797 5.00155 9.23797"
        fill="white"
      />
      <path
        d="M5.00049 7.29309L5.04309 8.78125C5.04309 8.78125 5.40525 8.51028 5.81107 8.51028C6.21796 8.51028 6.67598 8.80529 6.67598 8.80529L11.0964 11.4057L11.4031 10.0618L6.67598 7.27998C6.67598 7.27998 6.21796 6.98825 5.81107 6.98825C5.40312 6.98825 5.00155 7.29309 5.00155 7.29309"
        fill="white"
      />
      <path
        d="M12.5769 4.92534L12.9816 6.6812L18.5183 9.93723C18.5183 9.93723 18.9529 10.2301 19.3811 10.2301C19.8093 10.2301 20.1917 9.92412 20.1917 9.92412L20.1491 8.43596C20.1491 8.43596 19.8029 8.70584 19.3811 8.70584C18.9582 8.70584 18.5183 8.41192 18.5183 8.41192L14.2353 5.89232L13.9392 4.76363C13.9392 4.71555 13.9818 4.6937 13.9978 4.69042L14.7221 4.46097C14.8201 4.46097 14.8765 4.54619 14.8765 4.61722L14.9149 4.69807C14.9415 4.71227 15.0502 4.65874 15.0576 4.65436V4.20857C15.0576 4.13475 15.0433 4.06165 15.0156 3.99354C14.9878 3.92543 14.9471 3.86366 14.8959 3.81181C14.8446 3.75997 14.7839 3.71908 14.7171 3.69153C14.6503 3.66398 14.5789 3.65032 14.5069 3.65133H13.7826C13.7826 3.65133 13.6761 3.40002 13.3683 3.40002H11.9921C11.6299 3.40002 11.5415 3.7453 11.5415 3.7453L10.5861 7.63725L6.67589 5.33617C6.67589 5.33617 6.25196 5.04116 5.81098 5.04116C5.37001 5.04116 5.00146 5.34709 5.00146 5.34709L5.04301 6.83635C5.04301 6.83635 5.36894 6.56647 5.80992 6.56647C6.25089 6.56647 6.67589 6.86148 6.67589 6.86148L11.4872 9.6903L12.5769 4.92534Z"
        fill="white"
      />
      <path
        d="M13.0901 7.16302L13.4948 8.92653L18.5181 11.881C18.5181 11.881 18.9676 12.176 19.3809 12.176C19.7942 12.176 20.1915 11.869 20.1915 11.869L20.1499 10.3808C20.1499 10.3808 19.8283 10.6507 19.3809 10.6507C18.9335 10.6507 18.5181 10.3579 18.5181 10.3579L13.0901 7.16302Z"
        fill="white"
      />
      <path
        d="M13.6047 9.41055L14.0095 11.1752L18.5183 13.8259C18.5183 13.8259 18.9678 14.1198 19.3811 14.1198C19.7944 14.1198 20.1917 13.8139 20.1917 13.8139L20.1491 12.3257C20.1491 12.3257 19.8295 12.5956 19.3811 12.5956C18.9337 12.5956 18.5183 12.3028 18.5183 12.3028L13.6047 9.41055Z"
        fill="white"
      />
      <path
        d="M14.1211 11.6603L14.5237 13.4216L18.5181 15.7708C18.5181 15.7708 18.9676 16.0658 19.3808 16.0658C19.7931 16.0658 20.1914 15.7588 20.1914 15.7588L20.1488 14.2717C20.1488 14.2717 19.8293 14.5405 19.3808 14.5405C18.9324 14.5405 18.5181 14.2466 18.5181 14.2466L14.1211 11.6603Z"
        fill="white"
      />
    </svg>
  );
};

USAABankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default USAABankIcon;
