import React from "react";
import useTheme from "@mock/hooks/useTheme.js";
import {License} from "@ui/components/Footer/License";
import footerStyles from "@themes/default/components/Footer/styles.js";
import useFooter from "@mock/hooks/useFooter.js";

function Footer() {
  const theme = useTheme();
  const {site, ...footerProps} = useFooter();

  return <License styles={footerStyles(theme, site)} {...footerProps} />;
}

export default Footer;
