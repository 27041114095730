import React, {useCallback, useEffect, useState} from "react";
import useAppliedFilters, {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import {PageHead} from "../../core/seo/components/Head";
import useSite from "../../core/sites/hooks/useSite";
import PaginationContext from "src/core/common/hooks/pagination/context";
import {useRefreshHook} from "src/core/common/hooks/useRefresh";
import useRouter from "src/core/common/hooks/useRouter";
import styled from "styled-components";
import useInfinitePagination from "src/core/common/hooks/pagination/useInfinitePagination";
import media, {contentMaxWidth} from "src/themes/utils/media";
import {trackCategoryView} from "src/core/analytics/ecommerce/events";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";
import {useBrandResource} from "src/state/hooks/useAsyncResource";
import {FilterParams} from "@menu/utils/constants";
import useSubcategories from "src/core/common/hooks/useSubcategories";

export default function BaseProductsBrowser({render}) {
  const [productsPage, setProductsPage] = useState(null);
  const [refreshState] = useRefreshHook();
  const router = useRouter();
  const {productId} = router.query;
  const [paramFilters] = useAppliedFilters();
  const [filters, setFilters] = useState(paramFilters);
  const [catalog] = useFiltersCatalog({
    // clearing subcategories, so we always request all subcategories for the current category
    filters: ProductFilters.fromPrototype(filters, {
      subcategory: null,
      [FilterParams.PRODUCT_SUB_CATEGORY]: null,
    }),
  });

  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  const categories = catalog ? catalog.categories : [];
  const category = categories
    ? categories.find(c => c.getSlug() === filters.category)
    : null;

  const {subcategories} = useSubcategories(category, categories);

  const brandSlug = router.query["brand"];
  const {data: brand} = useBrandResource({brandSlug});

  const limit = 20;
  const totalPages = productsPage ? productsPage.getTotalPages() : 1;

  const [manager, setPage] = useInfinitePagination({limit, totalPages});

  /* eslint-disable */
  useEffect(() => {
    setPage(1);
  }, [filters]);
  /* eslint-enable */

  const onListUpdate = useCallback(
    function (products) {
      setProductsPage(products);
    },
    [setProductsPage]
  );

  const site = useSite();

  const categoryTitleTemplate = site.getOptions().getCategoryTitleTemplate();
  const brandTitleTemplate = site.getOptions().getBrandTitleTemplate();

  useEffect(() => {
    category && trackCategoryView(category);
  }, [category]);

  return (
    <PaginationContext.Provider value={{manager, setPage}}>
      <React.Fragment key={refreshState}>
        {category ? (
          <PageHead
            title={category.getName()}
            description={category.getDescription()}
            titleTemplate={categoryTitleTemplate}
          />
        ) : brand ? (
          <PageHead
            title={brand.getName()}
            description={brand.getDescription()}
            image={brand.getLogo()}
            titleTemplate={brandTitleTemplate}
            schemas={<BrandDetailSchema brand={brand} />}
          />
        ) : (
          <PageHead />
        )}
        <Container>
          {render({
            category,
            subcategories,
            brand,
            filters,
            onListUpdate,
            manager,
          })}
        </Container>
      </React.Fragment>
    </PaginationContext.Provider>
  );
}

const Container = styled.div`
  padding-top: 20px;
  > section > header,
  > section > p {
    padding: 0 ${({theme}) => theme.v2.spacing(8)};
    ${media.down("md")} {
      padding: 0 ${({theme}) => theme.v2.spacing(4)};
    }
  }

  width: 100%;
  max-width: ${contentMaxWidth};
  box-sizing: border-box;
`;
