import React from "react";
import styled from "styled-components";
import {ProductListHeader, SimpleProductsList} from "@ui";
import useTheme from "@mock/hooks/useTheme.js";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import productsListStyles from "@themes/default/components/ShowcaseProductsList/ProductsList/styles";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";

export default function ProductsList({keepQuery = true, ...props}) {
  const {name, description, slug, url} = props;

  const theme = useTheme();
  const {ViewAllLink} = useThemeComponents();

  const {site, ...baseProps} = useShowcaseProductsList({
    inView: true,
    slug,
    url,
    filters: props.filters,
  });

  const styles = productsListStyles(theme, site);

  const productsPerSlide = styles.element.elementsPerRow || {
    lg: 6,
    md: 6,
    sm: 6,
  };

  return (
    <ProductListContainer>
      <SimpleProductsList
        {...props}
        {...baseProps}
        styles={styles}
        maxProducts={productsPerSlide}
        HeaderComponent={
          <ProductListHeader
            title={name}
            description={description}
            ViewAllComponent={
              <ViewAllLink
                accessibilityIdentifier={slug}
                to={url}
                keepQuery={keepQuery}
              />
            }
          />
        }
      />
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  min-height: 500px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
