import {window} from "browser-monads";
import {addBreadCrumb} from "src/core/analytics/errorReporting";
import {runSafely} from "src/core/common/utils";
import {formatPriceWithoutCurrency} from "src/core/common/price";
import {AnalyticsLogger} from "src/core/common/logger";

const w = window;

export function productDetailView(_, payload) {
  const product = makeProduct(payload);

  addBreadCrumb({eventName: "productDetailView()", payload});

  runSafely(() => {
    w.surf("addProduct:digital", {
      id: product.id,
      name: product.name,
      brand: product.brand,
      category: product.category,
      price: product.price,
      currency: payload.getUnitPrice().currency,
    });

    w.surf("setCommerceAction:digital", "detail");
  });
}

export function addProductToCartSuccessful(_, payload) {
  const {quantity, product, weightKey} = payload.item;
  pushAddToSurf(product, quantity, product.getFinalPriceByWeightKey(weightKey));
}

export function removeProductFromCartSuccessful(_, payload) {
  const product = payload.item.product.getProduct();
  const quantity = payload.item.product.getQuantity();
  pushRemoveToSurf(product, quantity);
}

export function updateProductInCartSuccessful(_, payload) {
  const previousItems = makeItems(payload.oldCart);
  const newItems = makeItems(payload.cart);
  const product = payload.item.getProduct();

  newItems.forEach(newItem => {
    if (product.hasWeightPrices()) {
      handleItemWithWeightPrices(previousItems, product, newItem);
    } else {
      handleItemWithUnitPrices(previousItems, product, newItem);
    }
  });
}

export function checkoutStarted(_, payload) {
  AnalyticsLogger.debug("surfside::startedCheckout");

  addBreadCrumb({eventName: "checkoutStarted()", payload});

  const {totalPrice, taxTotal} = payload.getPrices();
  const products = payload
    .getItems()
    .map(product =>
      makeProduct(product.getProduct(), product.getQuantity(), product.getWeightPrice())
    );

  runSafely(() => {
    w.surf("addTransaction:digital", {
      id: "",
      affiliation: "Online Store",
      revenue: formatPriceWithoutCurrency(totalPrice),
      tax: formatPriceWithoutCurrency(taxTotal),
      coupon: payload.getPromoCodes().join(","),
      currency: totalPrice.currency,
    });

    products.forEach(product => {
      w.surf("addProduct:digital", {
        id: product.id,
        name: product.name,
        brand: product.brand,
        category: product.category,
        price: product.price,
        quantity: product.quantity,
        currency: product.currency,
      });
    });

    w.surf("setCommerceAction:digital", "checkout");
  });

  return true;
}

export function orderSuccessful(_, payload) {
  AnalyticsLogger.debug("surfside::orderSuccessful");
  const {order} = payload;

  addBreadCrumb({eventName: "orderSuccessful()", payload});

  const {totalPrice, taxTotal} = payload.order.getPrices();
  const products = payload.order
    .getItems()
    .map(product =>
      makeProduct(product.getProduct(), product.getQuantity(), product.getWeightPrice())
    );

  runSafely(() => {
    w.surf("addTransaction:digital", {
      id: order.getOrderNumber(),
      affiliation: "Online Store",
      revenue: formatPriceWithoutCurrency(totalPrice),
      tax: formatPriceWithoutCurrency(taxTotal),
      coupon: order.getPromoCodes().join(","),
      currency: totalPrice.currency,
    });

    products.forEach(product => {
      w.surf("addProduct:digital", {
        id: product.id,
        name: product.name,
        brand: product.brand,
        category: product.category,
        price: product.price,
        quantity: product.quantity,
        currency: product.currency,
      });
    });

    w.surf("setCommerceAction:digital", "purchase");
  });

  return true;
}

const makeProduct = (product, quantity, weightPrice) => {
  const productCategory = product.getMainCategory();
  const price = product.hasWeightPrices() && weightPrice;

  return {
    name: product.getName(),
    id: product.getId().toString(),
    price: formatPriceWithoutCurrency(product.getFinalPrice(price, quantity)),
    brand: product.getBrandName(),
    category: productCategory ? productCategory.getName() : "",
    quantity: quantity,
    currency: product.getUnitPrice().currency,
  };
};

const makeItems = cart =>
  cart?.getItems().map(item => ({
    productId: item.getProductId(),
    quantity: item.getQuantity(),
    weightPrice: item.getWeightPrice(),
  })) || [];

function handleItemWithWeightPrices(previousItems, product, newItem) {
  const previousQuantity = previousItems.find(
    previousItem => previousItem.productId === newItem.productId
  ).quantity;
  if (previousQuantity) {
    pushRemoveToSurf(product, previousQuantity);
  }
  pushAddToSurf(product, newItem.quantity, newItem.weightPrice);
}

function handleItemWithUnitPrices(previousItems, product, newItem) {
  const previousQuantity = previousItems.find(
    previousItem => previousItem.productId === newItem.productId
  ).quantity;
  const quantityDiff = newItem.quantity - previousQuantity;
  if (quantityDiff > 0) {
    pushAddToSurf(product, Math.abs(quantityDiff));
  } else if (quantityDiff < 0) {
    pushRemoveToSurf(product, Math.abs(quantityDiff));
  }
}

const pushAddToSurf = (product, quantity, weightPrice) => {
  const surfsideProduct = makeProduct(product, quantity, weightPrice);
  pushToSurf("add", surfsideProduct);
};

const pushRemoveToSurf = (product, quantity, weightPrice) => {
  const surfsideProduct = makeProduct(product, quantity, weightPrice);
  pushToSurf("remove", surfsideProduct);
};

const pushToSurf = (event, product) => {
  addBreadCrumb({eventName: "pushToSurf()", product});

  runSafely(() => {
    w.surf("addProduct:digital", {
      id: product.id,
      name: product.name,
      brand: product.brand,
      category: product.category,
      price: product.price,
      quantity: product.quantity,
      currency: product.currency,
    });

    w.surf("setCommerceAction:digital", event);
  });
};
