import React from "react";
import styled from "styled-components";
import Checkbox from "../checkbox/Checkbox";

function RadioButton(props) {
  return <CustomCheckbox {...props} />;
}

const CustomCheckbox = styled(Checkbox)`
  border-radius: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  > div {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
`;

export default RadioButton;
