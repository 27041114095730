import * as React from "react";

const AppStoreIcon = () => {
  return (
    <svg
      width="186"
      height="54"
      viewBox="0 0 186 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M184.967 47.5365C184.967 50.4 182.599 52.7195 179.67 52.7195H6.33696C3.4096 52.7195 1.03345 50.4 1.03345 47.5365V6.20362C1.03345 3.34146 3.4096 1.01384 6.33696 1.01384H179.669C182.599 1.01384 184.965 3.34146 184.965 6.20362L184.967 47.5365Z"
        fill="white"
      />
      <path
        d="M179.111 1.07635C182.301 1.07635 184.896 3.60686 184.896 6.71667V47.0167C184.896 50.1265 182.301 52.657 179.111 52.657H6.88889C3.69933 52.657 1.10394 50.1265 1.10394 47.0167V6.71667C1.10394 3.60686 3.69933 1.07635 6.88889 1.07635H179.111ZM179.111 7.62939e-06H6.88889C3.10172 7.62939e-06 0 3.02419 0 6.71667V47.0167C0 50.7092 3.10172 53.7333 6.88889 53.7333H179.111C182.898 53.7333 186 50.7092 186 47.0167V6.71667C186 3.02419 182.898 7.62939e-06 179.111 7.62939e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M41.5098 26.5765C41.4698 22.2469 45.1458 20.1406 45.3138 20.0425C43.232 17.0832 40.0053 16.6788 38.8714 16.6466C36.1613 16.3685 33.5325 18.2277 32.1519 18.2277C30.7438 18.2277 28.6179 16.6735 26.3267 16.7191C23.3782 16.7635 20.6199 18.4278 19.1071 21.0124C15.9851 26.2823 18.3135 34.0266 21.3047 38.2863C22.801 40.3725 24.5494 42.7019 26.8378 42.6199C29.0767 42.5299 29.913 41.2282 32.6149 41.2282C35.2919 41.2282 36.0772 42.6199 38.4112 42.5675C40.814 42.5299 42.3268 40.4719 43.7707 38.3669C45.4998 35.9758 46.1942 33.6209 46.2218 33.5C46.1653 33.4812 41.5553 31.7658 41.5098 26.5765Z"
        fill="black"
      />
      <path
        d="M37.1009 13.8444C38.3051 12.3761 39.129 10.3786 38.9003 8.3515C37.1574 8.42673 34.9778 9.52692 33.7226 10.9629C32.6121 12.2284 31.6201 14.3025 31.8764 16.253C33.8342 16.3954 35.8444 15.2898 37.1009 13.8444Z"
        fill="black"
      />
      <path
        d="M67.58 13.4454C67.58 15.0265 67.0936 16.2167 66.1223 17.016C65.2226 17.7535 63.944 18.1229 62.2879 18.1229C61.4668 18.1229 60.7641 18.088 60.1758 18.0181V9.37915C60.9432 9.25825 61.7699 9.19646 62.6627 9.19646C64.2402 9.19646 65.4293 9.53095 66.2311 10.1999C67.1294 10.9562 67.58 12.0376 67.58 13.4454ZM66.0575 13.4844C66.0575 12.4594 65.7792 11.6736 65.2226 11.1255C64.666 10.5787 63.8531 10.3047 62.7825 10.3047C62.3279 10.3047 61.9407 10.3343 61.6197 10.3961V16.9636C61.7974 16.9905 62.1226 17.0026 62.5952 17.0026C63.7001 17.0026 64.553 16.703 65.1537 16.1039C65.7544 15.5048 66.0575 14.6316 66.0575 13.4844Z"
        fill="black"
      />
      <path
        d="M75.6525 14.8264C75.6525 15.8003 75.3673 16.5982 74.7969 17.2242C74.1989 17.8677 73.4067 18.1887 72.4174 18.1887C71.464 18.1887 70.7049 17.8811 70.1386 17.2632C69.5737 16.6466 69.2913 15.8688 69.2913 14.9311C69.2913 13.9505 69.582 13.1459 70.1661 12.5212C70.7503 11.8966 71.5357 11.5836 72.5249 11.5836C73.4783 11.5836 74.2444 11.8912 74.8244 12.5078C75.3755 13.1069 75.6525 13.8807 75.6525 14.8264ZM74.1548 14.872C74.1548 14.2877 74.0253 13.7866 73.7677 13.3688C73.4646 12.8638 73.0333 12.6112 72.4726 12.6112C71.8925 12.6112 71.4516 12.8638 71.1485 13.3688C70.8895 13.7866 70.7614 14.2957 70.7614 14.8976C70.7614 15.4819 70.8909 15.983 71.1485 16.4007C71.4613 16.9058 71.8966 17.1584 72.4588 17.1584C73.0099 17.1584 73.4425 16.9018 73.7539 16.3873C74.0212 15.9615 74.1548 15.4564 74.1548 14.872Z"
        fill="black"
      />
      <path
        d="M86.4764 11.7125L84.4442 18.045H83.1215L82.2797 15.2952C82.0661 14.6088 81.8925 13.9263 81.7575 13.2493H81.7313C81.6059 13.9451 81.4323 14.6262 81.2091 15.2952L80.315 18.045H78.9771L77.0662 11.7125H78.55L79.2844 14.7229C79.4621 15.4349 79.6082 16.1133 79.7253 16.7554H79.7514C79.8589 16.2261 80.0366 15.5518 80.2874 14.7364L81.2091 11.7139H82.3858L83.2689 14.6719C83.4825 15.3933 83.6561 16.0878 83.7897 16.7567H83.8297C83.9275 16.1052 84.0749 15.4107 84.2706 14.6719L85.0586 11.7139H86.4764V11.7125Z"
        fill="black"
      />
      <path
        d="M93.9618 18.045H92.5179V14.418C92.5179 13.3004 92.0825 12.7415 91.209 12.7415C90.7805 12.7415 90.4347 12.8947 90.166 13.2023C89.9001 13.5099 89.7651 13.8726 89.7651 14.2877V18.0437H88.3211V13.522C88.3211 12.9659 88.3032 12.3627 88.2688 11.7098H89.5377L89.6052 12.6999H89.6452C89.8133 12.3923 90.064 12.1384 90.3933 11.9355C90.7846 11.6991 91.2227 11.5795 91.7022 11.5795C92.3084 11.5795 92.8127 11.7703 93.2136 12.1531C93.7124 12.622 93.9618 13.3218 93.9618 14.2514V18.045V18.045Z"
        fill="black"
      />
      <path d="M97.9435 18.045H96.501V8.80688H97.9435V18.045Z" fill="black" />
      <path
        d="M106.444 14.8264C106.444 15.8003 106.159 16.5982 105.589 17.2242C104.991 17.8677 104.197 18.1887 103.209 18.1887C102.255 18.1887 101.495 17.8811 100.931 17.2632C100.366 16.6466 100.083 15.8688 100.083 14.9311C100.083 13.9505 100.374 13.1459 100.958 12.5212C101.542 11.8966 102.328 11.5836 103.316 11.5836C104.27 11.5836 105.035 11.8912 105.616 12.5078C106.168 13.1069 106.444 13.8807 106.444 14.8264ZM104.945 14.872C104.945 14.2877 104.816 13.7866 104.558 13.3688C104.257 12.8638 103.824 12.6112 103.265 12.6112C102.683 12.6112 102.242 12.8638 101.94 13.3688C101.681 13.7866 101.553 14.2957 101.553 14.8976C101.553 15.4819 101.683 15.983 101.94 16.4007C102.253 16.9058 102.689 17.1584 103.251 17.1584C103.802 17.1584 104.233 16.9018 104.544 16.3873C104.813 15.9615 104.945 15.4564 104.945 14.872Z"
        fill="black"
      />
      <path
        d="M113.433 18.045H112.136L112.029 17.3156H111.989C111.545 17.8972 110.913 18.1887 110.091 18.1887C109.478 18.1887 108.982 17.9966 108.609 17.6151C108.27 17.2685 108.101 16.8373 108.101 16.3255C108.101 15.5518 108.431 14.962 109.097 14.5537C109.761 14.1453 110.695 13.9451 111.898 13.9545V13.8363C111.898 13.0021 111.449 12.5857 110.549 12.5857C109.908 12.5857 109.343 12.7429 108.856 13.0545L108.562 12.1303C109.166 11.7662 109.911 11.5836 110.79 11.5836C112.487 11.5836 113.339 12.4567 113.339 14.2031V16.5351C113.339 17.1678 113.371 17.6715 113.433 18.045ZM111.934 15.8688V14.8922C110.341 14.8653 109.545 15.2912 109.545 16.1684C109.545 16.4988 109.635 16.746 109.821 16.9112C110.007 17.0764 110.244 17.1584 110.527 17.1584C110.844 17.1584 111.14 17.0603 111.41 16.8655C111.681 16.6694 111.848 16.4209 111.91 16.116C111.925 16.0475 111.934 15.9642 111.934 15.8688Z"
        fill="black"
      />
      <path
        d="M121.637 18.045H120.356L120.288 17.0281H120.248C119.839 17.8018 119.142 18.1887 118.162 18.1887C117.38 18.1887 116.728 17.8892 116.212 17.29C115.695 16.6909 115.437 15.9131 115.437 14.958C115.437 13.933 115.717 13.1029 116.279 12.4688C116.823 11.8777 117.49 11.5822 118.284 11.5822C119.156 11.5822 119.766 11.8683 120.113 12.4419H120.141V8.80688H121.586V16.339C121.586 16.9555 121.603 17.5238 121.637 18.045ZM120.141 15.3744V14.3186C120.141 14.1359 120.127 13.9881 120.101 13.8753C120.02 13.5368 119.845 13.252 119.579 13.0223C119.31 12.7926 118.986 12.677 118.613 12.677C118.074 12.677 117.653 12.8852 117.343 13.303C117.035 13.7208 116.88 14.2541 116.88 14.9056C116.88 15.5316 117.027 16.0394 117.323 16.4303C117.636 16.8467 118.058 17.055 118.585 17.055C119.059 17.055 119.438 16.8817 119.726 16.5337C120.005 16.2127 120.141 15.8258 120.141 15.3744Z"
        fill="black"
      />
      <path
        d="M133.986 14.8264C133.986 15.8003 133.701 16.5982 133.131 17.2242C132.533 17.8677 131.742 18.1887 130.751 18.1887C129.799 18.1887 129.04 17.8811 128.472 17.2632C127.907 16.6466 127.625 15.8688 127.625 14.9311C127.625 13.9505 127.916 13.1459 128.5 12.5212C129.084 11.8966 129.869 11.5836 130.86 11.5836C131.812 11.5836 132.579 11.8912 133.158 12.5078C133.709 13.1069 133.986 13.8807 133.986 14.8264ZM132.49 14.872C132.49 14.2877 132.36 13.7866 132.103 13.3688C131.798 12.8638 131.368 12.6112 130.806 12.6112C130.228 12.6112 129.787 12.8638 129.482 13.3688C129.223 13.7866 129.095 14.2957 129.095 14.8976C129.095 15.4819 129.225 15.983 129.482 16.4007C129.795 16.9058 130.23 17.1584 130.793 17.1584C131.344 17.1584 131.778 16.9018 132.089 16.3873C132.355 15.9615 132.49 15.4564 132.49 14.872Z"
        fill="black"
      />
      <path
        d="M141.75 18.045H140.307V14.418C140.307 13.3004 139.872 12.7415 138.997 12.7415C138.569 12.7415 138.223 12.8947 137.956 13.2023C137.688 13.5099 137.555 13.8726 137.555 14.2877V18.0437H136.109V13.522C136.109 12.9659 136.093 12.3627 136.058 11.7098H137.326L137.393 12.6999H137.433C137.603 12.3923 137.854 12.1384 138.182 11.9355C138.574 11.6991 139.011 11.5795 139.492 11.5795C140.097 11.5795 140.601 11.7703 141.002 12.1531C141.502 12.622 141.75 13.3218 141.75 14.2514V18.045V18.045Z"
        fill="black"
      />
      <path
        d="M151.468 12.767H149.878V15.8433C149.878 16.6251 150.16 17.016 150.719 17.016C150.978 17.016 151.193 16.9945 151.363 16.9502L151.4 18.0181C151.115 18.1229 150.74 18.1753 150.279 18.1753C149.708 18.1753 149.264 18.006 148.943 17.6675C148.621 17.329 148.461 16.7594 148.461 15.9601V12.767H147.512V11.7125H148.461V10.5519L149.876 10.1355V11.7112H151.466V12.767H151.468Z"
        fill="black"
      />
      <path
        d="M159.111 18.045H157.666V14.4449C157.666 13.3097 157.231 12.7415 156.359 12.7415C155.689 12.7415 155.232 13.0706 154.981 13.7289C154.938 13.8672 154.913 14.0365 154.913 14.2353V18.0436H153.471V8.80688H154.913V12.6233H154.941C155.395 11.9288 156.047 11.5822 156.892 11.5822C157.49 11.5822 157.984 11.773 158.377 12.1558C158.866 12.6327 159.111 13.342 159.111 14.2796V18.045V18.045Z"
        fill="black"
      />
      <path
        d="M166.996 14.5792C166.996 14.8317 166.977 15.044 166.942 15.2173H162.612C162.631 15.8433 162.838 16.3202 163.239 16.6506C163.605 16.9461 164.078 17.0939 164.657 17.0939C165.297 17.0939 165.882 16.9945 166.408 16.7944L166.634 17.7723C166.018 18.0329 165.293 18.1632 164.454 18.1632C163.448 18.1632 162.656 17.8744 162.083 17.2968C161.507 16.7191 161.222 15.944 161.222 14.9714C161.222 14.0163 161.488 13.2211 162.024 12.587C162.583 11.91 163.34 11.5715 164.294 11.5715C165.229 11.5715 165.938 11.91 166.418 12.587C166.805 13.1244 166.996 13.7893 166.996 14.5792ZM165.618 14.2152C165.629 13.7974 165.534 13.4374 165.339 13.1338C165.088 12.7429 164.706 12.5467 164.19 12.5467C163.718 12.5467 163.334 12.7375 163.041 13.1203C162.801 13.4253 162.659 13.7893 162.612 14.2152H165.618Z"
        fill="black"
      />
      <path
        d="M73.911 42.3203H70.782L69.0681 37.0692H63.1106L61.4779 42.3203H58.4316L64.334 24.4433H67.9796L73.911 42.3203ZM68.5514 34.8662L67.0014 30.1981C66.8375 29.7212 66.5302 28.5982 66.0769 26.8304H66.0218C65.8413 27.5907 65.5506 28.7137 65.1511 30.1981L63.6286 34.8662H68.5514V34.8662Z"
        fill="black"
      />
      <path
        d="M89.0897 35.7166C89.0897 37.9089 88.4821 39.6418 87.2669 40.9139C86.1785 42.0463 84.8269 42.6119 83.2135 42.6119C81.472 42.6119 80.221 42.002 79.459 40.7823H79.4039V47.5728H76.4665V33.6733C76.4665 32.2951 76.4293 30.8806 76.3577 29.4298H78.941L79.105 31.473H79.1601C80.1397 29.9335 81.6263 29.1651 83.6213 29.1651C85.181 29.1651 86.483 29.7656 87.5245 30.9679C88.5689 32.1715 89.0897 33.7539 89.0897 35.7166ZM86.0972 35.8213C86.0972 34.5667 85.8078 33.5323 85.2264 32.7182C84.5913 31.8692 83.7384 31.4448 82.6693 31.4448C81.9445 31.4448 81.286 31.6812 80.6977 32.1473C80.108 32.6175 79.7222 33.2314 79.5417 33.9917C79.4508 34.3464 79.4053 34.6365 79.4053 34.8649V37.0142C79.4053 37.9519 79.7002 38.7431 80.2898 39.3892C80.8795 40.0354 81.6456 40.3578 82.588 40.3578C83.6943 40.3578 84.5554 39.9413 85.1713 39.1112C85.7886 38.2796 86.0972 37.1835 86.0972 35.8213Z"
        fill="black"
      />
      <path
        d="M104.296 35.7166C104.296 37.9089 103.689 39.6418 102.472 40.9139C101.385 42.0463 100.033 42.6119 98.4201 42.6119C96.6786 42.6119 95.4276 42.002 94.6671 40.7823H94.6119V47.5728H91.6745V33.6733C91.6745 32.2951 91.6373 30.8806 91.5657 29.4298H94.149L94.313 31.473H94.3681C95.3463 29.9335 96.8329 29.1651 98.8293 29.1651C100.388 29.1651 101.69 29.7656 102.734 30.9679C103.774 32.1715 104.296 33.7539 104.296 35.7166ZM101.304 35.8213C101.304 34.5667 101.013 33.5323 100.432 32.7182C99.7965 31.8692 98.9464 31.4448 97.8759 31.4448C97.1498 31.4448 96.4926 31.6812 95.9029 32.1473C95.3132 32.6175 94.9288 33.2314 94.7483 33.9917C94.6588 34.3464 94.6119 34.6365 94.6119 34.8649V37.0142C94.6119 37.9519 94.9068 38.7431 95.4937 39.3892C96.0834 40.034 96.8495 40.3578 97.7946 40.3578C98.901 40.3578 99.7621 39.9413 100.378 39.1112C100.995 38.2796 101.304 37.1835 101.304 35.8213Z"
        fill="black"
      />
      <path
        d="M121.298 37.307C121.298 38.8277 120.757 40.0649 119.67 41.02C118.475 42.0638 116.812 42.585 114.675 42.585C112.702 42.585 111.12 42.2142 109.923 41.4714L110.604 39.0843C111.893 39.8446 113.308 40.2261 114.85 40.2261C115.956 40.2261 116.817 39.9816 117.436 39.4953C118.052 39.009 118.359 38.3562 118.359 37.5421C118.359 36.8167 118.106 36.2055 117.597 35.7098C117.092 35.2141 116.247 34.7534 115.068 34.3275C111.857 33.1602 110.254 31.4501 110.254 29.2014C110.254 27.7318 110.816 26.5268 111.942 25.5891C113.063 24.6502 114.559 24.1813 116.43 24.1813C118.099 24.1813 119.485 24.4648 120.591 25.0303L119.857 27.365C118.824 26.817 117.655 26.5429 116.348 26.5429C115.314 26.5429 114.507 26.7914 113.928 27.2858C113.439 27.7277 113.194 28.2664 113.194 28.9045C113.194 29.6111 113.474 30.1954 114.036 30.6549C114.525 31.0794 115.414 31.5388 116.703 32.0345C118.281 32.6537 119.439 33.3778 120.185 34.208C120.927 35.0355 121.298 36.0712 121.298 37.307Z"
        fill="black"
      />
      <path
        d="M131.01 31.5791H127.772V37.8377C127.772 39.4295 128.343 40.2248 129.486 40.2248C130.011 40.2248 130.446 40.1804 130.791 40.0918L130.872 42.2666C130.294 42.4775 129.532 42.5837 128.588 42.5837C127.428 42.5837 126.521 42.2384 125.867 41.5493C125.215 40.8588 124.887 39.7009 124.887 38.0741V31.5764H122.958V29.427H124.887V27.0668L127.772 26.2178V29.427H131.01V31.5791Z"
        fill="black"
      />
      <path
        d="M145.619 35.7689C145.619 37.7503 145.037 39.3771 143.877 40.6493C142.661 41.959 141.046 42.6119 139.033 42.6119C137.093 42.6119 135.548 41.9845 134.397 40.7299C133.245 39.4752 132.669 37.8914 132.669 35.9825C132.669 33.985 133.261 32.3488 134.45 31.0767C135.637 29.8032 137.238 29.1664 139.251 29.1664C141.19 29.1664 142.752 29.7938 143.93 31.0498C145.057 32.2682 145.619 33.8412 145.619 35.7689ZM142.571 35.8616C142.571 34.6728 142.311 33.6532 141.783 32.8028C141.167 31.7739 140.287 31.2607 139.146 31.2607C137.965 31.2607 137.068 31.7752 136.452 32.8028C135.925 33.6545 135.664 34.6902 135.664 35.9153C135.664 37.1042 135.925 38.1238 136.452 38.9728C137.087 40.0018 137.975 40.5149 139.12 40.5149C140.241 40.5149 141.122 39.991 141.757 38.9459C142.298 38.0795 142.571 37.0491 142.571 35.8616Z"
        fill="black"
      />
      <path
        d="M155.167 31.9485C154.876 31.8961 154.566 31.8692 154.241 31.8692C153.207 31.8692 152.408 32.2494 151.846 33.0111C151.357 33.6827 151.112 34.5317 151.112 35.5567V42.3204H148.176L148.203 33.4893C148.203 32.0036 148.166 30.6508 148.093 29.4311H150.652L150.759 31.8975H150.84C151.15 31.0498 151.639 30.3674 152.309 29.8556C152.963 29.3948 153.67 29.1651 154.432 29.1651C154.704 29.1651 154.949 29.1839 155.167 29.2175V31.9485Z"
        fill="black"
      />
      <path
        d="M168.304 35.2652C168.304 35.7783 168.269 36.2109 168.196 36.5642H159.384C159.419 37.8377 159.844 38.8116 160.663 39.4832C161.405 40.0837 162.366 40.3846 163.545 40.3846C164.85 40.3846 166.04 40.1818 167.111 39.7747L167.571 41.7629C166.32 42.2948 164.843 42.5595 163.139 42.5595C161.088 42.5595 159.479 41.9711 158.308 40.7957C157.14 39.6203 156.554 38.0418 156.554 36.0618C156.554 34.118 157.098 32.4992 158.188 31.2083C159.329 29.83 160.871 29.1409 162.811 29.1409C164.716 29.1409 166.159 29.83 167.138 31.2083C167.914 32.3031 168.304 33.6572 168.304 35.2652ZM165.503 34.5223C165.522 33.6733 165.331 32.9399 164.932 32.3206C164.424 31.524 163.643 31.1264 162.592 31.1264C161.631 31.1264 160.85 31.5146 160.254 32.2937C159.764 32.913 159.474 33.6559 159.384 34.521H165.503V34.5223Z"
        fill="black"
      />
    </svg>
  );
};

export default AppStoreIcon;
