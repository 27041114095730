import {Modal, ModalExternalContainer} from "@ui";
import styled from "styled-components";
import React from "react";
import {Smartphone} from "@styled-icons/material-rounded/Smartphone";
import useSite from "src/core/sites/hooks/useSite";
import {isMobile} from "src/core/common/utils";

export default function ExclusiveMobileContentAddon() {
  const site = useSite();
  const options = site.getOptions();
  const showModal = options.hasInStoreExperienceEventFlow() && !isMobile();

  if (!showModal) return null;

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {}}
      ContainerComponent={VerticalContainer}
      showCloseButton={false}
    >
      <ContentContainer>
        <Smartphone />
        <h1>Mobile-Exclusive Content</h1>
        <span>
          This content is only available via mobile devices. Please switch to your phone
          to enjoy it. Thanks for your understanding!
        </span>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  svg {
    width: 80px;
    color: #ff8c00;
  }

  span {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }
`;

const VerticalContainer = styled(ModalExternalContainer)`
  align-items: center;
`;
