import get from "lodash/get";
import * as LegacyTheme from "src/themes/legacy";
import * as DefaultTheme from "@themes/default";
import * as BaseTheme from "src/themes/baseTheme";
import * as BoostTheme from "src/themes/boost";
import * as ColorfulTheme from "src/themes/colorful";
import * as SenseTheme from "src/themes/sense";
import * as FlightTheme from "src/themes/flight";
import * as MedleafTheme from "src/themes/medleaf";
import ThemeRegistry from "src/themes/registry";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";
import BaseThemeProps from "src/themes/baseTheme/baseThemeProps";
import BoostThemeProps from "@themes/boost/themeStyles/boostThemeProps";
import ColorfulThemeProps from "src/themes/colorful/colorfulThemeProps";
import SenseThemeProps from "src/themes/sense/senseThemeProps";
import FlightThemeProps from "src/themes/flight/flightThemeProps";
import MedLeafThemeProps from "src/themes/medleaf/medLeafProps";
import CheckoutThemePropsMixin from "src/themes/baseTheme/checkoutThemeProps";
import inStoreThemeExperienceThemePropsMixin from "src/themes/baseTheme/inStoreExperienceThemeProps";

function initAllThemes() {
  LegacyTheme.init();
  DefaultTheme.init(ThemeRegistry);
  BaseTheme.init();
  MedleafTheme.init();
  BoostTheme.init(ThemeRegistry);
  ColorfulTheme.init();
  SenseTheme.init();
  FlightTheme.init();
}

export function getThemeConfig(themeName) {
  initAllThemes();
  return ThemeRegistry.getThemeConfig(themeName);
}

export function getThemeProperty(theme, propertyName, overrideName, defaultValue) {
  const override = overrideName ? get(theme, overrideName) : null;
  const property = propertyName ? get(theme, propertyName) : null;
  if (override) {
    return override;
  } else if (property) {
    return property;
  } else {
    return defaultValue;
  }
}

export function addAlpha(color, opacity = 0.75) {
  const tempColor = color.trim().toLowerCase();
  if (
    tempColor.startsWith("rgba") ||
    (tempColor.startsWith("#") && (tempColor.length === 9 || tempColor.length === 5))
  ) {
    return color;
  }

  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function makeThemeProps(theme, site, options = {}) {
  const themeName = options?.overrideTheme || site.getBaseTheme().name;
  if (theme.v3) {
    return new BaseThemeProps();
  } else if (theme.v2) {
    let ThemeClass;
    switch (themeName) {
      // garden and sense are the same thing
      case "garden":
      case "sense":
        ThemeClass = SenseThemeProps;
        break;
      case "colourful":
        ThemeClass = ColorfulThemeProps;
        break;
      case "boost":
        ThemeClass = BoostThemeProps;
        break;
      case "flight":
        ThemeClass = FlightThemeProps;
        break;
      case "medleaf":
        ThemeClass = MedLeafThemeProps;
        break;
      case "default":
      default:
        ThemeClass = DefaultThemeProps;
    }

    ThemeClass = inStoreThemeExperienceThemePropsMixin(
      CheckoutThemePropsMixin(ThemeClass)
    );
    return new ThemeClass();
  }
}
