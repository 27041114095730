import React, {useEffect} from "react";
import BasePage from "./BasePage";
import styled from "styled-components";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import Title from "src/core/common/components/modules/Title";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import useRouter from "src/core/common/hooks/useRouter";
import FiltersPanel from "src/themes/flight/components/FiltersPanel";
import ActionsSortFilter from "src/themes/flight/elements/filters/ActionsSortFilter";
import useAppliedFilters from "src/core/common/hooks/useAppliedFilters";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import useFiltersCatalog from "src/menu/hooks/useFiltersCatalog";
import AdBanner from "@themes/default/components/AdBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";

export default function FlightProductsPage(props) {
  const {
    query: {category: categorySlug},
  } = useRouter();
  const {showAdBanner} = useAdBanner();
  const [catalog] = useFiltersCatalog({});
  const categories = catalog ? catalog.categories : [];
  const category = categories.find(c => c.getSlug() === categorySlug);

  const [, setCategory, setProduct, , setSearch] = useBreadcrumbs();

  useEffect(() => {
    setSearch(null);
  }, []);

  const [filters] = useAppliedFilters();

  useEffect(() => {
    let name = category ? category.getName() : "All categories";
    setCategory([name]);
    setProduct();
    // eslint-disable-next-line
  }, [category]);

  return (
    <BasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <Container>
        <div className={"title"}>
          <Title styles={{fontSize: {lg: "52px", md: "52px", sm: "32px"}}}>
            {category ? category.getName() : "All categories"}
          </Title>
        </div>
        <div className={"filters"}>
          <FiltersPanel />
        </div>
        <div className={"mobile-filters"}>
          <ActionsSortFilter filters={filters} />
        </div>
        <div className={"browser"}>
          <CategorizedProductsBrowser showCaseIfNoCategory={false} />
        </div>
      </Container>
    </BasePage>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 32px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0;

  .title {
    grid-area: 1 / 1 / 2 / 6;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: ${({theme}) => theme.v2.spacing("m")} 0;
  }
  .filters {
    @media (min-width: 768px) {
      padding-top: 20px;
    }
    grid-area: 2 / 1 / 4 / 2;
  }
  .browser {
    grid-area: 2 / 2 / 4 / 6;
  }

  @media (max-width: 1023px) {
    padding: 0 16px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    .filters {
      display: none;
    }

    .product-card__header-container,
    .product-card__skeleton {
      margin: 0 auto;
    }
    .product-card__skeleton {
      display: block;
    }
  }

  @media (min-width: 768px) {
    .mobile-filters {
      display: none;
    }
  }
`;
