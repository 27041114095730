import React, {useMemo, useRef, useState} from "react";
import {useTheme} from "styled-components";
import {FiltersList as Filters} from "@ui";
import useClickOutside from "@ui/utils/useClickOutside";
import FiltersModalService from "@menu/services/filtersModalService";
import useModalService from "src/core/common/hooks/useModalService";
import useSite from "src/core/sites/hooks/useSite";
import {useForm} from "src/core/common/components/FormContext";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useBaseProductFilters from "@menu/hooks/useBaseProductFilters";
import useAppliedFilters, {
  ProductFilters as ProductFilter,
} from "src/core/common/hooks/useAppliedFilters";
import styles from "src/themes/legacy/components/ProductFilters/styles";

export default function FiltersList({
  isInline,
  header,
  displayOptions = {},
  onChangeFilter,
}) {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);
  const toggles = useFeatureToggles();
  const [appliedFilters, , clearFilters] = useAppliedFilters();
  const defaultDisplayOptions = {
    brandsVisible: true,
    typesVisible: true,
    categoriesVisible: true,
    featuredTagsVisible: true,
    tagsVisible: true,
    weightVisible: true,
    pricesVisible: toggles.priceFilterVisible(),
    potencyThcVisible: true,
    potencyCbdVisible: true,
    onSaleVisible: true,
  };
  const _displayOptions = {...defaultDisplayOptions, ...displayOptions};

  const collapseTagFilters = site.getUiConfiguration().filters.collapseTagFilters;

  const [isOpen, setIsOpen] = useState(false);

  const formContext = useForm();
  const disabled = formContext.disabled;

  const {
    meta,
    filters,
    brands,
    types,
    subcategories,
    featuredTags,
    nonFeaturedTags,
    weights,
    hasOnSaleProducts,
    hasWeightFilters,
    pricesRange,
    potencyThcRange,
    potencyCbdRange,
    onChangeBrand,
    onChangeSubcategory,
    onChangeType,
    onChangePrice,
    onChangeThc,
    onChangeCbd,
    onChangeTag,
    onChangeWeight,
    onChangeOnSale,
  } = useBaseProductFilters(onChangeFilter);

  const subcategoriesConfig = useMemo(() => {
    if (!subcategories.values.length) {
      return {};
    }
    return {
      type: "option",
      renderCondition: _displayOptions.categoriesVisible,
      name: subcategories.name + " subcategories",
      value: filters.subcategories,
      options: subcategories.values,
      onChange: onChangeSubcategory,
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    };
  }, [subcategories]);

  const config = [
    {
      type: "option",
      renderCondition: _displayOptions.onSaleVisible && hasOnSaleProducts,
      isSingleOption: true,
      id: "on-sale",
      name: "On Sale",
      value: filters.onSale,
      onChange: onChangeOnSale,
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
    },
    {
      type: "option",
      renderCondition: _displayOptions.featuredTagsVisible,
      name: "Featured",
      value: filters.tags,
      options: featuredTags,
      onChange: onChangeTag,
      disabled: disabled || meta.loading,
      collapseFilters: collapseTagFilters,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No featured products available right now",
    },
    subcategoriesConfig,
    {
      type: "option",
      renderCondition: _displayOptions.typesVisible,
      name: "Type",
      value: filters.types,
      options: types,
      onChange: onChangeType,
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.brandsVisible,
      name: "Brands",
      value: filters.brands,
      options: brands,
      onChange: onChangeBrand,
      disabled: disabled || meta.loading,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.tagsVisible,
      name: "Tags",
      value: filters.tags,
      options: nonFeaturedTags,
      onChange: onChangeTag,
      disabled: disabled || meta.loading,
      collapseFilters: collapseTagFilters,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "option",
      renderCondition: _displayOptions.weightVisible && hasWeightFilters,
      isGridLayout: true,
      name: "Weights",
      value: filters.weights,
      options: weights,
      onChange: onChangeWeight,
      disabled: disabled || meta.loading,
      collapsedOptionsToDisplay: 6,
      checkedMarkColor: _styles?.section?.checkmarkColor,
      noOptionsText: "No products match the specified filters",
    },
    {
      type: "range",
      renderCondition: _displayOptions.pricesVisible,
      name: "Price",
      ranges: [
        {
          name: "From",
          value: filters.prices,
          range: pricesRange,
          onChange: onChangePrice,
          hideIcon: true,
          unit: "$",
          unitPosition: "pre",
          disabled: disabled || meta.loading,
        },
      ],
    },
    {
      type: "range",
      renderCondition:
        _displayOptions.potencyThcVisible && _displayOptions.potencyCbdVisible,
      name: "Potency",
      ranges: [
        {
          name: "THC",
          value: filters.potencyThc,
          range: potencyThcRange,
          onChange: onChangeThc,
          hideIcon: true,
          unit: filters.thcUnit,
          unitPosition: "post",
          disabled: disabled || meta.loading,
        },
        {
          name: "CBD",
          value: filters.potencyCbd,
          range: potencyCbdRange,
          onChange: onChangeCbd,
          hideIcon: true,
          unit: filters.cbdUnit,
          unitPosition: "post",
          disabled: disabled || meta.loading,
        },
      ],
    },
  ];

  useModalService(FiltersModalService, () => {
    setIsOpen(true);
  });

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const _filters = useMemo(
    () =>
      _displayOptions.brandsVisible
        ? ProductFilter.fromPrototype(appliedFilters)
        : ProductFilter.fromPrototype(appliedFilters, {brand: null}),
    [_displayOptions.brandsVisible, appliedFilters]
  );

  return (
    <Filters
      wrapperRef={wrapperRef}
      isInline={isInline}
      isOpen={isOpen}
      header={header}
      options={config}
      clearFilters={clearFilters}
      hasAppliedFilters={_filters.hasFilters()}
      filtersCounter={_filters.getCounters().total}
      onClose={() => setIsOpen(false)}
      styles={_styles}
    />
  );
}
