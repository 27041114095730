import React from "react";
import useFeatureToggles from "../../../hooks/useFeatureToggles";
import useAuthenticationForm from "../../../../authentication/hooks/useAuthenticationForm";
import curry from "lodash/curry";
import useFormsCache from "src/core/common/hooks/useFormsCache";
import {FormFields} from "../../collections/form/forms";
import EmailOrPhoneField from "./EmailOrPhoneField";
import PhoneInputField from "./PhoneInput";
import EmailInputField from "./EmailInput";

function IdentifierField() {
  const toggles = useFeatureToggles();
  const [authenticationForm] = useAuthenticationForm();
  const [phoneValue, setPhoneValue] = useFormsCache(FormFields.PHONE);
  const [emailValue, setEmailValue] = useFormsCache(FormFields.EMAIL);
  const [emailOrPhoneValue, setEmailOrPhoneValue] = useFormsCache(
    FormFields.EMAIL_OR_PHONE
  );

  const onChangeEmailOrPhone = curry((callback, evt) => {
    authenticationForm.setEmail(evt.target.value);
    setEmailOrPhoneValue(evt.target.value);
    callback(evt.target.value);
  });

  const onChangePhone = curry((callback, value) => {
    setPhoneValue(value);
    callback(value);
  });

  const onChangeEmail = curry((callback, evt) => {
    setEmailValue(evt.target.value);
    callback(evt.target.value);
  });

  function computePhoneInputProps({input}) {
    return {
      onChange: onChangePhone(input.onChange),
    };
  }

  function computeEmailInputProps({input}) {
    return {
      onChange: onChangeEmail(input.onChange),
    };
  }

  const emailOrPhoneInitialValue =
    emailOrPhoneValue ||
    (authenticationForm.getEmail()
      ? authenticationForm.getEmail()
      : authenticationForm.getPhone());

  return (
    <>
      {toggles.loginWithEmail() && (
        <EmailInputField
          name="email"
          initialValue={
            emailValue || (authenticationForm && authenticationForm.getEmail())
          }
          isRequired
          computeEmailInputProps={computeEmailInputProps}
        />
      )}
      {!toggles.loginWithEmail() && !toggles.disabledEmail() && (
        <EmailOrPhoneField
          initialValue={emailOrPhoneInitialValue}
          onChange={onChangeEmailOrPhone}
        />
      )}
      {!toggles.loginWithEmail() && toggles.disabledEmail() && (
        <PhoneInputField
          name={"phoneNumber"}
          initialValue={
            phoneValue || (authenticationForm && authenticationForm.getPhone())
          }
          isRequired={true}
          computePhoneInputProps={computePhoneInputProps}
        />
      )}
    </>
  );
}

export function handleIdentifier({email, phoneNumber, emailOrPhone}) {
  const params = {};
  if (emailOrPhone) {
    params.email = emailOrPhone.indexOf("@") > -1 ? emailOrPhone : undefined;
    params.phoneNumber = emailOrPhone.indexOf("@") > -1 ? undefined : emailOrPhone;
  } else {
    params.email = email || undefined;
    params.phoneNumber = phoneNumber || undefined;
  }
  return params;
}

export default IdentifierField;
