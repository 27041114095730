import React from "react";
import styled, {createGlobalStyle} from "styled-components";
import {QrCode} from "@styled-icons/bootstrap/QrCode";
import {ShoppingCartOutline} from "@styled-icons/evaicons-outline/ShoppingCartOutline";
import useSite from "src/core/sites/hooks/useSite";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import {isMobile} from "src/core/common/utils";
import CartModalService from "src/core/checkout/components/cart/cartModalService";
import PrimaryButton from "src/core/common/components/elements/button/PrimaryButton";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";

export default function ProductDetailHeaderActions({styles}) {
  const router = useRouter();
  const site = useSite();
  const [, , restore] = useCustomHome();

  const {instore_experience} = router.query;
  const options = site.getOptions();

  const onBackButtonClickHandler = () => {
    restore();
    router.push({
      pathname: routes.home,
      keepQuery: true,
    });
  };

  const onCartButtonClickHandler = () => {
    CartModalService.show();
  };

  // TODO: refactor integrations
  if (!isMobile()) return null;

  if (
    (!options.inStoreExperienceEnabled() || !instore_experience) &&
    !options.hasInStoreExperienceEventFlow()
  )
    return null;

  return (
    <NavigationActionsContainer styles={styles.root}>
      <GlobalStyles />
      <ButtonContainer styles={styles.backButton}>
        <Button
          onClick={onBackButtonClickHandler}
          LeftIconComponent={() => <QrCode width={24} styles={styles.backButton} />}
          label="Scan More"
        />
      </ButtonContainer>
      <ButtonContainer styles={styles.cartButton}>
        <Button
          onClick={onCartButtonClickHandler}
          LeftIconComponent={ShoppingCartIcon}
          label={"Cart"}
        />
      </ButtonContainer>
    </NavigationActionsContainer>
  );
}

const NavigationActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding: 16px;
  background: white;
`;

const Button = styled(PrimaryButton)`
  > svg {
    width: 24px;
    height: 24px;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;

  * > button {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    background-color: ${({styles}) => styles.backgroundColor};
    color: ${({styles}) => styles.color};
    font-weight: ${({styles}) => styles.fontWeight};
  }

  svg {
    color: ${({styles}) => styles.color} !important;
  }
`;

const ShoppingCartIcon = styled(ShoppingCartOutline)`
  width: 24px;
  height: 24px;
`;

const GlobalStyles = createGlobalStyle`
  .ReactModal__Content {
    top: 0 !important;
  }
  
  .product-info__container {
    padding: 0;
  }

  .delivery-banner__container {
    display: none;
  }
  
  .top-nav {
    display: none;
  }

  .breadcrumbs__container {
    display: none;
  }
`;
