import React, {useEffect, useState} from "react";
import * as ReactDOM from "react-dom";
import styled, {useTheme} from "styled-components";
import {ShoppingCartOutline} from "@styled-icons/evaicons-outline/ShoppingCartOutline";
import {ArrowRightShort} from "@styled-icons/bootstrap/ArrowRightShort";
import {Trash2} from "styled-icons/feather/Trash2";
import QuantityPicker from "@ui/components/ProductDetail/BigQuantityPicker";
import {isMobile} from "src/core/common/utils";
import * as Notifications from "src/core/notifications";
import useSite from "src/core/sites/hooks/useSite";
import useCartState from "src/core/checkout/hooks/useCartState";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import PrimaryButton from "src/core/common/components/elements/button/PrimaryButton";
import OutlinedPrimaryButton from "src/core/common/components/elements/button/OutlinedPrimaryButton";
import styles from "src/integrations/inStoreExperience/components/styles";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";

const CART_ACTIONS = "cart-actions";

export default function ProductDetailActions(props) {
  const site = useSite();
  const options = site.getOptions();
  const router = useRouter();

  const {instore_experience} = router.query;

  const [element, setElement] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    const el = document.getElementById(CART_ACTIONS);
    if (!el) {
      const newElement = document.createElement("div");
      newElement.id = CART_ACTIONS;
      document.body.append(newElement);
      setElement(newElement);
    } else {
      setElement(el);
    }
  }, []);
  /* eslint-enable */

  if (!element) return null;

  if (!isMobile()) return <QuantityPicker {...props} />;

  if (
    (!options.inStoreExperienceEnabled() || !instore_experience) &&
    !options.hasInStoreExperienceEventFlow()
  ) {
    return <QuantityPicker {...props} />;
  }

  return ReactDOM.createPortal(
    <CartActionsContainer>
      <Actions {...props} />
    </CartActionsContainer>,
    element
  );
}

const Actions = ({product, onAddItem}) => {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);
  const toggles = useFeatureToggles();
  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();

  const [cartState, cartActions] = useCartState();
  const cart = cartState.data;
  const cartItem = cart && product && cart.getItem(product);
  const router = useRouter();

  const [addedToCart, setAddedToCart] = useState(false);
  const [, , restore] = useCustomHome();

  const [productLoading, setProductLoading] = useState(null);
  const isProductLoading =
    productLoading && productLoading.getId() === product.getId() && cartState.loading;

  useEffect(() => {
    if (addedToCart && cartItem && onAddItem) {
      onAddItem({product});
    }
  }, [cartItem, addedToCart, onAddItem, product]);

  const onAddToCartButtonClickHandler = () => {
    const weight = product.hasWeightPrices()
      ? product.getFirstWeightPrice().weightKey
      : null;

    cartActions.addItem(cartState.data, {product, quantity: 1, weightKey: weight});
    setProductLoading(product);
    setAddedToCart(true);
  };

  const onBuyNowButtonClickHandler = async () => {
    const weight = product.hasWeightPrices()
      ? product.getFirstWeightPrice().weightKey
      : null;

    try {
      await cartActions.addItem(cartState.data, {
        product,
        quantity: 1,
        weightKey: weight,
      });
      setProductLoading(product);
      setAddedToCart(true);
      router.push(routes.checkout);
    } catch (e) {
      Notifications.error("Unable to go to checkout.");
    }
  };

  const onContinueShoppingButtonClickHandler = () => {
    restore();
    router.push({
      pathname: routes.home,
      keepQuery: true,
    });
  };

  const onRemoveItemButtonClickHandler = () => {
    cartActions.deleteItem(cartState.data, {product: cartItem});
  };

  const displayBuyNow = user || toggles.guestCheckoutEnabled();

  return (
    <AddToCartSection>
      {cartItem ? (
        <UpdateItem
          loading={isProductLoading}
          onContinueShopping={onContinueShoppingButtonClickHandler}
          onRemoveItem={onRemoveItemButtonClickHandler}
          styles={_styles.button}
        />
      ) : (
        <AddItem
          loading={isProductLoading}
          onAddToCart={onAddToCartButtonClickHandler}
          onBuyNow={onBuyNowButtonClickHandler}
          styles={_styles.button}
          displayBuyNow={displayBuyNow}
        />
      )}
    </AddToCartSection>
  );
};

const AddItem = ({loading, onAddToCart, onBuyNow, displayBuyNow, styles}) => {
  return (
    <>
      <FirstActionContainer styles={styles}>
        <PrimaryButton
          loading={loading}
          onClick={loading ? null : onAddToCart}
          label={"Add to Cart"}
          LeftIconComponent={ShoppingCartIcon}
        />
      </FirstActionContainer>
      {displayBuyNow && (
        <BuyNowContainer styles={styles}>
          <OutlinedPrimaryButton
            outline
            onClick={loading ? null : onBuyNow}
            label={"Buy now"}
            RightIconComponent={ArrowRightIcon}
          />
        </BuyNowContainer>
      )}
    </>
  );
};

const UpdateItem = ({loading, onContinueShopping, onRemoveItem, styles}) => {
  return (
    <>
      <FirstActionContainer styles={styles}>
        <PrimaryButton onClick={onContinueShopping} styles={styles}>
          <span>Continue shopping</span>
        </PrimaryButton>
      </FirstActionContainer>
      <RemoveItemContainer styles={styles}>
        <OutlinedPrimaryButton
          onClick={onRemoveItem}
          LeftIconComponent={RemoveItemIcon}
          loading={loading}
          styles={styles}
        />
      </RemoveItemContainer>
    </>
  );
};

const RemoveItemIcon = styled(Trash2)`
  width: 24px;
  height: 24px;
`;

const CartActionsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
`;

const AddToCartSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: ${({theme}) => theme.v2.spacing(4)};
  background: white;

  .button {
    font-size: 16px;
  }
`;

const ShoppingCartIcon = styled(ShoppingCartOutline)`
  width: 24px;
  height: 24px;
`;

const ArrowRightIcon = styled(ArrowRightShort)`
  width: 24px;
  height: 24px;
`;

const FirstActionContainer = styled.div`
  flex: 1;

  * > button {
    background-color: ${({styles}) => styles.backgroundColor};
    color: ${({styles}) => styles.color};
    font-weight: ${({styles}) => styles.fontWeight};
  }

  svg {
    color: ${({styles}) => styles.color};
  }
`;

const BuyNowContainer = styled.div`
  flex: 1;

  * > button {
    background-color: ${({styles}) => styles.color};
    border: 1px solid ${({styles}) => styles.backgroundColor};
    color: ${({styles}) => styles.backgroundColor};
    font-weight: ${({styles}) => styles.fontWeight};
  }

  * > svg {
    color: ${({styles}) => styles.backgroundColor};
  }
`;

const RemoveItemContainer = styled.div`
  max-width: 70px;

  * > button {
    background-color: ${({styles}) => styles.color};
    border: 1px solid ${({styles}) => styles.backgroundColor};

    > span {
      display: none;
    }
  }

  * > svg {
    color: ${({styles}) => styles.backgroundColor};
  }
`;
