import {useEffect} from "react";
import {PaymentOptions} from "src/core/payments/constants";
import {getCreditCardFormProps} from "src/core/payments/utils";
import useCreditCardPaymentSources from "src/core/payments/hooks/useCreditCardPaymentSources";

export default function useMerrco({cart, order}) {
  const {
    paymentOption,
    paymentSources,
    removeAccount,
    initialPaymentSource,
    loading,
    disabled,
    refresh,
  } = useCreditCardPaymentSources({cart, order, sourceType: PaymentOptions.MERRCO});

  const shopCountry = "CA";

  /* eslint-disable */
  useEffect(() => {
    paymentOption.setupMerrco();
  }, []);

  useEffect(() => {
    refresh();
  }, [cart]);

  const {
    cardInputProps,
    cardNameInputProps,
    cardExpirationDateProps,
    cardCvcProps,
    cardPostalCodeProps,
  } = getCreditCardFormProps(shopCountry);

  return {
    loading,
    disabled,
    paymentSources,
    removeAccount,
    initialPaymentSource,
    cardInputProps,
    cardNameInputProps,
    cardExpirationDateProps,
    cardCvcProps,
    cardPostalCodeProps,
  };
}
