import React from "react";
import MoneyIcon from "src/core/payments/components/MoneyIcon";
import styled from "styled-components";

export default function SavingsIndicatorLabel({savingsDisplay, savings}) {
  return (
    <LabelContainer>
      <MoneyIcon color={"#083EB0"} />
      {savingsDisplay || `You saved ${savings} with Stronghold`}
    </LabelContainer>
  );
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
