import React from "react";
import styled, {useTheme} from "styled-components";
import Rating from "src/core/common/components/modules/Rating";
import Skeleton from "react-loading-skeleton";
import ActionsSortFilter from "@menu/components/ThemedActionsSortFilter";
import media from "src/themes/utils/media";
import useSite from "src/core/sites/hooks/useSite";
import merge from "lodash/merge";
import get from "lodash/get";

function SenseBrandHeader({filters, filtersDisplayOptions, showSort, brand}) {
  const site = useSite();
  const hideDescription = site.getUiConfiguration().brandPage.hideDescription;
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};
  const descriptionTheme = {
    v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.text")),
  };
  const titleTheme = {
    v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.title")),
  };

  function createMarkup(description) {
    return {__html: description};
  }

  if (!brand) {
    return (
      <HeaderContainer>
        <CentralHeader logo={false} description={false}>
          <Skeleton height={32} width={250} />
        </CentralHeader>
      </HeaderContainer>
    );
  } else {
    const logo = brand.getLogo();
    const description = brand.getDescription();
    const name = brand.getName();
    const rating = brand.getRating();
    return (
      <HeaderContainer>
        {logo && (
          <BrandContainer>
            {logo && <BrandLogo src={logo} alt={"brand logo"} />}
          </BrandContainer>
        )}
        <CentralHeader logo={logo} description={description}>
          <BrandName logo={logo} description={description} style={titleTheme}>
            {name}
          </BrandName>
          <RateReview style={{display: "none"}}>
            <Rating readonly={true} initialRating={rating} size={"20px"} />
          </RateReview>
          {!hideDescription && (
            <Description
              dangerouslySetInnerHTML={createMarkup(description)}
              style={descriptionTheme}
            />
          )}
        </CentralHeader>
        <FiltersContainer>
          {showSort && (
            <ActionsSortFilter
              filters={filters}
              filtersDisplayOptions={filtersDisplayOptions}
            />
          )}
        </FiltersContainer>
      </HeaderContainer>
    );
  }
}

const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 ${({theme}) => `${theme.v2.spacing(8)}`};

  ${media.down("md")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`};
  }

  @media (max-width: 768px) {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(4) : 0)}
      ${({theme}) => theme.v2.spacing(4)};
    flex-direction: column;
    align-items: center;
  }
`;

const BrandContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
  height: 200px;
`;

const BrandLogo = styled.img`
  width: 200px;
  height: 100%;
  object-fit: contain;
`;

const CentralHeader = styled.div`
  flex-grow: 1;
  padding-left: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)};
  display: flex;
  flex-direction: column;
  justify-content: ${({description}) => (description ? "space-between" : "center")};

  @media (max-width: 768px) {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)} 0;
    padding-bottom: ${({description, theme}) => (description ? theme.v2.spacing(8) : 0)};
    flex-direction: column;
    width: 100%;
  }
`;

const BrandName = styled.h2`
  font-family: ${({theme, style}) =>
    style ? style.v2.typography.title1.family : theme.v2.typography.title1.family};
  font-size: ${({theme, style}) =>
    style ? style.v2.typography.title1.size.lg : theme.v2.typography.title1.size.lg};
  font-weight: ${({theme, style}) =>
    style ? style.v2.typography.title1.weight : theme.v2.typography.title1.weight};
  color: ${({theme, style}) =>
    style ? style.v2.color.onBackground : theme.v2.color.onBackground};
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 768px) {
    padding-bottom: ${({theme}) => theme.v2.spacing(4)};
    font-size: ${({theme}) => theme.v2.typography.title1.size.md};
  }
`;

const RateReview = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({theme}) => theme.v2.spacing(2)};

  @media (max-width: 768px) {
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: ${({theme}) => theme.v2.spacing(4)};
  }
`;

const Description = styled.div`
  text-align: justify;
  color: ${({theme, style}) =>
    style ? style.v2.color.onBackground : theme.v2.color.onBackground};
  font-family: ${({theme, style}) =>
    style ? style.v2.typography.title5.family : theme.v2.typography.title5.family};
  font-size: ${({theme, style}) =>
    style ? style.v2.typography.title5.size.lg : theme.v2.typography.title5.size.lg};
  line-height: ${({theme, style}) =>
    style
      ? style.v2.typography.title5.lineHeight
      : theme.v2.typography.title5.lineHeight};
`;

export default SenseBrandHeader;
