import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import {autoPlay} from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import {renderNodeOrComponent} from "@ui/components";
import media from "@ui/utils/media";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function AdBanner({AdComponent, onChangeIndicator, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const bannerContainerRef = useRef();
  const [bannerContainerSize, setBannerContainerSize] = useState();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (bannerContainerRef.current) {
      const sizes = bannerContainerRef.current.getBoundingClientRect();
      setBannerContainerSize({
        width: sizes.width,
        height: sizes.height,
      });
    }
  }, [bannerContainerRef.current]);

  const swipeableViewsProps = {
    enableMouseEvents: true,
    index: index,
    interval: 5000,
    onChangeIndex: newIndex => {
      setIndex(newIndex);
      onChangeIndicator && onChangeIndicator(index);
    },
  };

  if (!AdComponent) {
    return null;
  }

  return (
    <BackgroundContainer styles={_styles.root}>
      <Container ref={bannerContainerRef} styles={_styles.root}>
        <AutoPlaySwipeableViews {...swipeableViewsProps}>
          {renderNodeOrComponent(AdComponent, {bannerContainerSize})}
        </AutoPlaySwipeableViews>
      </Container>
    </BackgroundContainer>
  );
}

const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: ${({styles}) => styles?.height?.lg};
  background-color: ${({styles}) => styles.backgroundColor};
  background-image: ${({styles}) => styles.backgroundImage};

  ${media.down("sm")} {
    height: ${({styles}) => styles?.height?.sm};
  }
`;

const Container = styled.div`
  width: 100%;
  display: block;
  box-sizing: border-box;
  max-width: 1440px;
  padding: ${({styles}) => styles.padding};
  position: relative;

  ${media.down("md")} {
    padding: 0 !important;
  }
`;

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    backgroundImage: "none",
    padding: "0 32px",
  },
  navigationButton: {
    root: {
      backgroundColor: "#eeeeee",
      color: "#000000",
      width: "40px",
      height: "40px",
      borderRadius: "30px",
      position: "relative",
      side: "50%",
    },
    icon: {
      size: "16px",
    },
  },
};

AdBanner.defaultProps = {
  styles: defaultStyles,
};

AdBanner.propTypes = {
  onChangeIndicator: PropTypes.func,
  AdComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundImage: PropTypes.string,
    }),
  }),
};
