import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import CategoryBar from "@menu/components/CategoryBar";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import ProductDetail from "@themes/default/components/ProductDetail";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import AdBanner from "@themes/default/components/AdBanner";

export default function MedLeafBasePage({children, ...props}) {
  const toggles = useFeatureToggles();
  const {showAdBanner} = useAdBanner();

  return (
    <div>
      <Header isSearchEnabled={true} options={<OptionsList />} />
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <Content>
        <CategoryBar />
        {!toggles.kioskMode() && <DeliveryBanner />}
        <CategorizedProductsBrowser />
      </Content>
      <ProductDetail {...props} />
    </div>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
