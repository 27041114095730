import * as React from "react";

const WheelHybrid = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.65454 16.7891C8.61704 16.7891 7.64204 16.5922 6.72954 16.1984C5.81704 15.8047 5.02329 15.2703 4.34829 14.5953C3.67329 13.9203 3.13892 13.1266 2.74517 12.2141C2.35142 11.3016 2.15454 10.3266 2.15454 9.28906C2.15454 8.25156 2.35142 7.27656 2.74517 6.36406C3.13892 5.45156 3.67329 4.65781 4.34829 3.98281C5.02329 3.30781 5.81704 2.77344 6.72954 2.37969C7.64204 1.98594 8.61704 1.78906 9.65454 1.78906C10.692 1.78906 11.667 1.98594 12.5795 2.37969C13.492 2.77344 14.2858 3.30781 14.9608 3.98281C15.6358 4.65781 16.1702 5.45156 16.5639 6.36406C16.9577 7.27656 17.1545 8.25156 17.1545 9.28906C17.1545 10.3266 16.9577 11.3016 16.5639 12.2141C16.1702 13.1266 15.6358 13.9203 14.9608 14.5953C14.2858 15.2703 13.492 15.8047 12.5795 16.1984C11.667 16.5922 10.692 16.7891 9.65454 16.7891ZM14.867 12.2891H7.68579L6.93579 13.0391H14.3608C14.4608 12.9141 14.5514 12.7922 14.6327 12.6734C14.7139 12.5547 14.792 12.4266 14.867 12.2891ZM15.617 10.0391H9.95454L9.20454 10.7891H15.467C15.492 10.6641 15.517 10.5422 15.542 10.4234C15.567 10.3047 15.592 10.1766 15.617 10.0391ZM15.467 7.78906H12.2045L11.4545 8.53906H15.617C15.592 8.40156 15.567 8.27344 15.542 8.15469C15.517 8.03594 15.492 7.91406 15.467 7.78906ZM14.3983 5.61406L13.7233 6.28906H14.867C14.8045 6.16406 14.7327 6.04844 14.6514 5.94219C14.5702 5.83594 14.4858 5.72656 14.3983 5.61406ZM4.91079 12.9641L13.367 4.54531C12.8545 4.14531 12.2858 3.83594 11.6608 3.61719C11.0358 3.39844 10.367 3.28906 9.65454 3.28906C7.97954 3.28906 6.56079 3.87031 5.39829 5.03281C4.23579 6.19531 3.65454 7.61406 3.65454 9.28906C3.65454 9.98906 3.76392 10.6484 3.98267 11.2672C4.20142 11.8859 4.51079 12.4516 4.91079 12.9641ZM9.65454 15.2891C10.192 15.2891 10.7045 15.2266 11.192 15.1016C11.6795 14.9766 12.142 14.7891 12.5795 14.5391H6.72954C7.16704 14.7891 7.62954 14.9766 8.11704 15.1016C8.60454 15.2266 9.11704 15.2891 9.65454 15.2891Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default WheelHybrid;
