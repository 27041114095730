import React, {useMemo} from "react";
import {useTheme} from "styled-components";
import {
  BuyNowActions,
  HorizontalProductCard,
  ProductCard,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useProductCard from "src/core/common/hooks/useProductCard";
import styles from "src/themes/baseTheme/components/ProductCard/styles";
import Product from "src/core/common/models/product";
import * as ThemedComponents from "src/themes/utils/themedComponents";
import useSite from "src/core/sites/hooks/useSite";
import useCartState from "src/core/checkout/hooks/useCartState";
import {getFlowerTypeIndicatorProps} from "src/themes/colorful/elements/flowerType";

export default function TheColorFulProductCard({product, ...props}) {
  const _product = product instanceof Product ? product : new Product(product);
  const {actionProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();
  const site = useSite();

  const {actionsMode} = site.getUiConfiguration().productCard;

  const onSale = _product.isOnSale();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const [cartState] = useCartState();
  const cart = cartState.data;
  const quantity = cart && cart.getProductQuantity(_product);
  const productId = product?.id;

  const DesktopProductCard = useMemo(
    () => (
      <div>
        <ProductCard
          {...props}
          {...productCardProps}
          styles={styles(theme, site, {mode: props.mode})}
          ActionsComponent={innerProps => (
            <BuyNowActions
              AddIconComponent={AddIconComponent}
              {...actionProps}
              {...innerProps}
              mode={props.mode || "big"}
              buyText={
                props.mode === "small"
                  ? "Buy"
                  : site.getUiConfiguration().productCard.buyText
              }
            />
          )}
          potencyTagsPosition="bottom"
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          SaleIndicatorComponent={props =>
            onSale && <SaleIndicator {...props} position={"left"} />
          }
        />
      </div>
    ),
    // eslint-disable-next-line
    [productId, quantity, actionProps.quantity]
  );

  const MobileProductCard = useMemo(
    () => (
      <div>
        <HorizontalProductCard
          {...props}
          {...productCardProps}
          styles={styles(theme, site, {isHorizontalInMobile: true})}
          showSizes
          potencyTagsPosition="bottom"
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          SaleIndicatorComponent={props =>
            onSale && <SaleIndicator {...props} position={"right"} />
          }
          ActionsComponent={props => (
            <BuyNowActions
              AddIconComponent={AddIconComponent}
              mode={actionsMode}
              {...props}
              {...actionProps}
            />
          )}
        />
      </div>
      // eslint-disable-next-line
    ),
    [productId, quantity, actionProps.quantity]
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      TabletProductCard={DesktopProductCard}
      MobileProductCard={props.mode === "small" ? DesktopProductCard : MobileProductCard}
    />
  );
}

const AddIconComponent = () => {
  const theme = useTheme();

  const ShoppingCartIcon = ThemedComponents.get(theme.v1.icons.shopping);

  return <ShoppingCartIcon style={{height: "22px", width: "22px"}} color={"#fff"} />;
};
