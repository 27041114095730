import BasePage from "@themes/default/pages/BasePage";
import HomePage from "@themes/default/pages/HomePage";
import BrandDetailPage from "@themes/default/pages/BrandDetailPage";
import CategoryDetailPage from "@themes/default/pages/CategoryDetailPage";
import SearchPage from "@themes/default/pages/SearchPage";
import DealsPage from "@themes/default/pages/DealsPage";
import Button from "@themes/default/components/Button";
import Header from "@themes/default/components/Header";
import Footer from "@themes/default/components/Footer";
import ProductDetail from "@themes/default/components/ProductDetail";
import CategoryBar from "@themes/default/components/CategoryBar";
import DeliveryBanner from "@themes/default/components/DeliveryBanner";
import RecommendedProductsList from "@themes/default/components/RecommendedProductsList";
import ShowcaseProductsList from "@themes/default/components/ShowcaseProductsList";
import ProductCard from "@themes/default/components/ProductCard";
import {DetailProductsListWrapper} from "@themes/default/components/DetailProductsList";
import PromotionalBanner from "@themes/default/components/PromotionalBanner";
import AdBanner from "@themes/default/components/AdBanner";
import Title from "@themes/default/components/Title";
import ViewAllLink from "@themes/default/components/ViewAllLink";

export default {
  inherits: "legacy",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 6,
  },
  components: {
    Button,
    Header,
    Footer,
    RecommendedProductsList,
    ShowCasedProductsList: ShowcaseProductsList,
    ProductsList: DetailProductsListWrapper,
    ProductDetail,
    CategoryBar,
    DeliveryBanner,
    ProductCard,
    ViewAllLink,
    Title,
    PromotionalBanner,
    AdBanner,
  },
  pages: {
    BasePage,
    BrandPage: BrandDetailPage,
    ProductsPage: CategoryDetailPage,
    SubcategoryPage: CategoryDetailPage,
    ProductDetailPage: CategoryDetailPage,
    HomePage: HomePage,
    AllCategoriesPage: HomePage,
    SearchPage,
    DealsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};
