import * as React from "react";
import PropTypes from "prop-types";

const USBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7V17.0583L12.5044 19.9656L21.0288 17.0583V7H4Z"
        fill="#DE1E2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4843 16.7393H9.87523V15.8431C9.35735 16.5599 8.62061 16.9382 7.56488 16.9382C6.31026 16.9382 5.11499 16.1618 5.11499 14.5286V9.66874H7.82367V13.4129C7.82367 14.2099 7.92326 14.7871 8.7202 14.7871C9.15847 14.7871 9.77565 14.5679 9.77565 13.4129V9.66874H12.4843V16.7393ZM17.5233 11.7403C17.4834 11.5012 17.4038 11.3419 17.2046 11.2224C17.0451 11.0629 16.826 10.9833 16.5869 10.9833C16.2485 10.9833 15.7902 11.0831 15.7902 11.5411C15.7902 11.7602 15.9494 11.8598 16.1089 11.9197C16.5669 12.1186 17.5827 12.1586 18.4989 12.4773C19.3552 12.7564 20.1519 13.274 20.1519 14.4293C20.1519 16.4009 18.2995 16.9388 16.5666 16.9388C14.8937 16.9388 13.0813 16.2617 13.0216 14.4293H15.5913C15.6313 14.6884 15.7309 14.8872 15.9497 15.0667C16.0693 15.166 16.3284 15.2656 16.6668 15.2656C17.0055 15.2656 17.583 15.1463 17.583 14.7277C17.583 14.3097 17.3242 14.2101 16.0693 13.991C14.018 13.6326 13.1811 12.9552 13.1811 11.7602C13.1811 9.96777 15.1131 9.50952 16.5272 9.50952C18.0806 9.50952 19.913 9.92782 19.9729 11.7403H17.5233Z"
        fill="white"
      />
    </svg>
  );
};

USBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default USBankIcon;
