import {Modal} from "@ui/components";
import React, {useState} from "react";
import Loader from "src/core/common/components/elements/Loader";
import styled from "styled-components";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import {MonerisEvents} from "src/core/payments/moneris/Moneris";

function MonerisExternalAuthModal({showForm, challengeUrl, challengeData}) {
  const [loading, setLoading] = useState(true);

  const router = useRouter();
  const {pathname} = router.resolve({pathname: routes.paymentVerification});

  const encodedChallengeUrl = encodeURIComponent(challengeUrl);
  const encodedChallengeDataUrl = encodeURIComponent(challengeData);

  const iframePath = `${
    router._getNextRouter().basePath
  }${pathname}?challengeUrl=${encodedChallengeUrl}&challengeData=${encodedChallengeDataUrl}`;

  const iframeStyles = {
    border: 0,
  };

  const onCloseModal = () => {
    window.postMessage(
      {
        code: MonerisEvents.EXTERNAL_AUTH_FAILURE,
      },
      window.location.origin
    );
  };

  return (
    <Modal isOpen={showForm} onRequestClose={onCloseModal} allowedToClose={() => true}>
      <Content>
        {loading && <Loader style={{width: 50, height: 50}} />}
        <iframe
          title="monerisExternalAuthFrame"
          data-cy="moneris-3ds-iframe"
          height={loading ? 0 : "100%"}
          width={loading ? 0 : "100%"}
          src={iframePath}
          style={iframeStyles}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </Content>
    </Modal>
  );
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default MonerisExternalAuthModal;
