import KlaviyoTracker from "./klaviyo";
import NullTracker from "../../core/analytics/trackers/null";
import {events} from "../../core/analytics/ecommerce/events";
import {
  addedToCart,
  orderSuccessfulKlaviyo,
  startedCheckout,
  viewedProduct,
} from "./handlers";
import {AnalyticsLogger} from "src/core/common/logger";

function makeKlaviyoTracker(key) {
  AnalyticsLogger.debug("makeKlaviyoTracker: " + key);
  const tracker = key ? new KlaviyoTracker(key) : new NullTracker();
  if (key) {
    tracker.addHandler(events.PRODUCT_DETAIL_VIEW, viewedProduct);
    tracker.addHandler(events.ADD_ITEM_SUCCESSFUL, addedToCart);
    tracker.addHandler(events.CHECKOUT_STARTED, startedCheckout);
    tracker.addHandler(events.ORDER_SUCCESSFUL, orderSuccessfulKlaviyo);
  }

  return tracker;
}

export default makeKlaviyoTracker;
