import {makeThemeProps} from "src/themes/utils/utils";

export const getAdyenFormProps = (theme, site, options = {}) => {
  const themeStyles = makeThemeProps(theme, site, options);
  return themeStyles.getAdyenFormProps(theme, options);
};

export const getAdyenCreditCardFormProps = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getAdyenCreditCardFormProps(theme);
};
