import * as React from "react";

const CannabisHybrid = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.74613 8.69432C6.72136 8.40616 6.6704 8.12295 6.6612 7.84187C6.65176 7.56054 6.63383 7.27993 6.60741 7.00003C6.58617 6.77275 6.62298 6.41591 6.63218 6.2212C6.7341 4.07684 7.26919 2.05898 8.23744 0.167617C8.34432 -0.0405418 8.63451 -0.0681551 8.75129 0.160536C9.80165 2.22135 10.3481 4.40772 10.3905 6.71965C10.3976 7.06941 10.3551 7.46378 10.3431 7.83903C10.3339 8.11587 10.2801 8.40191 10.2589 8.68937C10.2561 8.72288 10.265 8.72642 10.2858 8.69999C11.7235 6.84827 13.5121 5.53678 15.6515 4.76551C15.9934 4.64231 16.0769 4.92836 16.0373 5.19811C15.6735 7.66486 14.1786 9.84557 12.2513 11.3225C12.1598 11.3924 12.1706 11.4162 12.2839 11.394C12.4905 11.3525 12.7163 11.322 12.9612 11.3027C14.2635 11.1972 15.5482 11.2991 16.7826 11.6928C17.0706 11.7841 17.0466 12.0588 16.8611 12.2493C16.042 13.0923 15.0563 13.6434 13.904 13.9025C12.9662 14.1135 12.0276 14.1291 11.0707 14.1192C11.0443 14.1192 11.0408 14.1279 11.0601 14.1454C11.3574 14.4173 12.3582 15.4658 12.2464 15.8793C12.1749 16.1455 11.6787 16.0641 11.4034 16.0202C10.4106 15.8616 9.56714 15.4326 8.87303 14.733C8.81311 14.6726 8.78314 14.6851 8.78314 14.7706L8.78385 16.7594C8.78397 16.8172 8.76259 16.873 8.72387 16.9159C8.68515 16.9588 8.63185 16.9858 8.57435 16.9916L8.51348 16.9973C8.31671 17.0171 8.21833 16.9279 8.21833 16.7297L8.21975 14.7529C8.21975 14.6769 8.19356 14.6665 8.14118 14.7217C7.36615 15.5374 6.26555 16.0096 5.15432 16.0627C3.94896 16.1201 5.76655 14.3061 5.95483 14.1397C5.977 14.1204 5.97323 14.1112 5.9435 14.1121C4.42105 14.1808 2.83065 14.0215 1.49789 13.2979C1.13267 13.0996 -0.105958 12.2875 0.00728754 11.8974C0.0532937 11.7416 0.184234 11.7034 0.327915 11.6609C1.25653 11.3876 2.18326 11.2538 3.1081 11.2595C3.67787 11.2623 4.19456 11.2807 4.71549 11.3975C4.8344 11.424 4.84572 11.3999 4.74946 11.3253C2.77332 9.79884 1.35279 7.67265 0.955722 5.14501C0.899807 4.78604 1.13692 4.67488 1.43985 4.79383C3.54481 5.61584 5.34542 6.90374 6.71711 8.70565C6.73976 8.73492 6.74944 8.73114 6.74613 8.69432Z"
        fill="#FDB913"
      />
    </svg>
  );
};

export default CannabisHybrid;
