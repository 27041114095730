import React from "react";
import get from "lodash/get";
import {cardTypes} from "src/core/payments/constants";
import BankIcon from "src/core/payments/stronghold/bank-icons";
import PaymentIcon from "react-payment-icons-inline";
import FallbackCardIcon from "src/core/payments/components/FallbackCardIcon";

export class PaymentSource {
  constructor(paymentSourceObj, sourceType) {
    this._obj = paymentSourceObj;
    this._sourceType = sourceType;
  }
  get internalId() {
    return get(this._obj, "id");
  }
  get id() {
    return get(this._obj, "attributes.external_id");
  }
  get label() {
    return get(this._obj, "attributes.label_display");
  }
  get institutionName() {
    return get(this._obj, "attributes.provider_display");
  }
  get institution() {
    return get(this._obj, "attributes.provider");
  }
  get mask() {
    return get(this._obj, "attributes.mask");
  }
  get isDefault() {
    return get(this._obj, "attributes.is_default");
  }
  get sourceType() {
    return this._sourceType;
  }

  get expiryDate() {
    return get(this._obj, "attributes.expiry_date");
  }

  get isExpired() {
    return get(this._obj, "attributes.is_expired");
  }

  getMaskLabel() {
    return `ending in ${this.mask}`;
  }

  getExpiryLabel() {
    return this.isExpired
      ? `Expired in ${this.expiryDate}`
      : `Expires in ${this.expiryDate}`;
  }

  isCreditCard() {
    return false;
  }

  isSelected(value) {
    return value?.id === this.id;
  }

  displayConvenienceFee() {
    return false;
  }
}

export class CreditCardPaymentSource extends PaymentSource {
  getRetryLabel() {
    return "Add new card";
  }

  getIconComponent(props) {
    const iconName = this.institution;
    if (!!cardTypes[iconName]) {
      return (
        <PaymentIcon
          icon={cardTypes[iconName]}
          style={{width: 40, height: 25}}
          {...props}
        />
      );
    } else {
      return <FallbackCardIcon {...props} />;
    }
  }

  isCreditCard() {
    return true;
  }
}

export class BankPaymentSource extends PaymentSource {
  constructor(paymentSourceObj, sourceType, opts) {
    super(paymentSourceObj, sourceType);
    this._retryLabel = opts?.retryLabel;
    this._displayConvenienceFee = opts?.displayConvenienceFee;
  }
  getIconComponent(props) {
    return <BankIcon width={25} height={25} bank={this.institution} {...props} />;
  }
  getRetryLabel() {
    return this._retryLabel;
  }
  isCreditCard() {
    return false;
  }
  displayConvenienceFee() {
    return this._displayConvenienceFee;
  }
}

export class NullPaymentSource extends PaymentSource {
  constructor() {
    super({}, null);
  }

  get internalId() {
    return null;
  }
  get id() {
    return null;
  }
  get label() {
    return "Card";
  }
  get institutionName() {
    return "Card";
  }
  get institution() {
    return null;
  }
  get mask() {
    return null;
  }
  get isDefault() {
    return true;
  }
  get sourceType() {
    return null;
  }

  get expiryDate() {
    return null;
  }

  get isExpired() {
    return false;
  }

  getMaskLabel() {
    return `Card`;
  }

  getExpiryLabel() {
    return "";
  }

  isSelected() {
    return true;
  }
  displayConvenienceFee() {
    return false;
  }
}
