import {useEffect, useState} from "react";
import {sitesCache} from "src/apps/common/sitesCache";
import {isSSG} from "src/server/utils/isSSG";
import makeSiteFromSiteData from "src/apps/common/makeSiteFromSiteData";
import {loadSite} from "src/server/preloader/site";

function useSiteInitialization({kioskId, identifier, initialSite, siteGroupName}) {
  const [site, setSite] = useState(sitesCache[identifier]);
  useEffect(() => {
    if (!initialSite || (!isSSG() && initialSite.site)) return;

    let mounted = true;
    loadSite(site.env.serialize()).then(updatedSite => {
      if (mounted) {
        const site = makeSiteFromSiteData(updatedSite, {siteGroupName, kioskId});
        setSite(site);
      }
    });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
    }, [kioskId]);

  return {site};
}

export default useSiteInitialization;
