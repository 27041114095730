import get from "lodash/get";
import * as StoresApi from "src/core/api/shops";
import * as Analytics from "src/core/analytics";
import Weglot from "src/integrations/translator/weglot";
import makeGtmTracker from "./googleTagManager";
import makeKlaviyoTracker from "./klaviyo";
import makeGoogleAnalyticsTracker from "./googleAnalytics";
import makeGoogleAnalytics4Tracker from "./googleAnalytics4";
import makeSalesforceTracker from "./salesforce";
import Tracker from "src/core/analytics/trackers/tracker";
import makeSurfsideTracker from "src/integrations/surfside";

class IntegrationsLoader {
  constructor() {
    this.initialized = false;
  }

  makeService(service) {
    switch (service.attributes.service) {
      case "klaviyo":
        return makeKlaviyoTracker(service.attributes.key_1);
      case "google_analytics":
        return makeGoogleAnalyticsTracker(service.attributes.key_1);
      case "google_analytics_4":
        return makeGoogleAnalytics4Tracker(service.attributes.key_1);
      case "google_tag_manager":
        return makeGtmTracker(service.attributes.key_1);
      case "salesforce":
        return makeSalesforceTracker(service.attributes.key_1);
      case "surfside":
        return makeSurfsideTracker(service.attributes.key_1, service.attributes.key_2);
      case "weglot":
        return new Weglot(service.attributes.key_1);
      default:
        break;
    }
  }

  async init() {
    if (this.initialized) return;

    this.initialized = true;

    try {
      const siteIntegrations = await StoresApi.siteIntegrations().then(response =>
        response.data
          .filter(service => get(service, "attributes.key_1"))
          .map(this.makeService)
      );
      await Analytics.init(
        siteIntegrations.filter(integration => integration instanceof Tracker)
      );
      siteIntegrations
        .filter(integration => !(integration instanceof Tracker))
        .forEach(integration => integration.init());
    } catch (e) {
      console.error("Couldn't load site integrations!");
    }
  }
}

export default new IntegrationsLoader();
