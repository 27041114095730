import {version} from "src/core/version";
import useShop from "src/core/shops/hooks/useShop";
import usePages from "src/core/shops/hooks/usePages";
import {isClient} from "src/server/utils/isClient";
import {useSocialsResource} from "src/state/hooks/useAsyncResource";
import useSite from "src/core/sites/hooks/useSite";

export default function useFooter() {
  const [shop] = useShop();
  const site = useSite();
  const siteName = site.getPoweredByName();
  const {data: socials} = useSocialsResource();
  const {footerPages} = usePages();

  const licenseNumber = isClient ? shop.data?.getLicenseNumber() : "";
  const _version = version.replace("# ", "");

  return {
    site: site,
    socialLinks: socials?.socialLinks || [],
    version: _version,
    licenseNumber: licenseNumber,
    footerPages: footerPages,
    siteName: siteName,
  };
}
