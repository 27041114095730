import React from "react";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useSite from "src/core/sites/hooks/useSite";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import ExclusiveMobileContentAddon from "src/integrations/inStoreExperience/components/ExclusiveMobileContentModal";

const getCategoryBarPosition = theme => {
  switch (theme) {
    case "boost":
    case "colourful":
      return "top";
    case "medleaf":
    case "garden":
    case "sense":
    case "default":
    default:
      return "bottom";
  }
};

export default function useBasePage() {
  const toggles = useFeatureToggles();
  const site = useSite();
  const {showAdBanner} = useAdBanner();

  const themeName = site.getBaseTheme().name;
  const showAnnouncementMessage = site.getOptions().hasAnnouncementMessage();
  const showBanner = themeName !== "sense" && themeName !== "garden";
  const categoryBarPosition = getCategoryBarPosition(themeName);
  return {
    kioskMode: toggles.kioskMode(),
    showAnnouncementMessage,
    showBanner,
    showAdBanner,
    categoryBarPosition,
    addons: [<ExclusiveMobileContentAddon key="exclusive-mobile-content" />],
  };
}
