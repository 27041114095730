import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function PaymentDisclaimer({className, disclaimer, styles}) {
  return (
    <PaymentDisclaimerContainer className={className} styles={styles}>
      {disclaimer}
    </PaymentDisclaimerContainer>
  );
}

const PaymentDisclaimerContainer = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  font-size: 12px;
  text-align: justify;
`;

PaymentDisclaimer.defaultProps = {
  styles: {
    fontFamily: "sans-serif",
    fontWeight: 500,
    color: "#000",
  },
};
PaymentDisclaimer.propTypes = {
  disclaimer: PropTypes.string,
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
  }),
};

export default PaymentDisclaimer;
