import * as React from "react";
import PropTypes from "prop-types";

const WealthfrontBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#4840BB" />
      <path
        d="M19.917 18.8632L19.9164 18.826C19.9069 18.8105 19.8878 18.7908 19.8559 18.7657C16.8285 16.4992 17.2091 11.758 18.0689 7.3573C18.1034 7.18013 18.1267 7.06158 17.9732 7.04521C16.9951 6.9403 13.7361 7.42835 12.8006 7.6627C12.5573 7.72364 12.5354 7.78827 12.5313 7.92953C12.2751 16.7386 18.1739 18.5832 19.7768 18.8703C19.8132 18.8768 19.8931 18.8851 19.9171 18.8632H19.917Z"
        fill="white"
      />
      <path
        d="M11.4007 10.4028C10.1999 10.6175 9.08836 11.0367 8.12656 11.4132C8.042 11.4355 7.90695 11.5675 7.94195 11.7316C8.32144 13.5113 10.1717 18.5296 17.2447 18.9205C17.4073 18.9273 17.5716 18.8999 17.4435 18.8266C16.4896 18.1536 13.0975 17.4613 11.7104 10.6555C11.6962 10.5861 11.6981 10.3213 11.4007 10.4027V10.4028Z"
        fill="white"
      />
      <path
        d="M8.00685 14.5807C7.58545 14.8137 6.49896 15.468 6.1092 15.8051C6.03201 15.872 6.04695 15.9192 6.08092 16.033C6.39564 17.0866 8.25232 18.9335 12.8647 18.7903C13.0826 18.7835 13.0835 18.7223 12.9699 18.6449C12.8957 18.5946 11.4379 18.1649 10.1051 17.0389C9.43821 16.4756 8.75987 15.6198 8.30477 14.8062C8.19184 14.5842 8.13472 14.5101 8.00685 14.5808V14.5807Z"
        fill="white"
      />
    </svg>
  );
};

WealthfrontBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WealthfrontBankIcon;
