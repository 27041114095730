import React from "react";
import styled from "styled-components";
import {CloseOutline} from "@styled-icons/evaicons-outline/CloseOutline";
import {useForm} from "../../../components/FormContext";

function Checkbox({
  checked,
  failed,
  className,
  disabled,
  onClick,
  label,
  helperText,
  ...props
}) {
  const formContext = useForm();
  const _disabled = formContext.disabled || disabled;

  const sanitizeHelperText = () => ({
    __html: helperText,
  });

  return (
    <CheckboxContainer
      disabled={_disabled}
      onClick={_disabled ? undefined : onClick}
      data-cy={props["data-cy"]}
    >
      <CheckBoxSelection className={className} checked={checked} />
      <CheckboxText>
        <PrimaryText>{label}</PrimaryText>
        {helperText && <SecondaryText dangerouslySetInnerHTML={sanitizeHelperText()} />}
      </CheckboxText>
    </CheckboxContainer>
  );
}
export function CheckBoxSelection({checked, className}) {
  return (
    <CheckboxSelectionContainer className={className}>
      {checked && <CheckboxSelectionIndicator />}
    </CheckboxSelectionContainer>
  );
}
export function CheckBoxFailedSelection({checked}) {
  return (
    <CheckboxSelectionContainer>
      {checked && <CheckboxFailedIndicator />}
    </CheckboxSelectionContainer>
  );
}

const CheckboxText = styled.div`
  margin-left: 8px;
`;

export const PrimaryText = styled.p`
  width: fit-content;
  word-break: break-all;
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  color: #020202;
  margin: 0;
`;

const SecondaryText = styled.div`
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: #020202;

  & p {
    margin: 0;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};

  > ${PrimaryText} {
    margin-left: ${({theme}) => theme.v2.spacing(2)};
  }
`;
export const CheckboxSelectionContainer = styled.div`
  border: 1px solid #8d8d8d;
  box-sizing: border-box;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let CheckboxSelectionIndicator = styled.div`
  background-color: ${({theme, checkedMarkColor}) =>
    checkedMarkColor ? `${checkedMarkColor} !important` : theme.v2.color.primary};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  width: 16px;
  height: 16px;
`;

export const CheckboxFailedIndicator = styled(CloseOutline)`
  color: red;
  width: 28px;
  height: 28px;
`;

export default Checkbox;
