import React from "react";
import get from "lodash/get";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useCartState from "src/core/checkout/hooks/useCartState";
import useOrderState from "src/core/checkout/hooks/useOrderState";
import {makePayment} from "src/core/payments/factories/payment";
import usePromoCode from "src/core/checkout/hooks/usePromoCode";
import {DeliveryTypes, makeDeliveryType} from "src/core/common/models/DeliveryType";
import useRouter from "src/core/common/hooks/useRouter";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useCheckout from "src/core/checkout/hooks/useCheckout";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import useOnBoarding from "src/core/checkout/hooks/useOnBoarding";
import useSite from "src/core/sites/hooks/useSite";

export const CheckoutContext = React.createContext({});

export function CheckoutContextProvider({children}) {
  const router = useRouter();
  const toggles = useFeatureToggles();
  const [, authApi] = useAuthentication();
  const {createOrder, clearCart, loading, placingOrder} = useCheckout();
  const [orderState] = useOrderState();
  const [deliveryType] = useDeliveryType();
  const [deliveryAddress] = useDeliveryAddress();
  const [deliveryMode] = useDeliveryMode();
  const [cartState, CartActions] = useCartState();
  const [onBoarding, {clearOnBoardingData}] = useOnBoarding();
  const site = useSite();
  const [promoCodeParam] = usePromoCode();
  const paymentOption = makePayment(
    cartState.data ? cartState.data.getPaymentOption() : "cash"
  );

  const deliverySpec = cartState.data?.getDeliverySpecification();

  return (
    <CheckoutContext.Provider
      value={{
        router,
        toggles,
        site,
        auth: {api: authApi},
        payments: {
          paymentOption,
        },
        order: {
          create: createOrder,
          loading,
          actions: CartActions,
          state: orderState,
          clearCart,
          placingOrder,
        },
        cart: {
          loading: cartState.loading,
          hasCart: Boolean(cartState.data),
          currentCart: cartState.data,
          errors: get(cartState, "error.data.errors"),
          actions: CartActions,
        },
        deliveries: {
          type:
            deliverySpec?.getType() ||
            deliveryType ||
            makeDeliveryType(DeliveryTypes.UNAVAILABLE),
          address: deliverySpec?.getAddress() || deliveryAddress,
          mode: deliverySpec?.getMode()?.code || deliveryMode,
        },
        queryParams: {
          promoCode: promoCodeParam,
        },
        onBoarding: {data: onBoarding.data, clear: clearOnBoardingData},
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
