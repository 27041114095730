import {useMemo, useEffect} from "react";
import useQueryParams from "src/core/common/hooks/useQueryParams";
import {FilterParams} from "@menu/utils/constants";
import useProductCategoryPath from "src/menu/hooks/useProductCategory";
import split from "lodash/split";
import useRouter from "src/core/common/hooks/useRouter";
import useRange from "src/menu/hooks/useRange";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import useSite from "src/core/sites/hooks/useSite";
import get from "lodash/get";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";

export default function useAppliedFilters(onChangeFilter) {
  const [pricesRange] = useRange("priceRange");
  const [thcRange] = useRange("thcRange");
  const [cbdRange] = useRange("cbdRange");
  const [paramFilters, _setParamFilter] = useQueryParams(filterParams);
  const [category, , subcategory] = useProductCategoryPath();
  const router = useRouter();
  const {brand} = router.query;
  const site = useSite();

  const clearFilters = () => {
    const args = [filterParams, filterParams.map(() => false)];
    setFilter(...args);
    onChangeFilter && onChangeFilter(...args);
  };

  const clearAllFilters = () => {
    const allFilters = Object.keys(FilterParams).map(key => FilterParams[key]);
    const args = [allFilters, allFilters.map(() => false)];
    setFilter(...args);
    onChangeFilter && onChangeFilter(...args);
  };

  function setFilter(paramName, value) {
    let paramsToChange = [];
    let paramValues = [];
    if (Array.isArray(paramName)) {
      paramsToChange = [...paramsToChange, ...paramName];
      paramValues = [...paramValues, ...value];
    } else {
      paramsToChange = [...paramsToChange, paramName];
      paramValues = [...paramValues, value];
    }

    _setParamFilter(paramsToChange, paramValues);
    onChangeFilter && onChangeFilter(paramsToChange, paramValues);
  }

  const filters = useMemo(() => {
    let params = {
      ...paramFilters,
      category,
      subcategory,
    };
    if (brand) params.brand = brand;

    return new ProductFilters(params, pricesRange, thcRange, cbdRange);
  }, [paramFilters, pricesRange, thcRange, cbdRange, category, subcategory, brand]);

  const noBrandFilters = useMemo(() => {
    return ProductFilters.fromPrototype(filters, {brand: null});
  }, [filters]);

  const [catalog] = useFiltersCatalog({filters: filters.serialize()});
  const catalogWeights = get(catalog, "weights", []);

  function getWeightUnits(filters, catalogWeights) {
    const noUnitFilters = (filters || "").split(",").filter(name => name !== "");

    return noUnitFilters.map(value => {
      const weight = catalogWeights.find(obj => obj.getAmount() === parseFloat(value));
      return weight?.getLabel();
    });
  }

  const weights = useMemo(() => {
    return getWeightUnits(filters.weights, catalogWeights);
  }, [filters, catalogWeights]);

  useEffect(() => {
    setFilter(FilterParams.PRODUCT_SUB_CATEGORY, null);
  }, [category]);

  return [
    filters,
    setFilter,
    clearFilters,
    clearAllFilters,
    FilterParams,
    noBrandFilters,
    site,
    weights,
  ];
}

export class ProductFilters {
  static fromPrototype(prototype, otherFiltersObj) {
    return new ProductFilters(
      {...prototype.filtersObj, ...otherFiltersObj},
      prototype.pricesRange,
      prototype.thcRange,
      prototype.cbdRange
    );
  }

  constructor(filtersObj, pricesRange, thcRange, cbdRange) {
    this.filtersObj = filtersObj;
    this.pricesRange = pricesRange || null;
    this.thcRange = thcRange || null;
    this.cbdRange = cbdRange || null;
  }

  getCounters() {
    const typeFilterCounter = this.count(FilterParams.PRODUCT_TYPE);
    const brandFilterCounter = this.count(FilterParams.PRODUCT_BRANDS);
    const tagFilterCounter = this.count(FilterParams.PRODUCT_TAG);
    const weightFilterCounter = this.count(FilterParams.PRODUCT_WEIGHT);
    const priceFilterCounter = this.hasPriceFilter() ? 1 : 0;
    const thcPotencyFilterCounter = this.hasThcPotencyFilter() ? 1 : 0;
    const cbdPotencyFilterCounter = this.hasCbdPotencyFilter() ? 1 : 0;
    const onSaleFilterCounter = this.onSale ? 1 : 0;
    const total =
      cbdPotencyFilterCounter +
      thcPotencyFilterCounter +
      priceFilterCounter +
      typeFilterCounter +
      brandFilterCounter +
      tagFilterCounter +
      weightFilterCounter +
      onSaleFilterCounter;
    return {
      typeFilterCounter,
      brandFilterCounter,
      tagFilterCounter,
      weightFilterCounter,
      priceFilterCounter: 1,
      thcPotencyFilterCounter: 1,
      cbdPotencyFilterCounter: 1,
      onSaleFilterCounter,
      total,
    };
  }

  count(filterType) {
    return this.filtersObj[filterType]
      ? split(this.filtersObj[filterType], ",").length
      : 0;
  }

  hasPriceFilter() {
    return (
      this.filtersObj[FilterParams.PRODUCT_MIN_PRICE] ||
      this.filtersObj[FilterParams.PRODUCT_MAX_PRICE]
    );
  }

  hasThcPotencyFilter() {
    return (
      this.filtersObj[FilterParams.PRODUCT_MIN_THC_POTENCY] ||
      this.filtersObj[FilterParams.PRODUCT_MAX_THC_POTENCY]
    );
  }

  hasCbdPotencyFilter() {
    return (
      this.filtersObj[FilterParams.PRODUCT_MIN_CBD_POTENCY] ||
      this.filtersObj[FilterParams.PRODUCT_MAX_CBD_POTENCY]
    );
  }

  get prices() {
    const min = this.pricesRange ? this.pricesRange.getMin() : 0;
    const max = this.pricesRange ? this.pricesRange.getMax() : Infinity;
    return [
      Math.max(this.filtersObj[FilterParams.PRODUCT_MIN_PRICE] || min, min),
      Math.min(this.filtersObj[FilterParams.PRODUCT_MAX_PRICE] || max, max),
    ];
  }

  get pricesUnit() {
    return "$";
  }

  get potencyThc() {
    const min = this.thcRange ? this.thcRange.getMin() : 0;
    const max = this.thcRange ? this.thcRange.getMax() : Infinity;
    return [
      Math.max(this.filtersObj[FilterParams.PRODUCT_MIN_THC_POTENCY] || min, min),
      Math.min(this.filtersObj[FilterParams.PRODUCT_MAX_THC_POTENCY] || max, max),
    ];
  }

  get thcUnit() {
    return this.thcRange?.getUnit();
  }

  get potencyCbd() {
    const min = this.cbdRange ? this.cbdRange.getMin() : 0;
    const max = this.cbdRange ? this.cbdRange.getMax() : Infinity;
    return [
      Math.max(this.filtersObj[FilterParams.PRODUCT_MIN_CBD_POTENCY] || min, min),
      Math.min(this.filtersObj[FilterParams.PRODUCT_MAX_CBD_POTENCY] || max, max),
    ];
  }

  get cbdUnit() {
    return this.cbdRange?.getUnit();
  }

  get types() {
    return this.filtersObj[FilterParams.PRODUCT_TYPE] || "";
  }

  get brands() {
    return this.filtersObj[FilterParams.PRODUCT_BRANDS] || "";
  }

  get category() {
    return this.filtersObj.category;
  }

  get subcategory() {
    return this.filtersObj.subcategory;
  }

  get subcategories() {
    return this.filtersObj[FilterParams.PRODUCT_SUB_CATEGORY] || "";
  }

  get tags() {
    return this.filtersObj[FilterParams.PRODUCT_TAG] || "";
  }

  get weights() {
    return this.filtersObj[FilterParams.PRODUCT_WEIGHT] || "";
  }

  get onSale() {
    return this.filtersObj[FilterParams.PRODUCT_ON_SALE] === "true";
  }

  get search() {
    return this.filtersObj[FilterParams.PRODUCT_SEARCH] || "";
  }

  hasFilters() {
    return this.getCounters().total > 0;
  }

  serialize() {
    const filterParamKeys = Object.values(FilterParams);
    const serialized = Object.keys(this.filtersObj).reduce((acc, key) => {
      if (filterParamKeys.indexOf(key) === -1) return acc;

      let filter =
        typeof this.filtersObj[key] === "string"
          ? this.filtersObj[key].split(",")
          : this.filtersObj[key];

      return {
        ...acc,
        [key]: Array.isArray(filter) && filter.length === 1 ? filter[0] : filter,
      };
    }, {});
    serialized.q = this.filtersObj.q;

    if (serialized.subcategory || serialized[FilterParams.PRODUCT_SUB_CATEGORY]) {
      serialized.category =
        serialized.subcategory || serialized[FilterParams.PRODUCT_SUB_CATEGORY];
      delete serialized.subcategory;
    }

    return serialized;
  }

  equals(other) {
    const diff = Object.keys(this.filtersObj).find(key => {
      const filter1 = other.filtersObj[key];
      const filter2 = this.filtersObj[key];
      if (Array.isArray(filter1) && Array.isArray(filter2)) {
        if (filter1.length !== filter2.length) return true;

        return !!filter1.find(entry => !filter2.includes(entry));
      }
      return filter1 !== filter2;
    });
    return (
      Object.keys(this.filtersObj).length === Object.keys(other.filtersObj).length &&
      !diff
    );
  }

  merge(otherFilters) {
    const filtersObj =
      otherFilters instanceof ProductFilters
        ? otherFilters.filtersObj
        : otherFilters || {};
    return new ProductFilters(
      {...this.filtersObj, ...filtersObj},
      this.pricesRange,
      this.thcRange
    );
  }

  toString() {
    return JSON.stringify(pickBy(this.serialize(), identity));
  }
}

export const filterParams = Object.keys(FilterParams)
  .filter(key => key !== "CATEGORY" && key !== "SUBCATEGORY" && key !== "PRODUCT_SEARCH")
  .map(key => FilterParams[key]);
