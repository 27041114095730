import * as React from "react";
import PropTypes from "prop-types";

const ChaseBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#0079C2" />
      <path
        d="M10.3262 4.86108C10.1831 4.86105 10.0458 4.91786 9.94448 5.01903C9.8432 5.12021 9.78622 5.25747 9.78607 5.40065V9.18174H19.773L15.225 4.86226L10.3262 4.86108ZM20.1389 10.3256C20.1389 10.2547 20.125 10.1845 20.0979 10.1191C20.0707 10.0536 20.031 9.99417 19.9808 9.94412C19.9307 9.89407 19.8712 9.85442 19.8057 9.82744C19.7402 9.80046 19.67 9.78667 19.5991 9.78689H15.8194V19.7764L20.1371 15.2259L20.1389 10.3256ZM14.6742 20.139C14.8171 20.1386 14.954 20.0815 15.0549 19.9803C15.1558 19.8791 15.2124 19.742 15.2124 19.5991V15.8185H5.22592L9.77319 20.138L14.6742 20.139ZM4.86108 14.6751C4.86106 14.7459 4.875 14.8161 4.9021 14.8816C4.9292 14.9471 4.96893 15.0066 5.01901 15.0567C5.0691 15.1069 5.12857 15.1467 5.19403 15.1738C5.25948 15.2009 5.32964 15.2149 5.4005 15.2149H9.18051V5.2247L4.86167 9.774L4.86108 14.6751Z"
        fill="white"
      />
    </svg>
  );
};

ChaseBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ChaseBankIcon;
