import * as React from "react";
import PropTypes from "prop-types";

const RoundedWarningIcon = ({width, height}) => {
  return (
    <svg
      width={width || "22"}
      height={height || "19"}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 12H10V7H12M12 16H10V14H12M0 19H22L11 0L0 19Z" fill="#FFAE00" />
    </svg>
  );
};

RoundedWarningIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default RoundedWarningIcon;
