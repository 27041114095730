import React from "react";
import NewCreditCard from "@ui/components/CheckoutPaymentMethod/CreditCard/NewCreditCard";
import styled, {useTheme} from "styled-components";
import {getCreditCardFormProps} from "src/core/payments/utils";
import {useShop} from "src/core/shops";
import useSite from "src/core/sites/hooks/useSite";
import {
  getAdyenCreditCardFormProps,
  getAdyenFormProps,
} from "src/core/payments/adyen/styles";

function CardAndBillingStep() {
  const [, shop] = useShop();
  const shopCountry = shop.getShop()?.getCountry();

  const theme = useTheme();
  const site = useSite();

  const styles = getAdyenCreditCardFormProps(theme, site);
  const errorStyles = getAdyenFormProps(theme, site).error;

  return (
    <FormContainer>
      <NewCreditCard
        {...getCreditCardFormProps(shopCountry)}
        styles={{...styles, error: errorStyles}}
      />
    </FormContainer>
  );
}

const FormContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export default CardAndBillingStep;
