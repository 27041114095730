import * as React from "react";
import PropTypes from "prop-types";

const SpenceIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "24"}
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70788 0.0913639C9.5377 0.122305 9.01171 0.230598 8.5476 0.32342C8.08349 0.416243 7.10886 0.77206 6.38175 1.11241C5.29883 1.6384 4.83472 1.97875 3.75179 3.07714C3.02468 3.80425 2.20476 4.80982 1.91082 5.30487C1.12183 6.65079 0.379254 8.98682 0.162669 10.7814C-0.146738 13.4113 0.410194 16.3971 1.66329 18.8724C2.14287 19.8006 3.08657 21.2393 3.30315 21.3631C3.36503 21.394 6.69116 17.3563 10.698 12.3748L18 3.32467L17.3348 2.72132C15.2153 0.803001 11.9047 -0.341805 9.70788 0.0913639ZM6.86133 6.75909C7.15527 7.03756 7.12433 7.08397 5.74747 8.81665C4.97395 9.79128 4.30872 10.5957 4.24684 10.5803C3.99932 10.5493 3.59709 9.45093 3.59709 8.81665C3.59709 7.3779 4.66454 6.24857 5.90217 6.3878C6.27346 6.43421 6.70663 6.58891 6.86133 6.75909ZM7.24809 9.52828C7.95973 11.2146 6.79945 13.1329 5.15959 12.9472C4.7883 12.9008 4.35513 12.7461 4.20043 12.5914C3.92196 12.2975 3.95291 12.2511 5.34524 10.5648C6.11876 9.6211 6.81492 8.87853 6.89227 8.92494C6.96962 8.97135 7.12433 9.23435 7.24809 9.52828Z"
        fill={color || "white"}
      ></path>
      <path
        d="M7.38738 18.4393C3.47338 23.297 0.178195 27.3966 0.0699022 27.5513C-0.100272 27.7989 -0.00744955 27.9226 0.889831 28.6652C2.76174 30.2122 4.85024 31.0012 7.07797 31.0012C8.81065 31.0012 9.80076 30.7692 11.487 29.9802C12.5699 29.4542 13.0341 29.1138 14.117 28.0154C14.8441 27.2883 15.664 26.2828 15.958 25.7877C16.7469 24.4418 17.4895 22.1058 17.7061 20.3112C18.0929 16.9851 17.1492 13.3186 15.1535 10.4721C14.8132 9.99248 14.5347 9.59025 14.5192 9.59025C14.5037 9.59025 11.3014 13.5816 7.38738 18.4393Z"
        fill={color || "white"}
      ></path>
    </svg>
  );
};

SpenceIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SpenceIcon;
