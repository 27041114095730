import {PaymentOptions} from "src/core/payments/constants";
import Stronghold from "src/core/payments/stronghold/Stronghold";
import StrongholdPaymentForm from "src/core/payments/stronghold/StrongholdPaymentForm";
import {BankPaymentSource} from "src/core/payments/models/paymentSource";
import PaymentPromotionBanner from "src/core/payments/stronghold/PaymentPromotionBanner";
import SavingsIndicatorLabel from "src/core/payments/stronghold/SavingsIndicatorLabel";

export default {
  type: PaymentOptions.STRONGHOLD,
  PaymentClass: Stronghold,
  PaymentForm: StrongholdPaymentForm,
  PaymentSource: {
    class: BankPaymentSource,
    options: {
      retryLabel: "Add new account",
      displayConvenienceFee: false,
    },
  },
  Promotions: {
    BannerComponent: PaymentPromotionBanner,
    SavingsLabelComponent: SavingsIndicatorLabel,
  },
};
