import React from "react";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import CategoryBar from "@menu/components/CategoryBar";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import MedLeafBasePage from "./BasePage";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import AdBanner from "@themes/default/components/AdBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";

export default function MedLeafProductsPage({
  useThemedPromotionalBanner,
  themeProperties = {},
  ...props
}) {
  const toggles = useFeatureToggles();
  const {showAdBanner} = useAdBanner();

  return (
    <MedLeafBasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <CategoryBar />
      {!toggles.kioskMode() && <DeliveryBanner />}
      <CategorizedProductsBrowser />
    </MedLeafBasePage>
  );
}
