import {PaymentOptions} from "src/core/payments/constants";
import useCreditCardPaymentSources from "src/core/payments/hooks/useCreditCardPaymentSources";

function useAdyen({cart, order}) {
  const {paymentSources, loading, disabled, removeAccount, initialPaymentSource} =
    useCreditCardPaymentSources({
      cart,
      order,
      sourceType: PaymentOptions.ADYEN,
    });

  return {
    paymentSources,
    loading,
    disabled,
    removeAccount,
    initialPaymentSource,
  };
}

export default useAdyen;
