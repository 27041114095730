import React from "react";
import ChaseBankIcon from "src/core/payments/stronghold/bank-icons/ChaseBankIcon";
import BankOfAmericaIcon from "src/core/payments/stronghold/bank-icons/BankOfAmericaIcon";
import WellsFargoBankIcon from "src/core/payments/stronghold/bank-icons/WellsFargoBankIcon";
import CitiBankIcon from "src/core/payments/stronghold/bank-icons/CitiBankIcon";
import USBankIcon from "src/core/payments/stronghold/bank-icons/USBankIcon";
import CapitalOneBankIcon from "src/core/payments/stronghold/bank-icons/CapitalOneBankIcon";
import PNCBankIcon from "src/core/payments/stronghold/bank-icons/PNCBankIcon";
import USAABankIcon from "src/core/payments/stronghold/bank-icons/USAABankIcon";
import TDBankIcon from "src/core/payments/stronghold/bank-icons/TDBankIcon";
import SunTrustBankIcon from "src/core/payments/stronghold/bank-icons/SunTrustBankIcon";
import RegionsBankIcon from "src/core/payments/stronghold/bank-icons/RegionsBankIcon";
import NavyFederalCreditUnionBankIcon from "src/core/payments/stronghold/bank-icons/NavyFederalCreditUnionBankIcon";
import BBandTBankIcon from "src/core/payments/stronghold/bank-icons/BBandTBankIcon";
import FidelityBankIcon from "src/core/payments/stronghold/bank-icons/FidelityBankIcon";
import CitizensBankIcon from "src/core/payments/stronghold/bank-icons/CitizensBankIcon";
import HuntigtonBankIcon from "src/core/payments/stronghold/bank-icons/HuntigtonBankIcon";
import WealthfrontBankIcon from "src/core/payments/stronghold/bank-icons/WealthfrontBankIcon";
import BettermentBankIcon from "src/core/payments/stronghold/bank-icons/BettermentBankIcon";
import SchwabBankIcon from "src/core/payments/stronghold/bank-icons/SchwabBankIcon";

export default function BankIcon({bank, width = 30, height = 30}) {
  const bankComponents = {
    chase: ChaseBankIcon,
    bank_of_america: BankOfAmericaIcon,
    wells_fargo: WellsFargoBankIcon,
    citi: CitiBankIcon,
    "u.s._bank": USBankIcon,
    capital_one: CapitalOneBankIcon,
    pnc: PNCBankIcon,
    usaa: USAABankIcon,
    td_bank: TDBankIcon,
    "suntrust_-_online_banking": SunTrustBankIcon,
    regions_bank: RegionsBankIcon,
    navy_federal_credit_union: NavyFederalCreditUnionBankIcon,
    "bb&t_-_online_banking": BBandTBankIcon,
    fidelity: FidelityBankIcon,
    citizens_bank: CitizensBankIcon,
    huntington_bank: HuntigtonBankIcon,
    wealthfront: WealthfrontBankIcon,
    betterment: BettermentBankIcon,
    charles_schwab: SchwabBankIcon,
  };

  const Component = bankComponents[bank];

  if (!Component) {
    const styles = {
      height: height + "px",
      width: width + "px",
      backgroundColor: "#bbb",
      borderRadius: "50%",
      display: "inline-block",
    };
    return <div style={styles} />;
  }
  return <Component width={width} height={height} />;
}
