import {PaymentOptions} from "src/core/payments/constants";
import Swifter from "src/core/payments/swifter/Swifter";
import SwifterPaymentForm from "src/core/payments/swifter/SwifterForm";
import {BankPaymentSource} from "src/core/payments/models/paymentSource";

export default {
  type: PaymentOptions.SWIFTER,
  PaymentClass: Swifter,
  PaymentForm: SwifterPaymentForm,
  PaymentSource: {
    class: BankPaymentSource,
    options: {
      retryLabel: "Retry",
      displayConvenienceFee: true,
    },
  },
};
