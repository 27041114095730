import * as React from "react";
import PropTypes from "prop-types";

const HuntigtonBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#69B932" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2944 18.4061V6.62069L15.4447 5.54924V11.1328L10.3643 14.083V19.9385L12.2491 21.0302V15.0986L15.4447 13.2428V19.4698L17.2944 18.4061ZM12.2491 4L10.3643 5.08437V10.3587L12.2491 9.20357V4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9045 20.9316L14.7891 19.847V14.3815L12.9045 15.4759V20.9316ZM7.85922 6.52577V18.4875L9.70846 19.5587V13.7054L14.7891 10.7555V5.16945L12.9045 4.07751V9.57084L9.70846 11.5293V5.46139L7.85922 6.52577ZM17.9498 7.00039V18.0288L20 16.8488V8.18842L17.9498 7.00039ZM7.20362 6.90283L5 8.17062V16.831L7.20362 18.1078V6.90283Z"
        fill="white"
      />
    </svg>
  );
};

HuntigtonBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default HuntigtonBankIcon;
