import {PaymentOptions} from "src/core/payments/constants";
import MerrcoPaymentForm from "src/core/payments/merrco/MerrcoPaymentForm";
import Merrco from "src/core/payments/merrco/Merrco";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";

export default {
  type: PaymentOptions.MERRCO,
  PaymentClass: Merrco,
  PaymentForm: MerrcoPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
