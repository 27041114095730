import React from "react";
import useProductCategoryPath from "../hooks/useProductCategory";
import {useRefreshHook} from "src/core/common/hooks/useRefresh";
import useFiltersCatalog from "@menu/hooks/useFiltersCatalog";

const BaseCategoryBar = ({render}) => {
  const [catalog] = useFiltersCatalog();

  const [, goToProductCategory] = useProductCategoryPath();
  const _categories = catalog
    ? catalog.categories.filter(category => category.getParentCategoryId() === null)
    : [];
  return render({
    categories: _categories,
    onChangeCategory: goToProductCategory,
  });
};

export default function CategoryBarWrapper(props) {
  const [refreshState] = useRefreshHook();
  return <BaseCategoryBar key={refreshState} {...props} />;
}
