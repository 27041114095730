import {ActionTypes} from "../actions";
import uniqBy from "lodash/uniqBy";

const initialState = {
  groups: [],
  products: {},
  loading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_PRODUCT_GROUPS:
      return {
        error: null,
        groups: state.groups,
        products: state.products,
        loading: true,
      };
    case ActionTypes.REQUEST_PRODUCT_GROUPS_SUCCESSFUL:
      return {
        groups: action.payload.groups,
        products: state.products,
        error: null,
        loading: false,
      };
    case ActionTypes.REQUEST_PRODUCT_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ActionTypes.REQUEST_GROUP_PRODUCTS:
    case ActionTypes.REQUEST_GROUP_PRODUCTS_SUCCESSFUL:
    case ActionTypes.REQUEST_GROUP_PRODUCTS_FAILURE:
      if (!action.payload.slug) return state;

      const group = state.groups.find(group => group.slug === action.payload.slug);
      const newGroup = {
        ...(group || {
          slug: action.payload.slug,
        }),
      };

      const groupProducts = state.products[newGroup.slug];

      return {
        ...state,
        products: {
          ...state.products,
          [newGroup.slug]: groupReducer(
            {
              loading: true,
              data: groupProducts?.data || [],
              error: null,
            },
            action
          ),
        },
        groups: !group
          ? [...state.groups, newGroup]
          : state.groups.map(group => {
              if (group.slug === action.payload.slug) {
                return newGroup;
              } else {
                return group;
              }
            }),
      };
    default:
      return state;
  }
}

const initialGroupState = {
  data: [],
  loading: false,
  error: null,
  hasMore: true,
};

function groupReducer(state = initialGroupState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_GROUP_PRODUCTS:
      return {
        error: null,
        data: action.payload.options?.reset ? [] : state.data,
        loading: true,
        hasMore: true,
      };
    case ActionTypes.REQUEST_GROUP_PRODUCTS_SUCCESSFUL:
      const newData = [...state.data, ...action.payload.data.getElements()];
      return {
        loading: false,
        error: null,
        data: uniqBy(newData, product => product.getId()),
        hasMore: action.payload.data.getElements().length > 0,
      };
    case ActionTypes.REQUEST_PRODUCT_GROUPS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
}
