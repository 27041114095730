import PropTypes from "prop-types";
import * as React from "react";
import WheelIndica from "@ui/components/IconsSVGs/WheelIndica";
import WheelHybrid from "@ui/components/IconsSVGs/WheelHybrid";
import WheelSativa from "@ui/components/IconsSVGs/WheelSativa";
import WheelCBD from "@ui/components/IconsSVGs/WheelCBD";

export const WheelIcon = ({abbreviation}) => {
  switch (abbreviation) {
    case "I":
      return <WheelIndica />;
    case "H":
      return <WheelHybrid />;
    case "ID":
      return <WheelIndica />;
    case "S":
      return <WheelSativa />;
    case "SD":
      return <WheelSativa />;
    case "CBC":
      return <WheelCBD />;
    default:
      return <WheelIndica />;
  }
};

WheelIcon.propTypes = {
  abbreviation: PropTypes.string,
};
