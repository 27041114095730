import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import CategoryBar from "@menu/components/CategoryBar";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";

export default function SenseBasePage({children, ...props}) {
  const toggles = useFeatureToggles();
  return (
    <>
      <Header isSearchEnabled={true} />
      {!toggles.kioskMode() && <DeliveryBanner />}
      <CategoryBar />
      <Content>{children}</Content>
      <ProductDetailModal {...props} />
    </>
  );
}

const ProductDetailModal = dynamic(
  () =>
    import("src/core/pages/ProductDetail").catch(
      logModuleLoadError("ProductDetail", {critical: true})
    ),
  {
    ssr: true,
  }
);

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
