import useQueryParams from "src/core/common/hooks/useQueryParams";
import {filterParams} from "src/core/common/hooks/useAppliedFilters";
import useSite from "src/core/sites/hooks/useSite";
import {useEffect} from "react";
import useProductCategory from "src/menu/hooks/useProductCategory";

const PRODUCTS_SORT_PARAM = "order";

export function useProductsSortBy(replace = false) {
  const [params, setParam] = useQueryParams(filterParams, replace);

  function setOrder(order) {
    setParam([PRODUCTS_SORT_PARAM], [order]);
  }
  return [params[PRODUCTS_SORT_PARAM], setOrder];
}

export function useProductsOrderSelector(orderOptions) {
  const [currentOrder, setSortBy] = useProductsSortBy(true);
  const [category] = useProductCategory();
  const siteOptions = useSite().getOptions();
  const siteDefaultProductOrder = siteOptions.getDefaultProductOrder(category);
  const siteCategoryDefaultProductOrder =
    siteOptions.getCategoryDefaultProductOrder(category);

  const defaultCandidate = siteCategoryDefaultProductOrder || siteDefaultProductOrder;

  const defaultOrder =
    orderOptions.find(opt => opt.value === defaultCandidate) || orderOptions[0];

  function onChangeOrder(opt) {
    setSortBy(opt.value);
  }

  useEffect(() => {
    if (!currentOrder && defaultOrder?.value) {
      setSortBy(defaultOrder.value);
    }
    // eslint-disable-next-line
  }, [defaultOrder.value]);

  useEffect(() => {
    if (defaultCandidate) {
      setSortBy(defaultOrder.value);
    }
    // eslint-disable-next-line
  }, [defaultOrder.value]);

  return {onChangeOrder, currentOrder};
}
