import React, {useEffect, useState} from "react";
import {Field, Form} from "react-final-form";
import PasswordInput from "../../common/components/elements/input/PasswordInput";
import RowLayout from "../../common/components/elements/container/row";
import ColumnLayout from "../../common/components/elements/container/column";
import {FormContainer, FormHeader} from "../../common/components/collections/form/forms";
import useRecoverPassword from "src/core/authentication/hooks/useRecoverPassword";
import {PrimaryLoadingButton} from "../../common/components/elements/button/PrimaryButton";
import {composeValidators, emailValidator, required} from "../../common/validations";
import Error from "../../common/components/elements/Error";
import AuthErrors from "./AuthErrors";
import * as Notifications from "src/core/notifications";
import {ResendCodeLink} from "../components/components";
import PhoneConfirmationWarning from "./PhoneConfirmationWarning";
import {PhoneConfirmationCodeField} from "../../common/components/elements/input/PhoneConfirmationCodeField";
import Input from "src/core/common/components/elements/input/Input";
import {showErrorHighlight} from "src/core/common/utils";

function ResetPasswordForm({phoneNumber, email, requestEmail, onResetSuccessful}) {
  const [authState, authApi] = useRecoverPassword();
  const [requestedChange, setRequestedChange] = useState(false);

  let emailValidators = [emailValidator, required];

  function onSubmit(formValues) {
    if (!requestedChange) {
      authApi.resetPassword({
        code: formValues.code,
        phoneNumber: phoneNumber,
        password: formValues.password,
        email: formValues.email || email,
      });
      setRequestedChange(true);
    }
  }

  const {error, data} = authState;
  useEffect(() => {
    if (requestedChange && (data || error)) {
      setRequestedChange(false);
      !error && Notifications.success("Reset password successful.");
      !error && onResetSuccessful();
    }
  }, [error, data, requestedChange, onResetSuccessful]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={() => {}}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <FormHeader>Reset Password</FormHeader>
            {/* trick chrome into not auto completing */}
            <input style={{display: "none"}} />
            <PhoneConfirmationWarning phone={phoneNumber} />
            <AuthErrors />
            {(phoneNumber || email) && (
              <ColumnLayout>
                <RowLayout>
                  <Field
                    name="code"
                    validate={required}
                    render={({input, meta}) => (
                      <>
                        <PhoneConfirmationCodeField input={input} meta={meta} />
                        {meta.touched && <Error>{meta.error}</Error>}
                      </>
                    )}
                  />
                </RowLayout>
              </ColumnLayout>
            )}
            {requestEmail && (
              <ColumnLayout>
                <RowLayout>
                  <Field
                    name="email"
                    validate={composeValidators(...emailValidators)}
                    render={({input, meta}) => (
                      <>
                        <Input
                          {...input}
                          disabled={false}
                          hasErrors={showErrorHighlight(meta)}
                          placeholder={`Email`}
                          onChange={input.onChange}
                        />
                        {meta.touched && <Error>{meta.error}</Error>}
                      </>
                    )}
                  />
                </RowLayout>
              </ColumnLayout>
            )}
            <ColumnLayout>
              <RowLayout>
                <Field
                  name="password"
                  validate={required}
                  render={({input, meta}) => (
                    <>
                      <PasswordInput
                        {...input}
                        disableAutoComplete={true}
                        autoComplete="new-password"
                        hasErrors={showErrorHighlight(meta)}
                        placeholder={"Password"}
                      />
                      {meta.touched && <Error>{meta.error}</Error>}
                    </>
                  )}
                />
              </RowLayout>
            </ColumnLayout>

            {(phoneNumber || email) && (
              <ResendCodeLink email={email} phoneNumber={phoneNumber} />
            )}
            <PrimaryLoadingButton loading={authState.loading} label="Continue" />
          </FormContainer>
        </form>
      )}
    />
  );
}

export default ResetPasswordForm;
