import React from "react";
import {Error} from "@ui/components/FormFields/Error";
import styled from "styled-components";
import CheckoutOptionPicker from "@ui/components/CheckoutOptionPicker/CheckoutOptionPicker";
import media from "@ui/utils/media";
import RoundedCloseIcon from "@ui/components/IconsSVGs/RoundedCloseIcon";
import ChainLinksIcon from "@ui/components/IconsSVGs/ChainLinks";
import PropTypes from "prop-types";
import PaymentDisclaimer from "@ui/components/CheckoutPaymentMethod/PaymentDisclaimer";
import BankLabelComponent from "@ui/components/CheckoutPaymentMethod/BankLabelComponent";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

function PaymentSourceList({
  paymentSources,
  value,
  styles,
  disabled,
  loading,
  meta,
  onChange,
  onRemove,
  onNewPaymentSource,
  disclaimer,
  InstitutionIconComponent,
  emptyPlaceholderTitle,
  emptyPlaceholderSubtitle,
  addNewPaymentSourceLabel,
  LabelComponent = BankLabelComponent,
}) {
  return (
    <>
      {paymentSources && paymentSources.length > 0 && (
        <PaymentSourcesListContainer>
          <CheckoutOptionPicker
            data={paymentSources.map((pSource) => {
              return {
                selected: !!value && value.id === pSource.id,
                label: pSource.institutionName,
                LabelComponent: renderNodeOrComponent(LabelComponent, {
                  styles,
                  pSource,
                  InstitutionIconComponent,
                }),
                onClick: () => {
                  !disabled && onChange(pSource);
                },
                action: {
                  label: "remove",
                  type: "button",
                  ActionComponent: RoundedCloseIcon,
                  onClick: (event) => {
                    event.stopPropagation();
                    onRemove(pSource);
                  },
                },
              };
            })}
            styles={styles.optionPicker}
          />
        </PaymentSourcesListContainer>
      )}
      {paymentSources && paymentSources.length === 0 && (
        <EmptyPlaceholderContainer styles={styles.emptyPlaceholder}>
          <ChainLinksIcon />
          <EmptyPlaceholderTitle styles={styles.emptyPlaceholderTitle}>
            {emptyPlaceholderTitle}
          </EmptyPlaceholderTitle>
          <EmptyPlaceholderSubtitle styles={styles.emptyPlaceholderSubtitle}>
            {emptyPlaceholderSubtitle}
          </EmptyPlaceholderSubtitle>
        </EmptyPlaceholderContainer>
      )}
      <LinkAccountButton
        type="button"
        disabled={disabled || loading}
        onClick={onNewPaymentSource}
        data-cy="linkAccount"
        styles={styles.linkAccount}
      >
        {addNewPaymentSourceLabel}
      </LinkAccountButton>
      <CustomPaymentDisclaimer
        disclaimer={disclaimer}
        styles={styles.emptyPlaceholderTitle}
      />
      {meta.touched && <Error>{meta.error}</Error>}
    </>
  );
}

const CustomPaymentDisclaimer = styled(PaymentDisclaimer)`
  padding-top: 10px;
`;

const PaymentSourcesListContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const EmptyPlaceholderContainer = styled.div`
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyPlaceholderTitle = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  margin-top: ${({styles}) => styles.marginTop};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
  line-height: 140%;
`;

const EmptyPlaceholderSubtitle = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
  line-height: 140%;
`;

const LinkAccountButton = styled.button`
  background-color: ${({styles}) => styles.backgroundColor};
  width: 100%;
  text-align: ${({styles}) => styles.textAlign};
  text-decoration: ${({styles}) => styles.textDecoration};
  border: ${({styles}) => styles.border};
  border-top: none;
  box-shadow: none;
  padding: ${({styles}) => styles.padding};
  border-radius: ${({styles}) => styles.borderRadius};
  cursor: pointer;
`;

PaymentSourceList.defaultProps = {
  LabelComponent: BankLabelComponent,
  paymentSources: [],
  styles: {
    optionPicker: {},
    emptyPlaceholder: {
      color: "#ffffff",
      padding: "16px",
      border: "1px solid #000",
      borderRadius: "4px 4px 0 0",
    },
    emptyPlaceholderTitle: {
      fontFamily: "sans-serif",
      fontWeight: 500,
      color: "#000",
      marginTop: 0,
      fontSize: {
        sm: "14px",
        md: "14px",
        lg: "14px",
      },
    },
    emptyPlaceholderSubtitle: {
      fontFamily: "sans-serif",
      fontWeight: 500,
      color: "#000",
      fontSize: {
        sm: "14px",
        md: "14px",
        lg: "14px",
      },
    },
    linkAccount: {
      backgroundColor: "#fff",
      textAlign: "center",
      textDecoration: "underline",
      border: "1px solid black",
      padding: "16px",
      borderRadius: "0 0 4px 4px",
    },
  },
  disabled: false,
  loading: false,
  meta: {
    touched: false,
    error: undefined,
  },
  onChange: () => {},
  onRemove: () => {},
  onNewPaymentSource: () => {},
  disclaimer: "",
  InstitutionIconComponent: () => <></>,
};

PaymentSourceList.propTypes = {
  paymentSources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      institutionName: PropTypes.string,
      institution: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  styles: PropTypes.shape({
    optionPicker: CheckoutOptionPicker.propTypes.styles,
    emptyPlaceholder: PropTypes.shape({
      backgroundColor: PropTypes.string,
      padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    emptyPlaceholderTitle: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    emptyPlaceholderSubtitle: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    linkAccount: PropTypes.shape({
      backgroundColor: PropTypes.string,
      textAlign: PropTypes.string,
      textDecoration: PropTypes.string,
      border: PropTypes.string,
      padding: PropTypes.string,
      borderRadius: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onNewPaymentSource: PropTypes.func,
  disclaimer: PropTypes.string,
  InstitutionIconComponent: PropTypes.elementType,
  emptyPlaceholderTitle: PropTypes.string,
  emptyPlaceholderSubtitle: PropTypes.string,
  addNewPaymentSourceLabel: PropTypes.string,
  LabelComponent: PropTypes.elementType,
};

export default PaymentSourceList;
