import {sanitizeFilters} from "src/core/common/utils";
import Product from "src/core/common/models/product";
import {ProductFilters} from "src/core/common/hooks/useAppliedFilters";

export function getInitialGroups({prefetchedGroups, currentParams, currentGroups}) {
  if (!prefetchedGroups?.data) return currentGroups;

  const prefetchedFilters = new ProductFilters(sanitizeFilters(prefetchedGroups.params));
  const currentFilters = new ProductFilters(sanitizeFilters(currentParams));

  if (prefetchedFilters.equals(currentFilters)) return mapGroups(prefetchedGroups);

  return currentGroups;
}

function mapGroups(groups) {
  return {
    groups: groups.data.map(group => ({
      ...group,
      filters: new ProductFilters(group.filters),
      prefetched: true,
    })),
    products: groups.data.reduce((acc, group) => {
      return {
        ...acc,
        [group.slug]: {
          data: group.products?.data?.objects?.map(p => new Product(p)) || [],
          loading: false,
          error: null,
          prefetched: true,
        },
      };
    }, {}),
    loading: false,
    error: null,
    prefetched: true,
  };
}
