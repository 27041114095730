const getProductsState = state => state.search.products;
const getCategoriesState = state => state.search.categories;
const getBrandsState = state => state.search.brands;

const getProductsOffset = state => {
  const productsState = getProductsState(state);
  return productsState.offset;
};

const getCategoriesOffset = state => {
  const categoriesState = getCategoriesState(state);
  return categoriesState.offset;
};

const getBrandsOffset = state => {
  const brandsState = getBrandsState(state);
  return brandsState.offset;
};

const getSearchTerm = state => {
  return state.search.searchTerm;
};

const getProductGroups = state => {
  return state.productGroups;
};

const getGroupProductsBySlug = (state, {slug}) => {
  return state.productGroups.products[slug];
};

const getProductsListState = state => state.productsList;

export {
  getSearchTerm,
  getProductsState,
  getCategoriesState,
  getBrandsState,
  getProductsOffset,
  getCategoriesOffset,
  getBrandsOffset,
  getProductGroups,
  getGroupProductsBySlug,
  getProductsListState,
};
