import {isAndroid, isIOS} from "src/core/common/utils";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";

export default function useMobileAppDownload() {
  const options = useSiteOptions();

  const appStoreUrl = options.appStoreUrl();
  const playStoreUrl = options.googlePlayUrl();

  const appUrl = () => {
    if (isIOS()) {
      return appStoreUrl;
    }
    if (isAndroid()) {
      return playStoreUrl;
    }
    return null;
  };

  const getDownloadAppUrl = () => {
    if (
      (isIOS() && options.showDownloadIOSAppAfterCheckout() && appStoreUrl) ||
      (isAndroid() && options.showDownloadAndroidAppAfterCheckout() && playStoreUrl)
    ) {
      return appUrl();
    }

    return null;
  };

  const showMobileAppDownloadPrompt = () => {
    if (typeof window === "undefined" || typeof navigator === "undefined") {
      return "hidden";
    }

    if (isIOS()) {
      return options.showIOSMobileAppDownloadPrompt();
    } else if (isAndroid()) {
      return options.showAndroidMobileAppDownloadPrompt();
    }
    return "hidden";
  };

  return {getDownloadAppUrl, showMobileAppDownloadPrompt, appUrl};
}
