import {useEffect, useState} from "react";
import store from "../state/store";
import Actions from "../actions";

function useOnBoarding() {
  const [state, setState] = useState(store.getState());

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [
    Actions.getOnBoarding(state),
    {
      setOnBoardingData: values => {
        const {payment, ...otherValues} = values; // filter payment data
        Actions.setOnBoardingData(otherValues);
      },
      clearOnBoardingData: clearOnBoardingData,
    },
  ];
}

function clearOnBoardingData() {
  Actions.setOnBoardingData(undefined);
}

export default useOnBoarding;
