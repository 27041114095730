import React, {useEffect, useState} from "react";
import LoginForm from "./LoginForm";
import AuthenticationModalService from "./authenticationModalService";
import useModalService from "../../common/hooks/useModalService";
import ResetPasswordForm from "./ResetPasswordForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import {ModalBackButton} from "../../common/components/modules/modal/modalComponents";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useRouter from "../../common/hooks/useRouter";
import routes from "../../common/routes";
import Modal from "../../common/components/modules/modal/Modal";
import useDeliveryAddress from "../../deliveries/hooks/useDeliveryAddress";
import useFeatureToggles from "../../common/hooks/useFeatureToggles";

export const LOGIN_MODE = "login";
export const FORGOT_PASSWORD_MODE = "forgot-password";
export const RESET_PASSWORD_MODE = "reset-password";

function AuthenticationModal({
  isOpen,
  closeModal = () => {},
  openModal,
  initialValues,
  initialMode,
  onAuthenticationSuccessful,
}) {
  const router = useRouter();
  const [_initialValues, setInitialValues] = useState(initialValues);
  const [mode, setMode] = useState(initialMode);
  const [, authApi] = useAuthentication();
  const [address] = useDeliveryAddress();
  const toggles = useFeatureToggles();

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  function _openModal({initialMode, initialValues}) {
    if (initialMode) setMode(initialMode);
    if (initialValues) setInitialValues(initialValues);
    openModal && openModal();
  }
  useModalService(AuthenticationModalService, _openModal);

  function switchToLogin() {
    router.push(routes.login);
    authApi.resetErrors();
  }
  function switchToSignUp(evt) {
    evt.preventDefault();
    router.push(routes.signup);
    authApi.resetErrors();
  }
  function switchToForgotPassword() {
    router.push(routes.forgotPassword);
    authApi.resetErrors();
  }
  function switchToResetPassword({phoneNumber, email, requestEmail = false}) {
    router.push({
      pathname: routes.resetPassword,
      query: {phone: phoneNumber, email, requestEmail},
    });
    authApi.resetErrors();
  }

  function isAllowedToClose() {
    return Boolean(address) || !toggles.deliveriesOnly();
  }

  function close() {
    if (isAllowedToClose()) {
      closeModal();
    } else {
      router.push(routes.deliveryCheck);
    }
    authApi.resetErrors();
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      {mode === LOGIN_MODE && (
        <>
          <LoginForm
            initialValues={_initialValues}
            onLoginSuccessful={onAuthenticationSuccessful}
            switchToSignUp={switchToSignUp}
            switchToForgotPassword={switchToForgotPassword}
          />
        </>
      )}

      {mode === FORGOT_PASSWORD_MODE && (
        <>
          <ModalBackButton onClick={switchToLogin} />
          <ForgotPasswordForm
            initialValues={_initialValues}
            switchToLogin={switchToLogin}
            switchToResetPassword={switchToResetPassword}
          />
        </>
      )}

      {mode === RESET_PASSWORD_MODE && (
        <>
          <ModalBackButton onClick={switchToLogin} />
          <ResetPasswordForm
            initialValues={_initialValues}
            requestEmail={router.query.requestEmail === "true"}
            phoneNumber={router.query.phone}
            email={router.query.email}
            onResetSuccessful={onAuthenticationSuccessful}
          />
        </>
      )}
    </Modal>
  );
}

export default AuthenticationModal;
