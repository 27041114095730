import Moneris from "src/core/payments/moneris/Moneris";
import {PaymentOptions} from "src/core/payments/constants";
import MonerisPaymentForm from "src/core/payments/moneris/MonerisPaymentForm";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";

export default {
  type: PaymentOptions.MONERIS,
  PaymentClass: Moneris,
  PaymentForm: MonerisPaymentForm,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
