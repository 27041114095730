import usePaymentSources from "src/core/payments/hooks/usePaymentSources";
import {useForm} from "react-final-form";
import {useEffect, useMemo} from "react";
import {makePayment} from "src/core/payments/factories/payment";

function useCreditCardPaymentSources({cart, order, sourceType}) {
  const paymentOption = useMemo(() => makePayment(sourceType), []);

  const {paymentSources, meta, refresh} = usePaymentSources({
    sourceType,
  });

  useEffect(() => {
    return paymentOption.subscribe(({event}) => {
      const refreshEvents = [
        "removed-account-success",
        "changed-default-account-success",
      ];

      if (refreshEvents.indexOf(event) > -1) refresh();
    });
  }, [paymentOption, refresh]);

  const initialPaymentSource = useMemo(
    () =>
      Array.isArray(paymentSources) &&
      (paymentSources.find(pSource => pSource.isDefault) || paymentSources[0]),
    [paymentSources]
  );

  const form = useForm();
  const disabled = order.loading || (cart?.hasCart && cart.loading);
  const loading = meta.loading;

  async function removeAccount(paymentSource) {
    if (loading || disabled) return;
    const currentPaymentSource = form.getState().values.payment?.source;
    if (currentPaymentSource && currentPaymentSource.id === paymentSource.id) {
      form.mutators.setValue("payment.source", undefined);
    }

    await paymentOption.removeAccount(paymentSource);
  }

  return {
    paymentOption,
    paymentSources,
    removeAccount,
    initialPaymentSource,
    loading,
    disabled,
    refresh,
  };
}

export default useCreditCardPaymentSources;
