import React from "react";
import Checkbox from "@ui/components/Checkbox";
import Separator from "@ui/components/Separator/Separator";
import FormFields from "@ui/components/FormFields/FormFields";
import styled, {useTheme} from "styled-components";
import media from "@ui/utils/media";
import {required} from "src/core/common/validations";
import {Error} from "@ui/components/FormFields/Error";
import {Field, useFormState} from "react-final-form";
import {FieldContainer} from "src/core/common/components/collections/form/forms";
import useSite from "src/core/sites/hooks/useSite";
import {
  getLedgerGreenAgreementFormProps,
  getLedgerGreenFormProps,
} from "src/core/payments/ledgerGreen/styles";

function AgreementStep() {
  const formValues = useFormState().values;
  const theme = useTheme();
  const site = useSite();

  const styles = getLedgerGreenAgreementFormProps(theme, site);

  const formStyles = getLedgerGreenFormProps(theme, site);

  return (
    <Container>
      <ConsentSection>
        <ConsentFieldContainer>
          <Field
            name={"consent"}
            validate={required}
            render={({input, meta}) => (
              <FieldContainer>
                <div style={{display: "flex", alignItems: "center"}}>
                  <Checkbox
                    checked={input.value}
                    onClick={() => input.onChange(!input.value)}
                  />
                  <Label
                    htmlFor={"consent"}
                    disabled={false}
                    onClick={() => input.onChange(!input.value)}
                    styles={{
                      fontFamily: styles.text.fontFamily,
                      fontSize: styles.text.fontSize,
                      padding: {},
                    }}
                  >
                    {formValues.agreement?.iAgree}
                  </Label>
                </div>
                {meta.touched && meta.error && (
                  <Error styles={formStyles.error}>{meta.error}</Error>
                )}
              </FieldContainer>
            )}
          />
        </ConsentFieldContainer>
        <ConsentDescription styles={styles.text}>
          {formValues.agreement?.agreementText}
        </ConsentDescription>
      </ConsentSection>
      <Separator />
      <FormContainer>
        <span
          style={{
            fontSize: styles.text.fontSize.lg,
            fontFamily: styles.text.fontFamily,
            color: "#616161",
          }}
        >
          Please sign below to confirm
        </span>
        <FormFields {...getProps(styles)} styles={{...styles, error: formStyles.error}} />
      </FormContainer>
    </Container>
  );
}

const getProps = styles => ({
  fields: [
    {
      type: "signature",
      validate: required,
      name: "signature",
      styles: {
        root: styles.signature,
        clearButton: styles.text,
      },
      width: {
        sm: 12,
        md: 12,
        lg: 12,
      },
    },
  ],
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "sans-serif";
`;
const ConsentSection = styled.div`
  padding: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const ConsentFieldContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.span`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;
const ConsentDescription = styled.div`
  padding: 16px;
  font-size: ${({styles}) => styles.fontSize.lg};
  font-family: ${({styles}) => styles.fontFamily};
  background-color: #f5f5f5;
  width: 100%;
  box-sizing: border-box;
  color: #616161;
`;
const FormContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
export default AgreementStep;
