import React from "react";
import useStrongholdPromotionBanner from "src/core/payments/stronghold/useStrongholdPromotionBanner";
import MoneyIcon from "src/core/payments/components/MoneyIcon";
import styled, {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import {getStrongholdPromotionBannerProps} from "src/core/payments/stronghold/styles";

export default function PaymentPromotionBanner() {
  const bannerInfo = useStrongholdPromotionBanner();
  const theme = useTheme();
  const site = useSite();
  const styles = getStrongholdPromotionBannerProps(theme, site);

  if (!bannerInfo?.title && !bannerInfo?.description) return null;

  return (
    <PaymentPromotionBannerContainer>
      <MoneyIcon color={"#ffffff"} />
      <PaymentPromotionTextContainer>
        {bannerInfo.title && <Text styles={styles.title}>{bannerInfo.title}</Text>}
        {bannerInfo.description && (
          <Text styles={styles.description}>{bannerInfo.description}</Text>
        )}
      </PaymentPromotionTextContainer>
    </PaymentPromotionBannerContainer>
  );
}

const PaymentPromotionBannerContainer = styled.div`
  display: flex;
  gap: 12px;
  padding: 18px;
  background-color: #083eb0;
  color: #fff;
`;

const PaymentPromotionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Text = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize};
  font-weight: ${({styles}) => styles.fontWeight};
`;
