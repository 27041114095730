import {makeThemeProps} from "src/themes/utils/utils";

export const getLedgerGreenFormProps = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getLedgerGreenFormProps(theme);
};

export const getLedgerGreenMultiStepFormProps = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getLedgerGreenMultiStepFormProps(theme);
};

export const getLedgerGreenCreditCardFormProps = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getLedgerGreenCreditCardFormProps(theme);
};

export const getLedgerGreenAgreementFormProps = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getLedgerGreenAgreementFormProps(theme);
};
