import * as React from "react";
import PropTypes from "prop-types";

const SunTrustBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <path
        d="M3.19426 13.5535L3.08265 13.8631C2.9871 14.1222 2.9741 14.5855 3.0452 14.8324L3.61776 17.7923C3.66286 18.0491 3.74389 18.1562 3.82951 18.1409C3.92277 18.1172 3.91283 17.9222 3.85856 17.4758L3.42665 13.5527C3.39607 13.3058 3.28752 13.3012 3.19426 13.5535Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M4.40744 16.6633C4.41814 16.9186 4.44566 17.2244 4.35852 17.2466C4.27749 17.2634 4.2278 17.164 4.18575 16.8345L4 15.087C3.96712 14.8363 4.00993 14.3058 4.10319 14.12C4.19798 13.9343 4.28513 13.9817 4.29507 14.2309L4.40744 16.6633Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M5.18568 10.7824L4.96246 15.8743C4.95864 16.1059 4.94335 16.401 4.85697 16.3811C4.80881 16.3696 4.75071 16.2351 4.74613 16.0027L4.64828 11.8228C4.64216 11.6148 4.72166 11.2716 4.83327 11.0637L4.98692 10.7747C5.09853 10.5722 5.20326 10.5752 5.18568 10.7824Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M5.95018 11.8235L5.60389 15.7069C5.56873 15.9813 5.52822 16.2634 5.44719 16.2595C5.39215 16.258 5.34399 16.0868 5.37151 15.8299L5.51522 12.4962C5.53968 12.2554 5.70174 11.8938 5.80494 11.7088C5.90967 11.5284 5.98841 11.611 5.95018 11.8235Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M6.08139 15.4317C6.01488 15.4233 6.01182 15.1481 6.03017 14.9609L6.57445 9.5861C6.60426 9.38735 6.74874 9.08157 6.89245 8.90499L6.94444 8.84077C7.0935 8.66113 7.20282 8.67795 7.1623 8.87135L6.24498 14.967C6.19758 15.2299 6.16318 15.4317 6.08139 15.4317Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M7.70344 9.76123C7.82346 9.62516 8.02221 9.70237 7.9794 9.89501L6.78918 15.3248C6.74637 15.519 6.66151 15.8186 6.57284 15.8087C6.49945 15.8033 6.50481 15.5182 6.55755 15.2873L7.41372 10.3774C7.45576 10.1794 7.58113 9.89424 7.70344 9.76123Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M7.82529 15.262C7.73891 15.4493 7.63265 15.7184 7.51187 15.6671C7.42472 15.6327 7.59978 15.2085 7.59978 15.2085L10.5513 8.00977C10.6461 7.80031 10.857 7.532 11.0871 7.64437C11.2928 7.74375 11.0719 8.1489 10.9908 8.3316C9.20128 12.3548 8.6914 13.3937 7.82529 15.262Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M8.48882 14.9073C8.55915 14.9608 8.72503 14.7636 8.85422 14.5396L14.2573 5.53987C14.3528 5.37781 14.4079 5.11714 14.2137 5.02005C14.0623 4.94437 13.8009 5.09191 13.6771 5.3113L8.65546 14.3768C8.50793 14.6497 8.42078 14.853 8.48882 14.9073Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M8.61346 15.8774C8.54313 15.8079 8.66315 15.5854 8.80763 15.3614L14.5776 6.98702C14.7335 6.75004 15.0775 6.48784 15.2885 6.61015C15.4796 6.721 15.4827 6.9595 15.3214 7.18348L9.0232 15.5204C8.86725 15.7452 8.68685 15.9516 8.61346 15.8774Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M10.3205 14.9066C10.3205 14.9066 9.93902 15.3056 9.85952 15.2162C9.77696 15.1298 9.93215 14.9624 10.1003 14.7476L10.2891 14.5198L18.3524 5.27159C18.4824 5.11259 18.6597 5.10495 18.8302 5.23108L19.074 5.5315C19.2071 5.68286 19.1979 5.94506 19.0511 6.09183L10.3205 14.9066Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M9.66674 16.3896C9.60406 16.3269 9.74395 16.1404 10 15.9149L18.561 8.04806C18.7146 7.88982 18.9294 7.89517 19.0678 8.02818C19.2474 8.19942 19.3086 8.35842 19.3376 8.50978C19.3858 8.75822 19.2474 8.8683 19.0716 9.02577L10.1552 16.1251C9.93506 16.3193 9.73936 16.4569 9.66674 16.3896Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M11.441 15.6855L21.4016 8.5671C21.592 8.41727 21.7655 8.45167 21.8137 8.63972L22.0445 9.43703C22.0973 9.63425 21.9581 9.89798 21.7548 10.0409L11.6122 15.9179C11.363 16.0769 11.1039 16.2168 11.0397 16.1289C10.9609 16.0196 11.2117 15.8721 11.441 15.6855Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M10.3807 17.1799C10.3509 17.0637 10.6139 16.9552 10.8921 16.8122L19.8055 12.3181C20.047 12.1859 20.2374 12.2531 20.2359 12.4618L20.2764 13.4518C20.2725 13.6681 20.0447 13.9341 19.768 14.045L10.9823 17.102C10.6743 17.2212 10.4067 17.2908 10.3807 17.1799Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M20.8261 16.3276L12.2193 17.5591C11.8821 17.6111 11.6345 17.6654 11.6085 17.5668C11.5794 17.4475 11.8669 17.3749 12.1818 17.2687L21.1112 14.5572C21.3986 14.4616 21.6241 14.5664 21.5974 14.7888L21.4705 15.8109C21.443 16.0463 21.1563 16.2802 20.8261 16.3276Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M11.0337 18.3948C11.01 18.2679 11.438 18.196 11.769 18.1616L19.3813 17.2688C19.7215 17.2344 20.0005 17.4155 19.8897 17.7106L19.7352 18.1119C19.6443 18.3688 19.3645 18.5477 19.0113 18.524L11.7148 18.527C11.3952 18.5316 11.0581 18.5217 11.0337 18.3948Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M9.98026 7.10631C9.98026 7.10631 7.77563 13.88 7.64873 14.2446C7.51343 14.6353 7.38194 14.9831 7.26651 14.9533C7.19236 14.9311 7.25046 14.6826 7.33302 14.383L9.41993 7.03369C9.51931 6.73709 9.82967 6.481 9.95122 6.50547C10.1706 6.54751 10.0445 6.91903 9.98026 7.10631Z"
        fill="url(#paint15_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="2.84033"
          y1="16.6287"
          x2="12.7927"
          y2="4.76796"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3.17326"
          y1="16.9409"
          x2="13.0889"
          y2="5.12391"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="2.53545"
          y1="16.4279"
          x2="12.4149"
          y2="4.65404"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="3.13866"
          y1="16.9564"
          x2="12.9939"
          y2="5.21136"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="2.74424"
          y1="16.643"
          x2="12.5778"
          y2="4.92372"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="3.4242"
          y1="17.2291"
          x2="13.2328"
          y2="5.5397"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="4.06877"
          y1="17.7732"
          x2="13.8078"
          y2="6.16656"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="4.43417"
          y1="18.0822"
          x2="14.1409"
          y2="6.51422"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="5.3935"
          y1="18.8937"
          x2="15.0709"
          y2="7.36075"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="6.60853"
          y1="19.9149"
          x2="16.256"
          y2="8.41756"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="7.59076"
          y1="20.7436"
          x2="17.2062"
          y2="9.28438"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="8.97073"
          y1="21.9052"
          x2="18.5538"
          y2="10.4845"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="9.48734"
          y1="22.3277"
          x2="19.0212"
          y2="10.9656"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="10.8654"
          y1="23.4796"
          x2="20.3376"
          y2="12.1911"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="10.957"
          y1="23.5431"
          x2="20.3512"
          y2="12.3475"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="3.23273"
          y1="17.0707"
          x2="13.0074"
          y2="5.42173"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3427" stopColor="#FDB913" />
          <stop offset="0.3656" stopColor="#FBB415" />
          <stop offset="0.5519" stopColor="#EF941B" />
          <stop offset="0.7247" stopColor="#E8801D" />
          <stop offset="0.8776" stopColor="#E4741E" />
          <stop offset="0.9944" stopColor="#E36F1E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

SunTrustBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SunTrustBankIcon;
