import {makePayment} from "src/core/payments/factories/payment";
import {useEffect, useState} from "react";
import {PaymentOptions} from "src/core/payments/constants";

export default function useStrongholdPromotionBanner() {
  const [bannerInfo, setBannerInfo] = useState({title: "", description: ""});

  useEffect(() => {
    const stronghold = makePayment(PaymentOptions.STRONGHOLD);
    stronghold.getPromotions().then(promotionsPage => {
      const promotions = promotionsPage.getElements();
      if (promotions.length) {
        setBannerInfo(promotions[0]);
      }
    });
  }, []);

  return bannerInfo;
}
