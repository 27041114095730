import {useCallback} from "react";
import routes from "../../common/routes";
import useRouter from "../../common/hooks/useRouter";

function useOrderPath(path = routes.orderDetail) {
  const router = useRouter();
  const {orderId} = router.query;

  const goToOrder = useCallback(
    (orderId, replace = false) => {
      const url = {pathname: routes.orderDetail, params: {orderId}};
      replace ? router.replace(url) : router.push(url);
    },
    [router]
  );

  return [orderId, goToOrder];
}

export default useOrderPath;
