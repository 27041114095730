import React from "react";
import {Field} from "react-final-form";
import {required} from "src/core/common/validations";
import styled, {useTheme} from "styled-components";
import Error from "src/core/common/components/elements/Error";
import useSite from "src/core/sites/hooks/useSite";
import {getAdyenFormProps} from "./styles";
import {CreditCardPanel} from "@ui/components";
import Skeleton from "react-loading-skeleton";
import {paymentSourcesToCreditCards} from "src/core/payments/utils";
import useAdyen from "src/core/payments/adyen/useAdyen";
import AdyenNewCardForm from "src/core/payments/adyen/AdyenNewCardForm";

function AdyenPaymentForm({cart, order}) {
  const {loading, disabled, paymentSources, initialPaymentSource, removeAccount} =
    useAdyen({cart, order});

  const theme = useTheme();
  const site = useSite();

  const styles = getAdyenFormProps(theme, site);

  const validatePaymentSource = (value, allValues) => {
    if (allValues.payment?.cardNumber) return undefined;

    return required(value);
  };
  return (
    <Container styles={styles.container}>
      {loading ? (
        <Skeleton height={50} count={3} />
      ) : (
        <Field
          validate={validatePaymentSource}
          initialValue={initialPaymentSource}
          name="payment.source"
          render={({input, meta}) => (
            <>
              <CreditCardPanel
                NewCreditCardFormComponent={
                  <AdyenNewCardForm cart={cart} order={order} />
                }
                disabled={disabled}
                creditCards={paymentSourcesToCreditCards({
                  paymentSources,
                  input,
                  disabled,
                  removeAccount,
                })}
                onAddNewCard={() => input.onChange(undefined)}
                styles={styles.creditCardPanel}
              />
              {meta.touched && <Error>{meta.error}</Error>}
            </>
          )}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: ${({styles}) => styles.padding};
`;

export default AdyenPaymentForm;
