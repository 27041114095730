import React, {useMemo} from "react";
import {useTheme} from "styled-components";
import {
  CartIconActions,
  HorizontalProductCard,
  ProductCard,
  ResponsiveProductCard,
  SaleIndicator,
} from "@ui";
import useProductCard from "src/core/common/hooks/useProductCard";
import styles from "src/themes/baseTheme/components/ProductCard/styles";
import useSite from "src/core/sites/hooks/useSite";
import {getFlowerTypeIndicatorProps} from "src/themes/baseTheme/elements/flowerType";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";

export default function BaseThemeProductCard({product, ...props}) {
  const site = useSite();
  const {actionProps, cartProps, ...productCardProps} = useProductCard({product});
  const theme = useTheme();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);

  const DesktopProductCard = useMemo(
    () => (
      <div>
        <ProductCard
          {...props}
          {...productCardProps}
          product={product}
          styles={styles(theme, site, {mode: props.mode})}
          potencyTagsPosition="bottom"
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          FlowerTypeIndicatorComponent={FlowerTypeIndicator}
          ActionsComponent={props => <CartIconActions {...props} {...actionProps} />}
          SaleIndicatorComponent={props =>
            productCardProps.onSale && <SaleIndicator {...props} position={"left"} />
          }
        />
      </div>
      // eslint-disable-next-line
    ),
    [props, productCardProps, product, theme, site, flowerTypeIndicatorProps, actionProps]
  );

  const MobileProductCard = useMemo(
    () => (
      <div>
        <HorizontalProductCard
          {...props}
          {...productCardProps}
          styles={styles(theme, site, {isHorizontalInMobile: true})}
          showSizes
          potencyTagsPosition="bottom"
          FlowerTypeIndicatorComponent={FlowerTypeIndicator}
          flowerTypeIndicatorProps={flowerTypeIndicatorProps}
          ActionsComponent={props => <CartIconActions {...actionProps} {...props} />}
          SaleIndicatorComponent={props =>
            productCardProps.onSale && <SaleIndicator {...props} position={"right"} />
          }
        />
      </div>
      // eslint-disable-next-line
    ),
    [props, productCardProps, theme, site, flowerTypeIndicatorProps, actionProps]
  );

  return (
    <ResponsiveProductCard
      DesktopProductCard={DesktopProductCard}
      TabletProductCard={DesktopProductCard}
      MobileProductCard={props.mode === "small" ? DesktopProductCard : MobileProductCard}
    />
  );
}
