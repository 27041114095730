import * as React from "react";
import PropTypes from "prop-types";

const PNCBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.48 4.00002C11.3657 3.9976 10.2619 4.21529 9.23198 4.6406C8.20205 5.06591 7.26627 5.69046 6.47835 6.47839C5.69043 7.26633 5.06589 8.20213 4.64059 9.23208C4.21529 10.262 3.9976 11.3658 4.00002 12.4801C4.00002 17.1783 7.78254 21 12.48 21C17.1778 21 21 17.1783 21 12.4801C21 7.78261 17.1778 4.00002 12.48 4.00002ZM7.90137 7.74222C7.90137 6.22945 9.09601 4.19896 11.4048 8.14045C11.7186 8.70059 12.0372 9.25806 12.3605 9.81277C12.1215 9.85249 11.9226 10.0117 11.7233 10.3305L10.6882 12.1617L8.85701 15.267C8.85701 15.267 8.10064 16.5011 7.94143 17.7758C7.86165 17.0585 7.90137 7.74222 7.90137 7.74222ZM10.6485 18.7308C9.33467 19.4879 6.98578 19.4879 9.25489 15.5064C9.65311 14.8298 10.2105 13.834 10.2105 13.834C10.3698 14.033 10.6088 14.1521 10.9669 14.1521H16.7001C16.7001 14.1521 18.1331 14.1919 19.2877 13.7145C18.7703 14.1124 10.6485 18.7308 10.6485 18.7308ZM16.7001 13.6748H14.7888C14.8683 13.4752 14.8289 13.1968 14.6697 12.878L13.5945 11.0865L11.8031 7.94117C11.8031 7.94117 11.1261 6.6674 10.1308 5.91068C10.768 6.14968 18.81 10.8475 18.81 10.8475C20.1241 11.6443 21.2787 13.6351 16.7001 13.6748Z"
        fill="#FF7506"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.48 4.00002C11.3657 3.9976 10.2619 4.21529 9.23198 4.6406C8.20205 5.06591 7.26627 5.69046 6.47835 6.47839C5.69043 7.26633 5.06589 8.20213 4.64059 9.23208C4.21529 10.262 3.9976 11.3658 4.00002 12.4801C4.00002 17.1783 7.78254 21 12.48 21C17.1778 21 21 17.1783 21 12.4801C21 7.78261 17.1778 4.00002 12.48 4.00002ZM7.90137 7.74222C7.90137 6.22945 9.09601 4.19896 11.4048 8.14045C11.7186 8.70059 12.0372 9.25806 12.3605 9.81277C12.1215 9.85249 11.9226 10.0117 11.7233 10.3305L10.6882 12.1617L8.85701 15.267C8.85701 15.267 8.10064 16.5011 7.94143 17.7758C7.86165 17.0585 7.90137 7.74222 7.90137 7.74222ZM10.6485 18.7308C9.33467 19.4879 6.98578 19.4879 9.25489 15.5064C9.65311 14.8298 10.2105 13.834 10.2105 13.834C10.3698 14.033 10.6088 14.1521 10.9669 14.1521H16.7001C16.7001 14.1521 18.1331 14.1919 19.2877 13.7145C18.7703 14.1124 10.6485 18.7308 10.6485 18.7308ZM16.7001 13.6748H14.7888C14.8683 13.4752 14.8289 13.1968 14.6697 12.878L13.5945 11.0865L11.8031 7.94117C11.8031 7.94117 11.1261 6.6674 10.1308 5.91068C10.768 6.14968 18.81 10.8475 18.81 10.8475C20.1241 11.6443 21.2787 13.6351 16.7001 13.6748Z"
        fill="#FF7506"
      />
    </svg>
  );
};

PNCBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PNCBankIcon;
