import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {required} from "src/core/common/validations";
import useCartState from "src/core/checkout/hooks/useCartState";
import {Field} from "react-final-form";
import useSite from "src/core/sites/hooks/useSite";
import SpenceIcon from "src/core/payments/spence/SpenceIcon";
import PaymentSource from "src/core/payments/components/PaymentSource";
import calendar from "dayjs/plugin/calendar";
import dayjs from "dayjs";

dayjs.extend(calendar);

function SpencePaymentForm({paymentOption, form, order}) {
  const {env} = useSite();
  const [submitted, setSubmitted] = useState(false);
  const [cartState] = useCartState();
  const cart = cartState.data;

  const initialPaymentInfo = useMemo(
    () => (order ? order.getPaymentSpecification().getPaymentInfo() : ""),
    [order]
  );

  const totalPrice = cart?.getPrices().totalPrice.amount;
  const isSpenceSetup = Boolean(window.Spence);
  const spencebuttoncontainer = document.getElementById("spence-button-container");
  const spencebutton = document.getElementById("spence_button");
  if (spencebutton) {
    spencebutton.setAttribute("type", "button");
  }

  function createSpenceButton(amount) {
    const cartId = cart.getId();
    const appLevel = env.getTymberAppLevel();
    const storeId = env.getEcommerceId();
    const integratorTransactionID = `app:${appLevel}|store:${storeId}|cart:${cartId}`;

    window.Spence.Buttons({
      createOrder: function (data) {
        data.order.create({
          amount: {value: amount},
          shouldPreAuthorize: true,
          integratorTransactionID: integratorTransactionID,
        });
      },
      onApprove: function (result) {
        paymentOption.onApprove(result);
      },
      cssClass: "spence",
    }).render("#spence-button-container");
  }

  function deleteSpenceButton() {
    if (spencebutton) {
      spencebutton.remove();
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if (!isSpenceSetup) {
      paymentOption.setupSpence();
    }
  }, [isSpenceSetup]);

  useEffect(() => {
    if (form.getState().values.payment.source?.sourceType === "stronghold") {
      form.mutators.setValue("payment.source", null);
    }
  }, []);

  useEffect(() => {
    if (isSpenceSetup && spencebuttoncontainer) {
      if (spencebutton) {
        deleteSpenceButton();
      }
      if (!submitted) {
        createSpenceButton(totalPrice);
      }
    }
  }, [isSpenceSetup, totalPrice, spencebuttoncontainer, submitted]);

  useEffect(() => {
    return paymentOption.subscribe(({event, data}) => {
      if (event === "transaction-approved") {
        form.mutators.setValue("payment.source", data);
        form.submit();
        setSubmitted(true);
        deleteSpenceButton();
      }
      if (event === "transaction-failed") {
        //TODO handle failed transaction
      }
    });
  }, [paymentOption, form.mutators]);
  /* eslint-enable */

  return (
    <Field
      name="payment.source"
      validate={required}
      initialValue={initialPaymentInfo}
      render={({input, meta}) => {
        return (
          <>
            {input.value && (
              <Container>
                {input.value && (
                  <PaymentSource
                    readOnly
                    icon={<SpenceIcon color="#373995" width="25" height="25" />}
                    institutionName={input.value.merchantName}
                    label={`${input.value.payerFirstName} (${
                      input.value.payerPhoneNumber
                    }), ${input.value.createdAt?.calendar().toLowerCase()}`}
                  />
                )}
              </Container>
            )}
          </>
        );
      }}
    />
  );
}

const Container = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0 0 0;

  ${({theme}) =>
    `
    width: auto !important;
    border: 1px solid #EBEBEB;
    border-radius: 10px;
    padding: ${theme.v2.spacing(8)};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    > button {
      max-width: 200px;
    }
  `};
`;

export default SpencePaymentForm;
