import React from "react";
import BasePage from "./BasePage";
import CategorizedProductsBrowser from "@menu/components/CategorizedProductsBrowser";
import PromotionalBanner from "@menu/components/PromotionalBanner";
import useAdBanner from "src/core/common/hooks/useAdBanner";
import AdBanner from "@themes/default/components/AdBanner";

export default function FlightHomePage(props) {
  const {showAdBanner} = useAdBanner();

  return (
    <BasePage {...props}>
      <PromotionalBanner />
      {showAdBanner && <AdBanner />}
      <CategorizedProductsBrowser />
    </BasePage>
  );
}
