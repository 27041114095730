import {useCallback} from "react";
import formsCache from "../formsCache";

function useFormsCache(key) {
  const cache = formsCache;

  const value = cache.get(key);

  const setValue = useCallback(
    value => {
      cache.put(key, value);
    },
    [key]
  );

  const clearCache = useCallback(() => {
    cache.clear();
  }, []);

  return [value, setValue, clearCache];
}

export default useFormsCache;
