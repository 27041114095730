import {ProductFilters} from "src/core/common/hooks/useAppliedFilters";
import * as ProductsApi from "src/core/api/products";
import {populateRelations} from "src/core/api/utils";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export async function getProducts(axiosInstance, elements, paramFactory, otherParams) {
  const {limit, offset, deliveryType} = otherParams;
  const promises = elements.map(async element => {
    const filters = new ProductFilters(paramFactory(element));
    const params = pickBy(
      {limit: limit, offset: offset, delivery_type: deliveryType, ...filters.serialize()},
      identity
    );

    const response = await ProductsApi.list(params, {}, axiosInstance);

    return {
      params,
      data: {
        meta: response.meta,
        objects: response.data.map(element =>
          populateRelations(element, response.included)
        ),
      },
    };
  });
  return Promise.all(promises);
}
