import React from "react";
import {composeValidators, emailValidator, required} from "src/core/common/validations";
import {FieldContainer} from "src/core/common/components/collections/form/forms";
import Input from "src/core/common/components/elements/input/Input";
import {showErrorHighlight} from "src/core/common/utils";
import Error from "src/core/common/components/elements/Error";
import {Field} from "react-final-form";

export default function EmailInput({
  name = "email",
  initialValue,
  isRequired,
  disabled = false,
  onClickContainer,
  computeEmailInputProps = ({input}) => input,
  styles,
}) {
  const _validations = isRequired
    ? composeValidators(required, emailValidator)
    : emailValidator;

  return (
    <Field
      name={name}
      validate={_validations}
      initialValue={initialValue}
      render={({input, meta}) => {
        const props = computeEmailInputProps({input, meta});
        return (
          <FieldContainer onClick={onClickContainer}>
            <Input
              {...input}
              data-cy="loginEmailField"
              disabled={disabled}
              hasErrors={showErrorHighlight(meta)}
              placeholder="Email*"
              onChange={input.onChange}
              styles={styles}
              {...props}
            />
            {meta.touched && <Error>{meta.error}</Error>}
          </FieldContainer>
        );
      }}
    />
  );
}
