import React from "react";
import useFormsCache from "src/core/common/hooks/useFormsCache";
import {FormFields} from "../../common/components/collections/form/forms";
import styled from "styled-components";

function PhoneConfirmationWarning({phone}) {
  const [phoneValue] = useFormsCache(FormFields.PHONE);
  return (
    <PhoneConfirmationWarningContainer>
      We just sent a 6-digit code to <span>{phone || phoneValue}</span> to verify your
      phone number. Please enter it below and set your password to create an account.
    </PhoneConfirmationWarningContainer>
  );
}

export default PhoneConfirmationWarning;

const PhoneConfirmationWarningContainer = styled.p`
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  text-align: center;
  margin: 0 0 50px 0;
  > span {
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  }
`;
