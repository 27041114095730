import React, {useState} from "react";
import Input from "./Input";
import {Eye} from "styled-icons/feather/Eye";
import {EyeOff} from "styled-icons/feather/EyeOff";
import styled from "styled-components";

function PasswordInput(props) {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <Container>
      <Input {...props} type={isHidden ? "password" : "text"} />
      {isHidden ? (
        <EyeIcon onClick={() => setIsHidden(false)} />
      ) : (
        <EyeOffIcon onClick={() => setIsHidden(true)} />
      )}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > input {
    width: 100%;
  }
`;

const EyeIcon = styled(Eye)`
  display: block;
  position: absolute;
  right: 20px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const EyeOffIcon = styled(EyeOff)`
  display: block;
  position: absolute;
  right: 20px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export default PasswordInput;
