class AuthenticationForm {
  getEmail() {
    return this.email;
  }
  setEmail(email) {
    this.email = email;
    return this;
  }

  getPhone() {
    return this.phone;
  }
  setPhone(phone) {
    this.phone = phone;
    return this;
  }

  getZipCode() {
    return this.zipCode;
  }
  setZipCode(zipCode) {
    this.zipCode = zipCode;
    return this;
  }

  build() {
    return {
      email: this.email,
      phone: this.phone,
      zipCode: this.zipCode,
    };
  }
}

const authenticationForm = new AuthenticationForm();

function useAuthenticationForm() {
  return [authenticationForm];
}

export default useAuthenticationForm;
