import React, {useState} from "react";
import {createPortal} from "react-dom";
import styled, {createGlobalStyle} from "styled-components";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import Head from "next/head";
import {addImgixParams} from "@ui";
import {isMobile, isIOS, isAndroid} from "src/core/common/utils";
import useSite from "src/core/sites/hooks/useSite";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import {useShop} from "src/core/shops";
import useCustomHome from "src/integrations/inStoreExperience/hooks/useCustomHome";
import useMobileAppDownload from "src/integrations/mobileApp/useMobileAppDownload";

export default function MobileAppDownload() {
  const options = useSiteOptions();
  const site = useSite();
  const [shop] = useShop();
  const logo = site.getLogoUrl();
  const [, setDisplayCustomHome] = useCustomHome();
  const [display, setDisplay] = useState(true);
  const {appUrl, showMobileAppDownloadPrompt} = useMobileAppDownload();

  const appStoreUrl = options.appStoreUrl();
  const playStoreUrl = options.googlePlayUrl();
  const appLogo = addImgixParams(logo, {width: 120, height: 120}, 3, "clip")?.href;
  const shopName = shop.data?.getGroupName();

  const onVisitWebApp = () => {
    setDisplayCustomHome(true);
    setDisplay(false);
  };

  if (
    showMobileAppDownloadPrompt() === "hidden" ||
    !isMobile() ||
    (isIOS() && !appStoreUrl) ||
    (isAndroid() && !playStoreUrl) ||
    !display
  ) {
    return null;
  }

  return createPortal(
    <>
      <Head>
        <meta name="apple-itunes-app" content={`app-id=${options.appStoreAppId()}`} />
        <meta name="google-play-app" content={`app-id=${options.googlePlayAppId()}`} />
        <link rel="apple-touch-icon" href={appLogo} />
        <link rel="android-touch-icon" href={appLogo} />
      </Head>
      <SmartBanner title={shopName} />
      <GlobalStyles />
      <Container id="mobile-app-download">
        <MiddleSection>
          <Logo logoUrl={logo} />
          <ContentContainer>
            <AppName>Welcome to {shopName}</AppName>
            <Label>
              The best way to optimize your {shopName} experience - and make sure you
              don't miss any crucial updates - is to download our official app.
            </Label>
            <Label>Download the official {shopName} app here.</Label>
            <a href={appUrl()} rel="noopener noreferrer">
              <DownloadAppButton>download app now</DownloadAppButton>
            </a>
          </ContentContainer>
        </MiddleSection>
        {showMobileAppDownloadPrompt() === "optional" && (
          <UseWebVersion>
            or, <WebAppLink onClick={onVisitWebApp}>continue on the web</WebAppLink>
          </UseWebVersion>
        )}
      </Container>
    </>,
    document.body
  );
}

const GlobalStyles = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
  padding: 180px 0;
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -90px;
`;

const Logo = styled.div`
  content: url(${({logoUrl}) => addImgixParams(logoUrl, {}, 3, "contain")});
  object-fit: contain;
  width: 180px;
  height: 180px;
  padding: 4px;
  background-color: #fff;
  border: 3px solid #eee;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
`;

const ContentContainer = styled.div`
  position: relative;
  top: -90px;
  width: 300px;
  min-height: 310px;
  background-color: #fff;
  box-shadow: 6px 8px 22px -11px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 90px 20px 20px;
  box-sizing: border-box;
  z-index: 1998;

  > a {
    text-decoration: none;
  }
`;

const AppName = styled.span`
  margin-top: 10px;
  font-family: ${({theme}) => theme.v2.typography.title1.family};
  font-size: ${({theme}) => theme.v2.typography.title1.size};
  font-weight: ${({theme}) => theme.v2.typography.title1.weight};
  color: ${({theme}) => theme.v2.color.base.grey["800"]};
`;

const Label = styled.span`
  font-family: ${({theme}) => theme.v2.typography.bodyText2.family};
  font-size: ${({theme}) => theme.v2.typography.bodyText2.size};
  font-weight: ${({theme}) => theme.v2.typography.bodyText2.weight};
  color: ${({theme}) => theme.v2.color.base.grey["500"]};
`;

const DownloadAppButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 50px;
  border-radius: 50px;
  background-color: ${({theme}) => theme.v2.color.primary};
  font-family: ${({theme}) => theme.v2.typography.title2.family};
  font-size: ${({theme}) => theme.v2.typography.title2.size};
  font-weight: ${({theme}) => theme.v2.typography.title2.weight};
  color: #fff;
  text-transform: uppercase;
`;

const UseWebVersion = styled.span`
  color: ${({theme}) => theme.v2.color.base.grey["500"]};
`;

const WebAppLink = styled.span`
  color: ${({theme}) => theme.v2.color.primary};
  text-decoration: underline;
`;
