import React from "react";
import styled from "styled-components";
import media from "@ui/utils/media";

const BankLabelComponent = ({styles, pSource, InstitutionIconComponent}) => (
  <LabelContainer styles={styles.optionPicker.optionRow.labelContainer}>
    <div>
      <InstitutionIconComponent institution={pSource.institution} />
    </div>
    <div>
      <Title styles={styles.optionPicker.optionRow.title}>
        {pSource.institutionName}
      </Title>
      <Subtitle styles={styles.optionPicker.optionRow.subtitle}>{pSource.label}</Subtitle>
    </div>
  </LabelContainer>
);

const LabelContainer = styled.div`
  padding: ${({styles}) => styles.padding};
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Title = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Subtitle = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  text-transform: capitalize;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }

  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

export default BankLabelComponent;
