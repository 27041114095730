import * as React from "react";
import PropTypes from "prop-types";

const BettermentBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#2F363C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0606 17.8571C19.4162 16.4938 20.2539 14.615 20.2539 12.5405C20.2539 10.7363 19.6202 9.08006 18.5633 7.78224L12.8313 17.8571H18.0606ZM7.35934 17.8503C6.00776 16.4875 5.17285 14.6115 5.17285 12.5405C5.17285 8.376 8.54885 5 12.7134 5C14.7955 5 16.6806 5.84394 18.0452 7.20843L7.35934 17.8503Z"
        fill="white"
      />
    </svg>
  );
};

BettermentBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default BettermentBankIcon;
