import useSite from "../../sites/hooks/useSite";
import {yearsAgo} from "src/core/common/utils";
import {age} from "../../common/validations";

function useAgeValidation() {
  const site = useSite();
  const minimumAge = site.getOptions().minimumAge() || 21;

  return [yearsAgo(minimumAge), age(minimumAge)];
}

export default useAgeValidation;
