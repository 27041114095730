import * as React from "react";
import PropTypes from "prop-types";

const CitiBankIcon = ({width, height}) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#035BB4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.073 10.0041H10.3606V18.3783H12.073V10.0041ZM13.6431 10.0179V11.588H15.0848V16.3124C15.0848 16.9642 15.2637 17.4877 15.6081 17.8825C15.9525 18.2912 16.4116 18.493 16.9901 18.5068C17.5638 18.5206 18.0735 18.392 18.5052 18.1257L18.5326 18.1119V18.0843L18.9413 16.4912L18.8402 16.5554C18.3812 16.8355 17.9589 16.9733 17.5731 16.9733C17.0406 16.9733 16.7699 16.6565 16.7699 16.0321V11.5881H18.5189V10.0179H16.7699V7.401L15.0848 8.30548V10.0179H13.6431ZM21.9302 10.0041H20.2039V18.3783H21.9302V10.0041ZM9.37808 11.3219L9.34126 11.2943C8.55161 10.3348 7.56451 9.86638 6.37997 9.86638C5.14043 9.86638 4.12112 10.2475 3.30386 11.0004C2.43619 11.7947 2 12.8644 2 14.2049C2 15.5455 2.43619 16.6062 3.30386 17.4097C4.10732 18.1625 5.14032 18.5345 6.37997 18.5345C7.56451 18.5345 8.5607 18.0616 9.34126 17.1158L9.37808 17.0882L8.40928 15.9175L8.37257 15.9541C7.74355 16.5784 7.06872 16.8999 6.34327 16.8999C5.58572 16.8999 4.96129 16.6427 4.4655 16.1332C3.96499 15.6327 3.72635 14.9853 3.72635 14.2048C3.72635 13.4244 3.96499 12.7723 4.4655 12.2628C4.96129 11.7669 5.58572 11.5099 6.34327 11.5099C7.06861 11.5099 7.74355 11.8313 8.37257 12.4556L8.40928 12.4924L9.37808 11.3219Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2051 8.62691C11.8801 7.93828 12.633 7.41487 13.4916 7.043C14.3181 6.68493 15.1994 6.51039 16.1085 6.51039C17.0131 6.51039 17.8944 6.68482 18.7393 7.043C19.5794 7.41487 20.3322 7.93828 21.0073 8.62691L21.0349 8.64992H22.9999L22.9265 8.53521C22.146 7.43788 21.1358 6.57022 19.9239 5.93659C18.7117 5.30756 17.4492 5 16.1086 5C14.7819 5 13.5009 5.30756 12.2888 5.93659C11.0766 6.5701 10.0666 7.43777 9.29066 8.53509L9.21265 8.64981H11.1914L11.2051 8.62691Z"
        fill="#CA3132"
      />
    </svg>
  );
};

CitiBankIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CitiBankIcon;
