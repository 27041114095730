import React from "react";
import styled from "styled-components";
import {CarouselProductsList, ProductListHeader} from "@ui";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import useTheme from "@mock/hooks/useTheme";
import useShowcaseProductsList from "@mock/hooks/useShowcaseProductsList";
import styles from "@themes/boost/components/ShowcaseProductsList/ProductsList/styles";

export default function BoostThemeProductsList({
  categoryName,
  description,
  url,
  slug,
  elementsPerRow,
  ...props
}) {
  const {Title, ViewAllLink} = useThemeComponents();
  const theme = useTheme();
  const {site, ...baseProps} = useShowcaseProductsList({
    inView: true,
    slug,
    url,
    filters: props.filters,
  });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  return (
    <CarouselProductsList
      {...props}
      {...baseProps}
      styles={styles(theme, site)}
      maxProductsPerSlide={productsPerSlide}
      HeaderComponent={
        <ProductListHeader
          ViewAllComponent={
            <ViewAllLinkContainer>
              <ViewAllLink
                accessibilityIdentifier={slug}
                to={url}
                keepQuery={true}
                underlined={false}
                theme={theme}
              />
            </ViewAllLinkContainer>
          }
          TitleComponent={Title}
          title={categoryName}
          description={description}
        />
      }
    />
  );
}

const ViewAllLinkContainer = styled.div`
  > * {
    color: ${({theme}) => theme.v2?.color?.onSurface};
    text-decoration: none !important;
  }
`;
