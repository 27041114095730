import React from "react";
import styled from "styled-components";
import {media} from "@ui";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import useBasePage from "@mock/hooks/useBasePage";
import AnnouncementMessage from "@themes/default/components/AnnouncementMessage";
import PromotionalBanner from "@themes/default/components/PromotionalBanner";
import AdBanner from "@themes/default/components/AdBanner";
import {ProductDetailModal} from "@themes/default/components/ProductDetail";

export default function BasePage({children, ...props}) {
  const {
    kioskMode,
    showAnnouncementMessage,
    showBanner,
    showAdBanner,
    categoryBarPosition,
    addons,
  } = useBasePage();
  const {DeliveryBanner, CategoryBar, Header, ProductDetail} = useThemeComponents();

  return (
    <div>
      <Header />
      {!kioskMode && <DeliveryBanner />}
      {categoryBarPosition === "top" && <CategoryBar />}
      {showAnnouncementMessage && <AnnouncementMessage />}
      {showBanner && <PromotionalBanner />}
      {categoryBarPosition === "bottom" && <CategoryBar />}
      {showAdBanner && <AdBanner />}
      <Container>{children}</Container>
      <ProductDetailModal ProductDetailComponent={ProductDetail} {...props} />
      {addons}
    </div>
  );
}

const Container = styled.div`
  ${media.down("sm")} {
    padding-top: 20px;
  }
`;
